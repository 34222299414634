import { defineStore } from 'pinia';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import storeId from '@/base/store/storeId';

export default defineStore({
  id: storeId.ORGANIZATION_INVITE_USER,
  state: () => ({
    _unauthService: UnAuthenticateService.getInstance(),
  }),
  actions: {
    async checkPhone(phone: string): Promise<any> {
      const res = await this._unauthService.checkPhone(phone);

      return res?.result;
    },
    async checkEmail(phone: string): Promise<any> {
      const res = await this._unauthService.checkEmail(phone);
      return res?.result;
    },
    sendVerificationCodeByPhoneNumber(data): Promise<any> {
      return this._unauthService.sendVerificationCodeByPhoneNumber(data);
    },
    reSendVerificationCodeByPhoneNumber(data): Promise<any> {
      return this._unauthService.reSendVerificationCodeByPhoneNumber(data);
    },
    verifyUserPhoneNumber(data): Promise<any> {
      return this._unauthService.verifyUserPhoneNumber(data);
    },
    getInvitedUser(data): Promise<any> {
      return this._unauthService.getInvitedUser(data);
    },
  },
});
