import { defineStore } from 'pinia';
import PlansService from '@/application/services/plans/PlansService';
import storeId from '@/base/store/storeId';
import { IPlanDetail } from '@/application/interfaces/PaymentInterfaces';
import {
  EPlanCapacityKeys,
  ExceededPlanAction,
  ExceededPlanNotify,
} from '@/application/enums/PlanEnums';
import { IOrgCurrentPlan } from '@/application/types/plan.types';
import dayjs from 'dayjs';

type PlansState = {
  _plansService: PlansService;
  currentOrgPlan: IOrgCurrentPlan;
  allPlans: any;
  allPlanByIds: any;
  isLoadingCurrentPlan: boolean;
  isLoadingAllPlans: boolean;
  orgPlanCapacities: any;
  organizationUsageExceed: boolean;
  exceededYourPlanLimitation: boolean;
  planStatusInfo: {
    showNotification: boolean;
    notificationType: ExceededPlanNotify | null;
    action: ExceededPlanAction | null;
    plannedEndDate?: number | null;
    nextBillingDate?: number | null;
  };
  planCapacityByKeys: any;
};

export default defineStore({
  id: storeId.PLANS_SETTING,
  state: () =>
    ({
      _plansService: PlansService.getInstance(),
      currentOrgPlan: {},
      allPlans: [],
      allPlanByIds: {},
      isLoadingCurrentPlan: false,
      isLoadingAllPlans: false,
      orgPlanCapacities: {},
      organizationUsageExceed: false,
      exceededYourPlanLimitation: false,
      planStatusInfo: {
        showNotification: false,
        notificationType: null,
        action: null,
        plannedEndDate: null,
        nextBillingDate: null,
      },
      planCapacityByKeys: {},
    } as PlansState),
  getters: {},
  actions: {
    initData() {
      const promise = [
        this.getCurrentOrgPlans(),
        this.getAllPlans(),
        this.checkOrganizationUsageExceed(),
      ];
      return Promise.all(promise);
    },
    async getCurrentOrgPlans() {
      try {
        this.isLoadingCurrentPlan = true;
        const res = await this._plansService.getCurrentOrgPlan();
        const result: IOrgCurrentPlan = res.result;
        const remainDays = -dayjs().diff(result.stateEndDate, 'day'); // 7

        this.currentOrgPlan = {
          ...res['result'],
          subStateCode:
            remainDays < 5
              ? 'INCOMING_EXPIRED_HIGHER'
              : remainDays < 10
              ? 'INCOMING_EXPIRED'
              : 'DEFAULT',
          remainDays,
          isLifeTime: result.organizationId === 7490,
        };
        this.isLoadingCurrentPlan = false;
        return res['result'];
      } catch (e) {
        this.isLoadingCurrentPlan = false;
      }
    },
    async checkOrganizationUsageExceed() {
      try {
        const res = await this._plansService.checkOrganizationUsageExceed();
        const result = res?.result;
        if (!result) return;
        this.organizationUsageExceed = result?.isExceeded;
        this.exceededYourPlanLimitation = result?.exceededYourPlanLimitation;
        this.planStatusInfo = {
          showNotification: result?.showNotification,
          notificationType: result?.notificationType,
          action: result?.action,
          plannedEndDate: result?.plannedEndDate,
          nextBillingDate: result?.nextBillingDate,
        };
      } catch (e) {
        console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
      }
    },
    async getAllPlanCapacities() {
      try {
        const res = await this._plansService.getOrgPlanCapacity([
          EPlanCapacityKeys.ALL,
        ]);
        const result: any[] = res.result || [];
        this.planCapacityByKeys = result?.reduce((oldValue, currentValue) => {
          return {
            ...oldValue,
            [currentValue?.key]: currentValue,
          };
        }, {});
      } catch (e) {
        console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
        return [];
      }
    },
    async getOrgPlanCapacities(filters?: EPlanCapacityKeys[]) {
      /* List keys:
        ALL
        EXCEEDED_ONLY
        TotalTask
        TotalStorageByUser
        TotalStorage
        TotalMember
        TotalGroup
        TotalMessage
        TotalNote
        MessageHistory
        FileAndTaskHistory
        TotalCall
      */
      try {
        const res = await this._plansService.getOrgPlanCapacity(
          filters || [EPlanCapacityKeys.ALL]
        );
        return res.result;
      } catch (e) {
        console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
        return [];
      }
    },
    async getAllPlans() {
      try {
        this.isLoadingAllPlans = true;
        const res = await this._plansService.getAllPlansDetail();
        this.allPlans = res.result;
        this.allPlanByIds = this.allPlans.reduce(
          (oldValue, currentValue: IPlanDetail) => {
            return {
              ...oldValue,
              [currentValue.id]: currentValue,
            };
          },
          {}
        );
        this.isLoadingAllPlans = false;
        return res['result'];
      } catch (e) {
        this.isLoadingAllPlans = false;
        console.log(e);
      }
    },
  },
});
