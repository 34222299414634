import { upperFirst, camelCase } from 'lodash';

export function declare(requireComponent: any, vueApp: any) {
  requireComponent.keys().forEach((fileName: string) => {
    const componentConfig = requireComponent(fileName);
    const urlArr = fileName.split('/');
    if (urlArr.length > 0) {
      const compName = urlArr[urlArr.length - 1];
      const componentName = upperFirst(
        camelCase(compName.replace(/^(.*)\.\w+$/, '$1'))
      );

      vueApp.component(
        componentName,
        componentConfig.default || componentConfig
      );
    }
  });
}
