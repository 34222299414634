import { ref } from 'vue';
import {
  getLocalStorage,
  StorageConstant,
  setLocalStorage,
} from '@/ui/hooks/storageHook';
import { onChangeI18n } from './plugins/i18n/myi18n';
import appStore from '@/store/common-store';

export const isShowDayoffDetail = ref(false);
export const dayoffIdGlobal = ref('');
export const isCreateGroup = ref(false);
export const isOpenModalInvite = ref(false);
export const isShowPageGedDetail = ref(false);
export const gedIdGlobal = ref({
  id: '',
  isGroup: false,
  folderId: '',
  notification: {},
});
export const eventIdGlobal = ref({
  isShow: false,
  id: '',
});
export const detectLanguageFromBrowserClient = async () => {
  // 1. user selected language
  const userSelectedLanguage = getLocalStorage(StorageConstant.LANGUAGE);
  if (userSelectedLanguage) {
    onChangeI18n(userSelectedLanguage);

    return;
  }

  const defaultLanguage = 'vi';

  setLocalStorage(StorageConstant.LANGUAGE, defaultLanguage);
  appStore().setLanguage(defaultLanguage);
  onChangeI18n(defaultLanguage);
};

export const detectLocation = () => {
  detectLanguageFromBrowserClient();
};
