import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import PaymentService from '@/application/services/payment/PaymentService';
import {
  IPaymentVoucherDetail,
  IPaymentVoucher,
} from '@/application/types/payment.types';
import { setLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

// Generated by https://quicktype.io

export default defineStore({
  id: storeId.PAYMENT_VOUCHER,
  state: () =>
    ({
      isLoading: false,
      availableVouchers: null,
      voucherDetail: {},
    } as {
      isLoading: boolean;
      voucherDetail: IPaymentVoucher;
      availableVouchers: IPaymentVoucherDetail[] | null;
    }),
  getters: {},
  actions: {
    async verifySpecialOfferToken(data: {
      organizationId: number;
      userId: number;
      token: string;
    }) {
      this.isLoading = true;
      const res =
        await UnAuthenticateService.getInstance().verifySpecialOfferToken(data);
      this.voucherDetail = res?.result;
      setLocalStorage(StorageConstant.TOKEN, this.voucherDetail?.token);

      this.isLoading = false;
    },
    async getAvailableVouchers() {
      const res = await PaymentService.getInstance().getAvailableVouchers();
      this.availableVouchers = res?.result;
      return res?.result;
    },
  },
});
