import BaseRepository from '@/base/repositories/BaseRepository';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export default class CatalogRepository {
  private static instance: CatalogRepository;
  baseRepository: BaseRepository;
  constructor() {
    this.baseRepository = new BaseRepository();
  }

  public static getInstance(): CatalogRepository {
    if (!CatalogRepository.instance) {
      // Get from local storage
      CatalogRepository.instance = new CatalogRepository();
    }

    return CatalogRepository.instance;
  }

  getCheckEmailPhone(emailOrPhone: any): Promise<any> {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CheckEmailPhone?emailOrPhone=${emailOrPhone}`,
      method: 'get',
    });
  }
  checkEmailPhoneInOrganization(emailOrPhone) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CheckEmailPhoneInOrganization?emailOrPhone=${emailOrPhone}`,
      method: 'get',
    });
  }
  getCheckCode(code) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CheckOrgCode?code=${code}`,
      method: 'get',
    });
  }
  getUserOrganizations() {
    const lang = 'en';
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetUserOrganizations?lang=${lang}`,
      method: 'get',
    });
  }
  onFinishWithPersonalMode() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/FinishSetupPersonalWorkspace`,
      method: 'post',
    });
  }
  updateAndFinishSetupOrganization(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/UpdateAndFinishSetupOrganization`,
      method: 'post',
      data,
    });
  }
  getOrganizationConfigDefault() {
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/OrganizationDefaultConfigs?lang=${lang
        .slice(0, 2)
        .toLowerCase()}`,
      method: 'get',
    });
  }
  getUserProfile() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetUserProfile`,
      method: 'get',
    });
  }
  saveOrganizationSetting(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/SaveOrganizationSetting`,
      method: 'post',
      data,
    });
  }
  registerNewOrganization(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/RegisterNewOrganization`,
      method: 'post',
      data,
    });
  }
  saveTeam(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/SaveTeam`,
      method: 'post',
      data,
    });
  }
  saveTeamDraft(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/SaveDraftTeam`,
      method: 'post',
      data,
    });
  }
  getDraftTeam() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetDraftTeam`,
      method: 'get',
    });
  }
  getOrganizationInfo(id) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetOrganizationInfo?organizationId=${id}`,
      method: 'get',
    });
  }
  getOrganizationAttachmentCode() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetOrganizationAttachmentCode`,
      method: 'get',
    });
  }
  generateOrganizationAttachmentCode() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GenerateOrganizationAttachmentCode`,
      method: 'get',
    });
  }
  updateOrganization(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/UpdateOrganization`,
      method: 'post',
      data,
    });
  }

  uploadAvatarAws(file) {
    const formData = new FormData();
    formData.append('fileData', file);
    return this.baseRepository.requestWithAuthorize({
      url: `Media/UploadAvatarAws`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-organization-code': 'temp',
      },
    });
  }

  skipAddMember() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/SkipAddMember`,
      method: 'post',
    });
  }
  inviteUser(data) {
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/InviteUser?lang=${lang}`,
      method: 'post',
      data,
    });
  }
  getInvitedUser(params) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetInvitedUser`,
      method: 'get',
      params,
    });
  }
  /*
  Data:
  {
    "firstName": "string",
    "lastName": "string",
    "phone": "string",
    "email": "string",
    "isFixedPhone": true,
    "isFixedEmail": true,
    "inviteToken": "string"
  }
  */
  acceptedInvitation(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/AcceptedInvitation`,
      method: 'post',
      data,
    });
  }
  rejectedInvitation(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/RejectedInvitation`,
      method: 'post',
      data,
    });
  }

  attachOrganizationByCode(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/AttachOrganizationByCode`,
      method: 'post',
      data,
    });
  }

  checkOrganizationAttachCode(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CheckOrganizationAttachCode`,
      method: 'post',
      data,
    });
  }

  getPendingUsers() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetPendingUsers`,
      method: 'get',
    });
  }

  resendInvitation(inviteId) {
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/ResendInvitation?inviteId=${inviteId}&lang=${lang}`,
      method: 'post',
    });
  }

  cancelInvitation(inviteId) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CancelInvitation?inviteId=${inviteId}`,
      method: 'post',
    });
  }

  getMyPendingInvitation() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetMyPendingInvitation`,
      method: 'get',
    });
  }

  checkUserInstallApp() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/CheckUserInstallApp`,
      method: 'get',
    });
  }
  getAllMyPendingInvitation() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetAllMyPendingInvitation`,
      method: 'get',
    });
  }

  usingTrialPackage(planId: string) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/UsingTrialPackage`,
      method: 'post',
      params: {
        planId,
      },
    });
  }
  payImmediately(planId: string) {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/PayImmediately`,
      method: 'post',
      params: {
        planId,
      },
    });
  }
  getPaymentBonus() {
    return this.baseRepository.requestWithAuthorize({
      url: `Catalog/GetPaymentBonus`,
      method: 'get',
    });
  }
}
