import { AxiosInstance, AxiosRequestConfig } from 'axios';
import BaseRequest from '@/base/api/base-request';

export interface IResponseConfig {
  message: string;
}

export class BaseResponse {
  success: boolean;
  result: any;
  error: any;
  targetUrl: string;
  unAuthorizedRequest: boolean;

  constructor(data) {
    this.success = data?.success || false;
    this.result = data?.result;
    this.error = data?.error || false;
    this.targetUrl = data?.targetUrl;
    this.unAuthorizedRequest = data?.unAuthorizedRequest;
  }
}
export default class BaseRepository {
  private static instance: BaseRepository;
  request: AxiosInstance;
  constructor() {
    this.request = BaseRequest;
  }
  public static getInstance(): BaseRepository {
    if (!BaseRepository.instance) {
      // Get from local storage
      BaseRepository.instance = new BaseRepository();
    }

    return BaseRepository.instance;
  }

  async requestWithAuthorize(
    requestConfig: AxiosRequestConfig
  ): Promise<BaseResponse> {
    try {
      const res = await this.request(requestConfig);

      return Promise.resolve(new BaseResponse(res));
    } catch (error: any) {
      return Promise.reject(
        new BaseResponse({
          error: error?.error || true,
        })
      );
    }
  }
}
