import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import 'dayjs/locale/fr';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export type FormatType = 'date' | 'time' | 'date_time' | 'date_day';
export default function datetimeComposables() {
  const FORMAT_BY_LANGUAGE = {
    date: {
      en: 'MMM DD, YYYY',
      vi: 'DD MMM YYYY',
      fr: 'DD MMM YYYY',
    },
    time: {
      en: 'hh:mm A',
      vi: 'HH[h]mm',
      fr: 'HH[:]mm',
    },
    date_time: {
      en: 'MMM DD, YYYY hh:mm A',
      vi: 'DD MMM YYYY, HH[h]mm',
      fr: 'DD MMM YYYY HH[:]mm',
    },
    date_day: {
      en: 'dddd MMM DD, YYYY',
      vi: 'dddd DD MMM YYYY',
      fr: 'dddd DD MMM YYYY',
    },
  };
  dayjs.extend(utc);
  dayjs.extend(tz);

  const localeData = require('dayjs/plugin/localeData');

  const myTimezone = computed(() => myProfileStore().getTimezone);
  const timezone = computed(() => parseInt(myTimezone.value) || 0);
  const _genFormatString = (format: FormatType) => {
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';

    return FORMAT_BY_LANGUAGE[format][lang];
  };

  dayjs.extend(localeData);
  dayjs.locale(getLocalStorage(StorageConstant.LANGUAGE) || 'vi');

  const formatDate = (
    originDate: any,
    format: FormatType,
    configs: {
      isIgnoreTimezone: boolean;
      timezone?: number;
    }
  ) => {
    const dateValue =
      configs?.isIgnoreTimezone || typeof originDate == 'number'
        ? dayjs(new Date(originDate))
        : dayjs(new Date(originDate)).add(
            configs.timezone || timezone.value,
            'hour'
          );

    const formatStr = _genFormatString(format);
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';

    return dateValue.locale(lang).format(formatStr);
  };

  return {
    formatDate,
  };
}
