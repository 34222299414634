import { translate } from './../../../plugins/i18n/myi18n';
import { ref } from 'vue';

export const visible = ref(false);

type SynConfirmModalType = 'confirm' | 'notification' | 'warning';

export const type = ref('confirm' as SynConfirmModalType);
export const title = ref('');
export const content = ref('');
export const cancelLabel = ref('');
export const confirmLabel = ref('');
export interface ISynConfirmModalMetadata {
  type: SynConfirmModalType;
  title: string;
  iconName?: string;
  content: any;
  cancelLabel?: string;
  confirmLabel?: string;
  closeable?: boolean;
  confirmable?: boolean;
}

export const confirmModalMetadata = ref<ISynConfirmModalMetadata>({
  type: 'notification',
  title: '',
  content: '',
  iconName: '',
});

export const settingGlobalModal = (options: ISynConfirmModalMetadata) => {
  confirmModalMetadata.value = {
    type: options?.type,
    title: options?.title,
    content: options?.content,
    cancelLabel: options?.closeable
      ? options?.cancelLabel || translate('COMMON_LABEL_CANCEL')
      : '',
    confirmLabel: options?.confirmable
      ? options?.confirmLabel || translate('COMMON_LABEL_GOT_IT')
      : '',
    closeable: options?.closeable,
    confirmable: options?.confirmable,
    iconName: options?.iconName,
  };
};

let resolveFn = (_value: boolean) => {};

export const ask = (): Promise<boolean> => {
  visible.value = true;
  resolveFn && resolveFn(false);

  return new Promise((resolve) => {
    resolveFn = resolve;
  });
};

export const tell = (value) => {
  visible.value = false;
  resolveFn && resolveFn(value);
};
