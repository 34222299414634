export enum VnpayTransactionStatusEnum {
  TRANSACTION_SUCCESS = '00',
  TRANSACTION_NOT_COMPLETED = '01',
  TRANSACTION_FAILED = '02',
  TRANSACTION_FAILED_VNPAY_04 = '04',
  TRANSACTION_FAILED_VNPAY_05 = '05',
  TRANSACTION_FAILED_VNPAY_06 = '06',
  TRANSACTION_FAILED_VNPAY_07 = '07',
  TRANSACTION_FAILED_VNPAY_09 = '09',
}
export enum VnpayResponseCodeEnum {
  TRANSACTION_SUCCESS = '00',
  TRANSACTION_FAILED_VNPAY_07 = '07',
  TRANSACTION_FAILED_VNPAY_09 = '09',
  TRANSACTION_FAILED_VNPAY_10 = '10',
  TRANSACTION_FAILED_VNPAY_11 = '11',
  TRANSACTION_FAILED_VNPAY_12 = '12',
  TRANSACTION_FAILED_VNPAY_13 = '13',
  USER_CANCELED = '24',
  TRANSACTION_FAILED_VNPAY_51 = '51',
  TRANSACTION_FAILED_VNPAY_65 = '65',
  TRANSACTION_FAILED_VNPAY_75 = '75',
  TRANSACTION_FAILED_VNPAY_79 = '79',
  TRANSACTION_FAILED_VNPAY_99 = '99',
}

// const vnpayResultByCode = {
//   0: { name: 'Giao dịch thành công' },
//   7: {
//     name: 'Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).',
//   },
//   9: {
//     name: 'Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.',
//   },
//   10: {
//     name: 'Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần',
//   },
//   11: {
//     name: 'Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.',
//   },
//   12: {
//     name: 'Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.',
//   },
//   13: {
//     name: 'Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.',
//   },
//   24: { name: 'Giao dịch không thành công do: Khách hàng hủy giao dịch' },
//   51: {
//     name: 'Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.',
//   },
//   65: {
//     name: 'Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.',
//   },
//   75: { name: 'Ngân hàng thanh toán đang bảo trì.' },
//   79: {
//     name: 'Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch',
//   },
//   99: {
//     name: 'Các lỗi khác (lỗi còn lại, không có trong danh sách mã lỗi đã liệt kê)',
//   },
// };
