import { defineStore } from 'pinia';
import storeId from './storeId';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

type State = {
  isLoading: boolean;
  isHideContactWidget: boolean;
  language: string;
};
export default defineStore({
  id: storeId.app,
  state: () =>
    ({
      isLoading: true,
      isHideContactWidget: true,
      language: getLocalStorage(StorageConstant.LANGUAGE),
    } as State),
  getters: {},
  actions: {
    setIsHideContactWidget(data) {
      this.isHideContactWidget = data;
    },
    setLanguage(key) {
      this.language = key;
    },
    setIsLoading(data) {
      this.isLoading = data;
    },
  },
});
