<template>
  <component :is="layout" class="font-sans w-full">
    <router-view />
  </component>
  <SynConfirmModal />
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import EmptyLayout from '@/ui/components/layouts/empty-layout.vue';
import DashboardLayout from '@/ui/components/layouts/dashboard-layout';
import PaymentLayout from '@/ui/components/layouts/payment/PaymentLayout.vue';
import BuyLicenseLayout from '@/ui/components/layouts/payment/BuyLicenseLayout.vue';
import systemStore from '@/store/system/system-store';

export default {
  name: 'App',
  components: {
    EmptyLayout,
    DashboardLayout,
    PaymentLayout,
    BuyLicenseLayout,
  },
  setup() {
    const { currentRoute } = useRouter();

    const layout = computed(() => `${currentRoute.value.meta.layout}-layout`);
    const _systemStore = systemStore();

    const initData = () => {
      _systemStore.initData();
    };

    initData();
    return {
      layout,
    };
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.small-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.small-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

.small-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.small-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.small-scrollbar:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.small-scrollbar {
  scrollbar-color: white white;
  scrollbar-width: thin;
}

.small-scrollbar:hover {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.ck-mentions::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.v-html a {
  text-decoration: underline;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
