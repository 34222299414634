import AuthRepository from '@/application/repositories/AuthRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default class AuthService {
  private static instance: AuthService;
  _authRepository: AuthRepository;

  constructor() {
    this._authRepository = AuthRepository.getInstance();
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      // Get from local storage
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  getCurrentLoginInformations(): Promise<any> {
    return this._authRepository.getCurrentLoginInformations();
  }

  selectOrganization(payload): Promise<any> {
    const deviceToken = getLocalStorage(StorageConstant.DEVICE_TOKEN);
    const jwtToken = getLocalStorage(StorageConstant.TOKEN);
    const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

    return this._authRepository.selectOrganization({
      ...payload,
      removingDeviceToken: deviceToken,
      jwtToken,
      refreshToken,
    });
  }

  checkVoucherCode(voucherCode: string): Promise<any> {
    return this._authRepository.checkVoucherCode(voucherCode);
  }
  getVoucherCodeDetail(voucherCode: string): Promise<any> {
    return this._authRepository.getVoucherCodeDetail(voucherCode);
  }
}
