<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 1707 1707"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg" id="Layer_x0020_1">
        <path
          d="m271 718c-53 0-96-43-96-96v-30h-133c-24 0-42-20-42-42v-458c0-24 19-42 42-42h458c24 0 42 19 42 42v133h15c53 0 96 43 96 96s-43 96-96 96h-15v133c0 24-20 42-42 42h-133v30c0 53-43 96-96 96zm1283 939c-217 0-433 0-650 0-15 0-26-13-26-27v-57c0-55-84-53-84 0v57c0 16-12 27-26 27h-163c-23 0-41-20-41-42v-133h-15c-53 0-96-43-96-96s43-96 96-96h15c0-109 0-216 0-325 0-34 46-26 68-26 55 0 54-84 0-84-23 0-68 8-68-26v-161c0-24 19-42 41-42h134v-30c0-53 43-96 96-96 52 0 95 42 95 94v31c101 0 199 1 299 1v-30c0-53 43-96 96-96s96 43 96 96v30h133c24 0 42 20 42 42v133h15c53 0 96 43 96 96s-43 96-96 96h-15v621c0 25-20 43-42 43zm-325-572c0-54 42-98 96-98s96 44 96 98v29h120v-149c0-34 46-26 68-26 56 0 55-84 0-84-22 0-68 8-68-26v-150h-148c-15 0-27-12-27-26v-59c0-55-83-53-83 0v59c0 15-12 26-26 26h-150v121c32 0 59 5 82 29 59 58 21 164-67 164h-15v122h122zm-122 519h434v-436h-148c-50 0-9-85-38-113-25-25-70-6-70 30v56c0 17-14 27-28 27h-150v148c0 35-46 27-68 27-56 0-54 82-2 82 23 0 70-8 70 28zm-368-489v-32c0-80 90-121 152-77 40 29 41 63 41 108h121v-149c0-34 46-26 68-26 55 0 54-84 0-84-22 0-68 8-68-26v-150h-149c-15 0-26-12-26-26v-59c0-55-84-53-84 0v59c0 15-12 26-26 26h-150v122h15c53 0 96 43 96 96s-43 96-96 96h-15v122zm314 489v-122h-16c-52 0-95-43-95-96 0-54 43-96 97-96h14v-122h-149c-15 0-26-13-26-27v-56c0-56-84-54-84-1v49c0 22-6 35-33 35h-143v148c0 35-46 27-68 27-55 0-54 83 0 83 22 0 68-8 68 27v148h122v-30c0-53 43-96 96-96s96 43 96 96v33zm-1000-1065h148c16 0 27 12 27 26v59c0 55 83 53 83 0v-59c0-15 13-26 26-26h149v-150c0-35 46-27 68-27 55 0 54-83 0-83-23 0-68 8-68-26v-150h-433z"
          fill="#ffff"
          data-original="#ffff"
          class=""
        ></path>
        <g fill="#57e4ca">
          <path
            d="m618 679v122h15c53 0 96 43 96 96s-43 96-96 96h-15v122h121v-32c0-80 90-121 152-77 40 29 41 63 41 108h121v-149c0-34 46-26 68-26 55 0 54-84 0-84-22 0-68 8-68-26v-150h-149c-15 0-26-12-26-26v-59c0-55-84-53-84 0v59c0 15-12 26-26 26z"
            fill="#f2cb05"
            data-original="#57e4ca"
            class=""
          ></path>
          <path
            d="m337 539h149v-150c0-35 46-27 68-27 55 0 54-83 0-83-23 0-68 8-68-26v-150h-433v436h148c16 0 27 12 27 26v59c0 55 83 53 83 0v-59c0-15 13-26 26-26z"
            fill="#f2cb05"
            data-original="#57e4ca"
            class=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
