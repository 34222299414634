import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import AuthService from '@/application/services/authenticate/AuthService';

export default defineStore({
  id: storeId.AUTH_MY_PROFILE,
  state: () => ({
    _unauthService: AuthService.getInstance(),
    myProfile: {} as any,
    diffTimeMiliseconds: 0,
    allMyPenddingInvitation: [] as any,
  }),
  getters: {
    getTimezone(state) {
      return state.myProfile?.utc || 0;
    },
  },
  actions: {
    async fetchCurrentLoginUser() {
      const res = await AuthService.getInstance().getCurrentLoginInformations();
      const userInfo = res?.result;
      this.myProfile = {
        ...userInfo,
        fullName: `${userInfo.lastName} ${userInfo.firstName}`,
        avatarUrl: `${userInfo.avatarUrl}`,
      };
      return userInfo;
    },
  },
});
