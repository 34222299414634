import { ref } from 'vue';

export default function stepperComposables(
  stepData: any,
  currentStepDefault: string
) {
  const stepperByKey = ref(stepData);
  const currentStep = ref(currentStepDefault);
  const updateCurrentStep = (step) => {
    if (!step) return;
    currentStep.value = step;
  };

  const onHandleGoToPreviousStep = () => {
    const currentStepData = stepperByKey.value[currentStep.value];
    if (
      currentStepData?.previousAction &&
      typeof currentStepData?.previousAction == 'function'
    )
      currentStepData?.previousAction();

    stepperByKey.value[currentStepData?.previous].isFinished = false;
    updateCurrentStep(currentStepData?.previous);
  };

  const onHandleGoToNextStep = () => {
    const currentStepData = stepperByKey.value[currentStep.value];
    if (
      currentStepData?.nextAction &&
      typeof currentStepData?.nextAction == 'function'
    )
      currentStepData?.nextAction();

    stepperByKey.value[currentStep.value].isFinished = true;
    updateCurrentStep(currentStepData?.next);
  };
  return {
    currentStep,
    stepperByKey,
    onHandleGoToPreviousStep,
    onHandleGoToNextStep,
  };
}
