export const PackagePrice = {
  FREE: {
    key: 'FREE',
  },
  PRO: {
    key: 'PRO',
  },
  VIP: {
    key: 'VIP',
  },
};
export const allPlanLevels = {
  FREE: 1,
  PRO: 2,
  VIP: 3,
};

export const iconPlans = {
  FREE: 'Mountain',
  PRO: 'Plane',
  VIP: 'Rocket',
};
export const paymentPlansId = {
  FREE: 'FREE',
  PRO: 'PRO',
  VIP: 'VIP',
};

export const tabPlanBilling = {
  MANAGE_PLAN: 'MANAGE_PLAN',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  BILLING_INFO: 'BILLING_INFO',
  BILLING_HISTORY: 'BILLING_HISTORY',
};
export const paymentStatus = {
  FREE: {
    key: 'FREE',
    label: 'COMMOM_LABEL_FREE',
    color: 'current',
  },
  ACTIVE: {
    key: 'ACTIVE',
    label: 'SETTING_PLAN_PAYMENT_STATUS_ACTIVE_LABEL',
    color: 'current',
  },
  PENDING: {
    key: 'PENDING',
    label: 'SETTING_PLAN_PAYMENT_STATUS_PENDING_LABEL',
    color: 'orange',
  },
  PAID: {
    key: 'PAID',
    label: 'SETTING_PLAN_PAYMENT_STATUS_PAID_LABEL',
    color: 'current',
  },
};
