export enum ZaloPayResponseCodeEnum {
  SUCCESS = '1',
  FAIL = '2',
  PROCESSING = '3',
}

// const momoResultByCode = {
//   0: { name: 'Giao dịch thành công.', description: '' },
//   9000: {
//     name: 'Giao dịch đã được xác nhận thành công.',
//     description:
//       'Đối với thanh toán 1 bước (autoCapture=1), đây có thể xem như giao dịch thanh toán đã thành công. Đối với thanh toán 2 bước (autoCapture=0), vui lòng thực hiện tiếp yêu cầu capture hoặc cancel. Đối với liên kết, vui lòng tiến hành yêu cầu lấy recurring token.',
//   },
//   8000: {
//     name: 'Giao dịch đang ở trạng thái cần được người dùng xác nhận thanh toán lại.',
//     description:
//       'Giao vẫn đang chờ người dùng xác nhận thanh toán; trạng thái của giao dịch sẽ được tự động thay đổi ngay sau khi người dùng xác nhận hoặc hủy thanh toán.',
//   },
//   7000: {
//     name: 'Giao dịch đang được xử lý.',
//     description: 'Vui lòng chờ giao dịch được xử lý hoàn tất.',
//   },
//   1000: {
//     name: 'Giao dịch đã được khởi tạo, chờ người dùng xác nhận thanh toán.',
//     description:
//       'Giao vẫn đang chờ người dùng xác nhận thanh toán; trạng thái của giao dịch sẽ được tự động thay đổi ngay sau khi người dùng xác nhận hoặc hủy thanh toán.',
//   },
//   11: {
//     name: 'Truy cập bị từ chối.',
//     description:
//       'Cấu hình tài khoản doanh nghiệp không cho phép truy cập. Vui lòng xem lại các thông tin đăng ký và cấu hình trên M4B, hoặc liên hệ trực tiếp với MoMo để được điều chỉnh.',
//   },
//   12: {
//     name: 'Phiên bản API không được hỗ trợ cho yêu cầu này.',
//     description:
//       'Vui lòng nâng cấp lên phiên bản mới nhất của cổng thanh vì, vì phiên bản bạn đang truy cấp hiện không còn được hỗ trợ.',
//   },
//   13: {
//     name: 'Xác thực doanh nghiệp thất bại.',
//     description:
//       'Vui lòng kiểm tra thông tin kết nối, bao gồm cả chữ ký mà bạn đang sử dụng, và đối chiếu với các thông tin được cung cấp từ M4B.',
//   },
//   20: {
//     name: 'Yêu cầu sai định dạng.',
//     description:
//       'Vui lòng kiểm tra định dạng của yêu cầu, các biến thể, hoặc tham số còn thiếu.',
//   },
//   22: {
//     name: 'Số tiền giao dịch không hợp lệ.',
//     description:
//       'Vui lòng kiểm tra nếu số tiền thanh toán nằm trong giới hạn quy định của yêu cầu thanh toán này. Đối với yêu cầu dạng capture, hãy kiểm tra số tiền capture có bằng với số tiền đã được xác nhận trước đó hay không.',
//   },
//   40: {
//     name: 'RequestId bị trùng.',
//     description: 'Vui lòng thử lại với một requestId khác.',
//   },
//   41: {
//     name: 'OrderId bị trùng.',
//     description:
//       'Vui lòng truy vấn trạng thái của orderId này, hoặc thử lại với một orderId khác.',
//   },
//   42: {
//     name: 'OrderId không hợp lệ hoặc không được tìm thấy.',
//     description: 'Vui lòng thử lại với một orderId khác.',
//   },
//   43: {
//     name: 'Yêu cầu bị từ chối vì xung đột trong quá trình xử lý giao dịch.',
//     description:
//       'Trước khi thử lại, vui lòng kiểm tra nếu có một giao dịch khác đang được xử lý có thể hạn chế yêu cầu này được tiếp nhận, hoặc orderId được sử dụng không phù hợp với yêu cầu này.',
//   },
//   1001: {
//     name: 'Giao dịch thanh toán thất bại do tài khoản người dùng không đủ tiền.',
//     description: '',
//   },
//   1002: {
//     name: 'Giao dịch bị từ chối do nhà phát hành tài khoản thanh toán.',
//     description:
//       'Sự từ chối xảy ra khi thẻ được dùng để thanh toán hiện không còn khả dụng, hoặc kết nối đến hệ thống của nhà phát hành thẻ bị gián đoạn. Vui lòng tạm thời sử dụng phương thức thanh toán khác.',
//   },
//   1003: {
//     name: 'Giao dịch bị đã bị hủy.',
//     description:
//       'Giao dịch bị hủy bởi doanh nghiệp hoặc bởi trình xử lý timeout của MoMo. Vui lòng đánh dấu giao dịch này đã bị hủy (giao dịch thất bại).',
//   },
//   1004: {
//     name: 'Giao dịch thất bại do số tiền thanh toán vượt quá hạn mức thanh toán của người dùng.',
//     description:
//       'Vui lòng đánh dấu giao dịch này thất bại, và thử lại vào một khoảng thời gian khác.',
//   },
//   1005: {
//     name: 'Giao dịch thất bại do url hoặc QR code đã hết hạn.',
//     description: 'Vui lòng gửi lại một yêu cầu thanh toán khác.',
//   },
//   1006: {
//     name: 'Giao dịch thất bại do người dùng đã từ chối xác nhận thanh toán.',
//     description: 'Please send another payment request.',
//   },
//   1007: {
//     name: 'Giao dịch bị từ chối vì tài khoản người dùng đang ở trạng thái tạm khóa.',
//     description:
//       'Vui lòng sử dụng một phương thức thanh toán khác không bị ràng buộc với tài khoản người dùng này. Đối với giao dịch, bạn có thể liên hệ với MoMo để được giúp đỡ.',
//   },
//   1026: {
//     name: 'Giao dịch bị hạn chế theo thể lệ chương trình khuyến mãi.',
//     description: 'Vui lòng liên hệ MoMo để biết thêm chi tiết.',
//   },
//   1030: {
//     name: 'Đơn hàng thanh toán thất bại do thông tin không hợp lệ.',
//     description: 'Vui lòng liên hệ MoMo để biết thêm chi tiết.',
//   },
//   1080: {
//     name: 'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu không được tìm thấy.',
//     description:
//       'Vui lòng kiểm tra nếu orderId hoặc TID được dùng trong yêu cầu này là chính xác.',
//   },
//   1081: {
//     name: 'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu có thể đã được hoàn.',
//     description:
//       'Vui lòng kiểm tra nếu giao dịch thanh toán ban đầu đã được hoàn thành công, hoặc số tiền hoàn vượt quá số tiền cho phép hoàn của giao dịch thanh toán ban đầu.',
//   },
//   2001: {
//     name: 'Giao dịch thất bại do sai thông tin liên kết.',
//     description:
//       'Token liên kết không tồn tại hoặc đã bị xóa, vui lòng cập nhật dữ liệu của bạn.',
//   },
//   2007: {
//     name: 'Giao dịch thất bại do liên kết hiện đang bị tạm khóa.',
//     description:
//       'Token liên kết hiện đang ở trạng thái không hoạt động, do người dùng đã chủ động tạm khóa liên kết.',
//   },
//   3001: {
//     name: 'Liên kết thất bại do người dùng từ chối xác nhận.',
//     description: '',
//   },
//   3002: {
//     name: 'Liên kết bị từ chối do không thỏa quy tắc liên kết.',
//     description:
//       'Từ chối này thường xảy ra khi partnerClientId dùng trong yêu cầu đã được liên kết với một hoặc nhiều tài khoản MoMo trước đó. Vui lòng liên hệ MoMo để biết thêm chi tiết.',
//   },
//   3003: {
//     name: 'Hủy liên kết bị từ chối do đã vượt quá số lần hủy.',
//     description: 'Vui lòng liên hệ MoMo để biết thêm chi tiết.',
//   },
//   3004: {
//     name: 'Liên kết này không thể hủy do có giao dịch đang chờ xử lý.',
//     description:
//       'Vui lòng kiểm tra nếu có bất kỳ giao dịch chờ liên quan đén token này chưa được yêu cầu capture hoặc cancel (hủy).',
//   },
//   4001: {
//     name: 'Giao dịch bị hạn chế do người dùng chưa hoàn tất xác thực tài khoản.',
//     description: '',
//   },
//   4010: {
//     name: 'Quá trình xác minh OTP thất bại.',
//     description:
//       'Quá trình xác minh người dùng thất bại. Vui lòng gửi một yêu cầu xác minh người dùng khác để thử lại.',
//   },
//   4011: {
//     name: 'OTP chưa được gửi hoặc hết hạn.',
//     description: 'Vui lòng yêu cầu gửi một mã OTP khác.',
//   },
//   4100: {
//     name: 'Giao dịch thất bại do người dùng không đăng nhập thành công.',
//     description: '',
//   },
//   4015: {
//     name: 'Quá trình xác minh 3DS thất bại.',
//     description:
//       'Quá trình xác minh người dùng thất bại. Vui lòng gửi một yêu cầu xác minh người dùng khác để thử lại.',
//   },
//   10: {
//     name: 'Hệ thống đang được bảo trì.',
//     description: 'Vui lòng quay lại sau khi bảo trì được hoàn tất.',
//   },
//   99: {
//     name: 'Lỗi không xác định.',
//     description: 'Vui lòng liên hệ MoMo để biết thêm chi tiết.',
//   },
// };
