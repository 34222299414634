<template>
  <div
    class="h-full flex items-center w-max px-4 py-2 font-medium text-current-500 rounded-t-xl cursor-pointer"
  >
    <syn-avatar
      v-if="isHaveIcon"
      class="cursor-pointer text-current-800 justify-center"
      :name="label.split(' ')[label.split(' ').length - 1] || 'A V'"
      custom-class="w-6 h-6"
      :src="avt?.replace('original', 'mini')"
    ></syn-avatar>
    <div class="">{{ label }}</div>
    <SynIcon
      v-if="isHaveIcon"
      class="ml-4 hover:bg-current-100 rounded-full"
      name="close"
      @click="close"
    />
  </div>
</template>

<script>
export default {
  name: 'SynTabHeader',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    avt: {
      type: String,
      default: '',
    },
    isHaveIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const close = () => {
      emit('close', false);
    };
    return {
      close,
    };
  },
};
</script>
