import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
} from '@/ui/hooks/storageHook';
import { AdminType, SettingProgressEnum } from '@/ui/common/constants/constant';

type OrgSetting = {
  chatEnabled: boolean;
  gedEnabled: boolean;
  videoCallEnabled: boolean;
  dayoffEnabled: boolean;
  calendarEnabled: boolean;
  timeWorkingEnabled: boolean;
};

export class MyOrganizationSingleton {
  private static instance: MyOrganizationSingleton;
  organizations: any;
  activeOrganization: number;
  token: string;
  refreshToken: string;
  myUserId: number;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */

  private constructor(
    organizations: any,
    activeOrganization: number,
    token: string,
    refreshToken: string,
    myUserId: number
  ) {
    this.organizations = organizations;
    this.activeOrganization = activeOrganization;
    this.token = token;
    this.refreshToken = refreshToken;
    this.myUserId = myUserId;
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): MyOrganizationSingleton {
    if (!MyOrganizationSingleton.instance) {
      // Get from local storage

      const organizations = getLocalStorage(StorageConstant.ORGANIZATIONS);
      const activeOrganization = getLocalStorage(StorageConstant.ACTIVE_ORG);
      const token = getLocalStorage(StorageConstant.TOKEN);
      // const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

      if (!organizations || !token) this.reLogin();

      MyOrganizationSingleton.instance = new MyOrganizationSingleton(
        organizations ? JSON.parse(organizations) : [],
        parseInt(activeOrganization),
        token,
        '',
        0
      );
    }

    return MyOrganizationSingleton.instance;
  }

  static reLogin(): void {
    location.reload();
  }

  getActiveOrganization(): OrganizationSetting {
    if (this.invalidOrganizationData()) return new OrganizationSetting();
    return this.organizations[this.activeOrganization];
  }

  getNumberOfLogin(): number {
    if (this.invalidOrganizationData()) return 0;
    return this.organizations[this.activeOrganization].numberOfLogins;
  }

  getUserRole(): number | null {
    if (this.invalidOrganizationData()) return null;
    return this.organizations[this.activeOrganization].role;
  }

  getOrganizationSettingProgress(): SettingProgressEnum | null {
    if (this.invalidOrganizationData()) return null;
    return this.organizations[this.activeOrganization].settingProgress;
  }

  getOrganizationRole(): SettingProgressEnum | null {
    if (this.invalidOrganizationData()) return null;
    return this.organizations[this.activeOrganization].orgRole;
  }

  getCurrentTaskDrawer(): ITaskDrawer {
    if (this.invalidOrganizationData())
      return {
        currentTabId: 0,
        allTabByIds: [],
        allTabIds: [],
      };

    return this.organizations[this.activeOrganization].taskDrawer;
  }

  invalidOrganizationData() {
    const organizations = MyOrganizationSingleton.instance.organizations;
    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    return (
      !organizations || Object.keys(organizations).length == 0 || !activeOrgId
    );
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */
  public static setInstance(
    organizations: any[],
    activeOrganization: number,
    token: string,
    refreshToken: string,
    numberOfLogins: number,
    organizationSettingProgress: SettingProgressEnum | null
  ): void {
    // 0. clean previous org's data

    // save to local storage
    const localStorageOrganizations = getLocalStorage(
      StorageConstant.ORGANIZATIONS
    );
    const orgByIds = localStorageOrganizations
      ? JSON.parse(localStorageOrganizations)
      : organizations.reduce((o, n) => {
          const orgId = n.id;
          const orgSetting = new OrganizationSetting(
            orgId,
            n.name,
            n.logoUrl,
            n.isDefault,
            0,
            orgId == activeOrganization ? organizationSettingProgress : null,
            numberOfLogins,
            {
              currentTabId: 0,
              allTabByIds: {},
              allTabIds: [],
            }
          );
          return {
            ...o,
            [orgId]: orgSetting,
          };
        }, {});

    MyOrganizationSingleton.instance = new MyOrganizationSingleton(
      orgByIds,
      activeOrganization,
      token,
      refreshToken,
      0
    );

    this.updateOrganizationLocalStorage(orgByIds);

    setLocalStorage(StorageConstant.ACTIVE_ORG, activeOrganization);
    setLocalStorage(StorageConstant.TOKEN, token);
    // setLocalStorage(StorageConstant.REFRESH_TOKEN, refreshToken);
  }

  public static setUserRole(role: number): void {
    if (MyOrganizationSingleton.instance.invalidOrganizationData()) return;

    const organizations = MyOrganizationSingleton.instance.organizations;
    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    organizations[activeOrgId].role = role;

    this.updateOrganizationLocalStorage(organizations);
  }

  public static setUserId(userId: number): void {
    this.instance.myUserId = userId;
    setLocalStorage(StorageConstant.CURRENT_USER_ID, userId);
  }

  public static setActiveOrganization(organizationId: number): void {
    MyOrganizationSingleton.instance.activeOrganization = organizationId;
  }

  public static setNumberOfLogin(count: number): void {
    if (MyOrganizationSingleton.instance.invalidOrganizationData()) return;

    const organizations = MyOrganizationSingleton.instance.organizations;
    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    organizations[activeOrgId].numberOfLogins = count;

    this.updateOrganizationLocalStorage(organizations);
  }

  public static setOrganizationSettingProgress(
    settingProgress: SettingProgressEnum
  ): void {
    if (
      !MyOrganizationSingleton.instance ||
      MyOrganizationSingleton.instance.invalidOrganizationData()
    )
      return;

    const organizations = MyOrganizationSingleton.instance.organizations;
    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    organizations[activeOrgId].settingProgress = settingProgress;

    this.updateOrganizationLocalStorage(organizations);
  }

  public static setOrganizationTaskDrawer(taskDrawer: ITaskDrawer): void {
    if (MyOrganizationSingleton.instance.invalidOrganizationData()) return;

    const organizations = MyOrganizationSingleton.instance.organizations;

    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    organizations[activeOrgId].taskDrawer = taskDrawer;

    this.updateOrganizationLocalStorage(organizations);
  }

  public static pushNewOrganization(newOrg): void {
    if (MyOrganizationSingleton.instance.invalidOrganizationData()) return;

    const organizations = MyOrganizationSingleton.instance.organizations;
    const newOrgSetting = new OrganizationSetting(
      newOrg.id,
      newOrg.name,
      newOrg.logoUrl,
      false,
      null,
      newOrg.settingProgress,
      0,
      {
        currentTabId: 0,
        allTabByIds: {},
        allTabIds: [],
      }
    );
    // const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;
    const newOrganizations = {
      ...organizations,
      [newOrg.id]: newOrgSetting,
    };

    MyOrganizationSingleton.instance.organizations = newOrganizations;
    this.updateOrganizationLocalStorage(newOrganizations);
  }

  public static replaceOrganizations(orgs): void {
    if (MyOrganizationSingleton.instance.invalidOrganizationData()) return;

    const organizations = MyOrganizationSingleton.instance.organizations;

    orgs
      .filter((org) => !organizations[org.id])
      .forEach((organization) => {
        this.pushNewOrganization(organization);
      });

    this.updateOrganizationLocalStorage(
      MyOrganizationSingleton.instance.organizations
    );
  }

  private static updateOrganizationLocalStorage(organizations) {
    setLocalStorage(
      StorageConstant.ORGANIZATIONS,
      JSON.stringify(organizations)
    );
  }

  public static setOrgRole(setting: OrgSetting): void {
    if (
      !MyOrganizationSingleton.instance ||
      MyOrganizationSingleton.instance.invalidOrganizationData()
    )
      return;

    const organizations = MyOrganizationSingleton.instance.organizations;
    const activeOrgId = MyOrganizationSingleton.instance.activeOrganization;

    organizations[activeOrgId].orgRole = setting;

    this.updateOrganizationLocalStorage(organizations);
  }
}

export class OrganizationSetting {
  id: number;
  name: string;
  logoUrl: string;
  isDefault: boolean;
  role: number | null;
  settingProgress: SettingProgressEnum | null;
  numberOfLogins: number;
  taskDrawer: ITaskDrawer;
  orgRole: OrgSetting;

  constructor(
    id = 0,
    name = '',
    logoUrl = '',
    isDefault = false,
    role: AdminType | null = null,
    settingProgress: SettingProgressEnum | null = null,
    numberOfLogins = 0,
    taskDrawer: ITaskDrawer = {
      currentTabId: 0,
      allTabByIds: {},
      allTabIds: [],
    }
  ) {
    this.id = id;
    this.name = name;
    this.logoUrl = logoUrl;
    this.isDefault = isDefault;
    this.role = role;
    this.settingProgress = settingProgress;
    this.numberOfLogins = numberOfLogins;
    this.taskDrawer = taskDrawer;
    this.orgRole = {
      chatEnabled: false,
      gedEnabled: false,
      videoCallEnabled: false,
      dayoffEnabled: false,
      calendarEnabled: false,
      timeWorkingEnabled: false,
    };
  }
}

interface ITaskDrawer {
  currentTabId: number;
  allTabByIds: any;
  allTabIds: any[];
}
