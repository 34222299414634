
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SynTabz',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentTab: {
      type: String,
      default: '',
    },
  },
  emits: ['changeTab'],
  setup: (props, { emit }) => {
    let isActive = ref('');
    const currentItem = computed(() => props.currentTab);
    const changeTab = (item) => {
      emit('changeTab', item);
    };
    return { isActive, changeTab, currentItem };
  },
});
