import axios, { AxiosResponse } from 'axios';
import Qs from 'qs';
import {
  StorageConstant,
  getLocalStorage,
  clearLocalStorage,
} from '@/ui/hooks/storageHook';
import router from '@/ui/router';

const debug = process.env.NODE_ENV !== 'production';

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_TICTOP_SERVER}/api/payment/`,
  // // withCredentials: true, // send cookies when cross-domain requests
  timeout: debug ? 100000 : 20000, // request timeout
  paramsSerializer: (params) =>
    Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

// request interceptor
service.interceptors.request.use(
  async (config) => {
    const token = getLocalStorage(StorageConstant.TOKEN);
    const requestTime = Date.now();
    // do something before request is sent
    const location = getLocalStorage(StorageConstant.LOCATION);
    const language = getLocalStorage(StorageConstant.LANGUAGE);

    config.headers = {
      ...config.headers,
      'x-request-time': requestTime,
      'x-location': location,
      'x-lang': language,
    };

    if (token && typeof token == 'string')
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token,
      };

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  (response: AxiosResponse<any>) => {
    const { success } = response.data;
    if (success) {
      return response.data;
    }

    return Promise.reject(response.data);
  },
  async (error) => {
    console.log('🚀 Hyrin ~ error', error.response);
    if (error && error.response) {
      const { status } = error.response;
      console.log('🚀 ~ api error: ', {
        error: error,
        response: error?.response,
      });
      // 401: Token expired;
      switch (status) {
        case 404:
          {
            router.push({ name: 'Notfound' });
          }
          break;
        case 400:
        case 500:
          {
            clearLocalStorage({ isSwitchingOrg: false });
          }
          break;
        case 401:
          {
            clearLocalStorage({ isSwitchingOrg: false });
            router.push({
              name: 'HomePage',
            });
          }
          break;

        default:
          clearLocalStorage({ isSwitchingOrg: false });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
