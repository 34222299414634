<template>
  <input
    ref="inputEmailRef"
    v-model="value"
    type="email"
    class="w-full rounded-md border-gray-200 focus:border-current focus:ring focus:ring-current focus:ring-opacity-30"
  />
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import Awesomplete from 'awesomplete';

export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    let _emailAwesomplete;

    const inputEmailRef = ref();

    onMounted(() => {
      _emailAwesomplete = new Awesomplete(inputEmailRef.value, {
        list: [
          'gmail.com',
          'outlook.com',
          'yahoo.com',
          'live.com',
          'hotmail.com',
        ],
        data: (text, input) => {
          return input.slice(0, input.indexOf('@')) + '@' + text;
        },
        filter: Awesomplete.FILTER_STARTSWITH,
        sort: false,
      });

      inputEmailRef.value?.addEventListener(
        'awesomplete-selectcomplete',
        (e) => {
          emit('update:modelValue', e?.text?.value);
        }
      );
    });

    onUnmounted(() => {
      _emailAwesomplete && _emailAwesomplete.destroy();
    });

    return {
      inputEmailRef,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style>
@import '~awesomplete/awesomplete.css';

.awesomplete {
  @apply w-full;
}

.awesomplete ul[role='listbox'] {
  @apply bg-white transition-none;
}

.awesomplete ul[role='listbox'] li[role='option'] {
  @apply font-bold hover:bg-current-50;
}

.awesomplete ul[role='listbox'] li[role='option'] mark {
  @apply bg-transparent font-normal;
}

.awesomplete ul[role='listbox'] li[role='option'][aria-selected='true'] {
  @apply bg-current;
}

.awesomplete > ul:before {
  @apply hidden;
}
</style>
