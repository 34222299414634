<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 505.92 505.91"
  >
    <circle
      class="cls-7"
      style="
        opacity: 0.32;
        stroke: #fff;
        fill: #dceef2;
        stroke-miterlimit: 10;
        stroke-width: 6px;
      "
      cx="235.73"
      cy="270.18"
      r="232.73"
    />
    <g>
      <path
        class="cls-11"
        style="fill: #30a2a2"
        d="M198.08,191.05l111.03-111.03c24.57-24.56,53.3-43.75,85.41-57.04,32.05-13.26,65.87-19.98,100.54-19.98,2.56,0,5.16,.04,7.73,.11,2.11,72.14-25.83,142.59-76.9,193.68l-111.03,111.03-116.78-116.78Z"
      />
      <path
        class="cls-2"
        style="fill: #fff"
        d="M495.07,0V6c1.59,0,3.19,.01,4.79,.04,.64,34.14-5.34,67.57-17.81,99.46-13.15,33.63-32.76,63.63-58.29,89.16l-108.91,108.91-112.53-112.53,108.91-108.91c24.29-24.28,52.69-43.25,84.43-56.39,31.68-13.11,65.12-19.75,99.39-19.75V0m0,0c-70.39,0-138.09,27.93-188.07,77.9l-113.15,113.15,121.02,121.02,113.15-113.15C480.49,146.42,508.66,74.39,505.69,.21c-3.55-.14-7.09-.21-10.63-.21h0Z"
      />
    </g>
    <path
      class="cls-15"
      style="fill: #8fa2ff"
      d="M498.24,74.31c-35.24-3.54-63.11-31.41-66.65-66.65C455.82,1.72,480.76-.79,505.69,.21c1.04,24.93-1.47,49.88-7.45,74.1Z"
    />
    <path
      class="cls-10"
      style="fill: #008e8e"
      d="M192.84,191.05l121.02,121.02-27.93,9.31-102.4-102.4,9.31-27.93Z"
    />
    <path
      class="cls-8"
      style="
        stroke: #fff;
        fill: #005c5c;
        stroke-miterlimit: 10;
        stroke-width: 6px;
      "
      d="M318.42,311.07l41.89,41.89,41.89-41.89v-83.78l-83.78,83.78Z"
    />
    <path
      class="cls-3"
      style="fill: #ffa100"
      d="M354.34,363.31l60.52-60.52c3.85-3.85,10.1-3.85,13.95,0h0c3.85,3.85,3.85,10.1,0,13.95l-60.52,60.52c-3.85,3.85-10.1,3.85-13.95,0h0c-3.85-3.85-3.85-10.1,0-13.95Z"
    />
    <path
      class="cls-8"
      style="
        stroke: #fff;
        fill: #005c5c;
        stroke-miterlimit: 10;
        stroke-width: 6px;
      "
      d="M193.84,187.05l-41.89-41.89,41.89-41.89h83.78l-83.78,83.78Z"
    />
    <path
      class="cls-3"
      style="fill: #ffa100"
      d="M128.67,135.81l60.52-60.52c3.85-3.85,10.1-3.85,13.95,0h0c3.85,3.85,3.85,10.1,0,13.95l-60.52,60.52c-3.85,3.85-10.1,3.85-13.95,0h0c-3.85-3.85-3.85-10.1,0-13.95Z"
    />
    <circle
      class="cls-1"
      style="fill: #42dbdb"
      cx="384.67"
      cy="121.23"
      r="55.85"
    />
    <circle
      class="cls-2"
      style="fill: #fff"
      cx="384.67"
      cy="121.23"
      r="37.24"
    />
    <g>
      <path
        class="cls-3"
        style="fill: #ffa100"
        d="M495.95,71.02c-31.6-4.35-56.72-29.46-61.07-61.06,19.69-4.62,39.9-6.96,60.13-6.96,2.58,0,5.19,.04,7.78,.12,.71,22.85-1.59,45.66-6.84,67.91Z"
      />
      <path
        class="cls-2"
        style="fill: #fff"
        d="M495.01,0V6s0,0,0,0c1.61,0,3.23,.02,4.85,.05,.41,20.7-1.67,41.37-6.21,61.58-13.72-2.46-26.49-9.09-36.39-18.99-9.9-9.9-16.53-22.66-18.99-36.38,18.61-4.15,37.66-6.26,56.73-6.26V0m0,0c-21.36,0-42.65,2.57-63.42,7.66,3.54,35.24,31.41,63.11,66.65,66.65,5.98-24.22,8.49-49.17,7.45-74.1C502.13,.07,498.57,0,495.01,0h0Z"
      />
    </g>
    <path
      class="cls-13"
      style="fill: #005c5c"
      d="M276.62,312.07l-9.31,27.93-102.4-102.4,27.93-9.31,83.78,83.78Z"
    />
    <path
      class="cls-2"
      style="fill: #fff"
      d="M229.61,425.87c11.7,10.05,13.05,27.68,3,39.38-.4,.46-.81,.91-1.23,1.35,5.63,11.25,8.38,23.73,8.01,36.31h-4.65C106.2,502.91,2,398.71,2,270.18v-4.65c12.58-.37,25.05,2.38,36.31,8.01,11.02-10.79,28.7-10.6,39.49,.42,.43,.44,.84,.88,1.23,1.35,21.38-15.45,51-12.12,68.42,7.68l31.42-31.42,74.47,74.47-31.42,31.42c19.8,17.42,23.13,47.04,7.68,68.42h0Z"
    />
    <path
      class="cls-9"
      style="fill: #dceef2"
      d="M137.98,340c-2.57,0-4.65-2.08-4.65-4.65,0-12.85-10.42-23.27-23.27-23.27s-23.27,10.42-23.27,23.27c0,2.57-2.08,4.65-4.65,4.65s-4.65-2.08-4.65-4.65c0-7.71-6.25-13.96-13.96-13.96-2.57,0-4.65-2.08-4.65-4.65s2.08-4.65,4.65-4.65c6.8,0,13.27,2.98,17.69,8.15,8.35-15.94,28.05-22.09,43.98-13.73,10.73,5.63,17.46,16.74,17.46,28.86,0,2.57-2.08,4.65-4.65,4.65Z"
    />
    <path
      class="cls-9"
      style="fill: #dceef2"
      d="M189.18,447.05c-2.57,0-4.65-2.08-4.65-4.65,0-7.71-6.25-13.96-13.96-13.96-2.57,0-4.65-2.08-4.65-4.65s2.08-4.65,4.65-4.65c12.85,0,23.27-10.42,23.27-23.27s-10.42-23.27-23.27-23.27c-2.57,0-4.65-2.08-4.65-4.65s2.08-4.65,4.65-4.65c17.99,0,32.58,14.59,32.58,32.58,0,12.12-6.72,23.23-17.46,28.86,5.17,4.42,8.15,10.88,8.15,17.69,0,2.57-2.08,4.65-4.65,4.65Z"
    />
    <g>
      <path
        class="cls-2"
        style="fill: #fff"
        d="M114.71,149.16c0,17.99-14.59,32.58-32.58,32.58H20.41c12.51-30.31,31.26-57.65,55.02-80.24,3.38,4.4,5.56,9.59,6.33,15.08,17.99-.21,32.75,14.21,32.95,32.21,0,.12,0,.25,0,.37Z"
      />
      <path
        class="cls-2"
        style="fill: #fff"
        d="M421.91,409.81c-19.75,26.28-44.83,48.11-73.59,64.05-17.38-4.67-27.68-22.54-23.01-39.92,3.86-14.36,16.94-24.29,31.81-24.13,2.71-17.79,19.33-30.01,37.12-27.3,14.09,2.15,25.15,13.21,27.3,27.3h.37Z"
      />
      <path
        class="cls-2"
        style="fill: #fff"
        d="M68.54,149.16c-1.4,0-.37,.74-.37-4.65-.02,1.56,.11,3.12,.37,4.65Z"
      />
    </g>
    <circle class="cls-2" style="fill: #fff" cx="212.45" cy="14.18" r="13.96" />
    <circle
      class="cls-5"
      style="fill: #f2cb05"
      cx="44.89"
      cy="223.63"
      r="13.96"
    />
    <circle
      class="cls-3"
      style="fill: #ffa100"
      cx="468.45"
      cy="274.83"
      r="13.96"
    />
    <circle
      class="cls-9"
      style="fill: #dceef2"
      cx="417.25"
      cy="488.94"
      r="13.96"
    />
    <rect
      class="cls-3"
      style="fill: #ffa100"
      x="185.37"
      y="257.96"
      width="105.32"
      height="19.73"
      rx="9.87"
      ry="9.87"
      transform="translate(-119.67 246.75) rotate(-45)"
    />
    <g>
      <path
        class="cls-10"
        style="fill: #008e8e"
        d="M391.28,115.01l-11.18,11.18c-6.17,6.17-16.18,6.17-22.35,0h0l22.35-22.35c3.09-3.09,8.09-3.09,11.18,0h0c3.09,3.09,3.09,8.09,0,11.18h0Z"
      />
      <path
        class="cls-6"
        style="fill: #f2cb05; isolation: isolate; opacity: 0.63"
        d="M389.34,102.38c-3.06-1.58-6.79-1-9.22,1.43l-.73,.73c-2.43,3.15-2.15,7.61,.66,10.42l5.64,5.64,5.6-5.6c2.94-2.94,3.1-7.64,.37-10.78l-.43-.43c-.56-.56-1.19-1.03-1.89-1.4Z"
      />
      <circle
        class="cls-4"
        style="fill: #005167"
        cx="385.71"
        cy="109.4"
        r="6.59"
      />
      <path
        class="cls-12"
        style="fill: #8fcdcd"
        d="M392.66,131.68h0c.93-.25,1.89,.3,2.14,1.24l.45,1.69c.25,.93-.3,1.89-1.24,2.14h0c-.93,.25-1.89-.3-2.14-1.24l-.45-1.69c-.25-.93,.3-1.89,1.24-2.14Z"
      />
      <path
        class="cls-14"
        style="fill: #61b9b9"
        d="M385.61,132.3h0c.97,0,1.75,.78,1.75,1.75v1.75c0,.97-.78,1.75-1.75,1.75,0,0,0,0,0,0h0c-.97,0-1.75-.78-1.75-1.75h0s0-1.75,0-1.75c0-.97,.78-1.75,1.75-1.75Z"
      />
      <path
        class="cls-10"
        style="fill: #008e8e"
        d="M377.13,136.64h0c-.93-.25-1.49-1.21-1.24-2.14,0,0,0,0,0,0h0l.45-1.69c.26-.93,1.22-1.47,2.16-1.21,.92,.26,1.46,1.2,1.22,2.12l-.45,1.69c-.25,.93-1.21,1.48-2.14,1.24Z"
      />
      <path
        class="cls-5"
        style="fill: #f2cb05"
        d="M391.67,104.22c2.72,3.13,2.56,7.84-.37,10.77l-5.6,5.6,5.54,5.54c6.17,6.17,16.18,6.17,22.35,0l-21.92-21.92Z"
      />
    </g>
  </svg>
</template>
