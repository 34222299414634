import {
  StorageConstant,
  getLocalStorage,
  clearLocalStorage,
} from '@/ui/hooks/storageHook';
// import createTaskFormIndexDB from '@/indexedDB/task/create-task-form';
import clearAllStore from '@/store/clear-all';

export const getWishByStartHour = (hour) => {
  if (hour < 7.5) {
    return 'HEADER_LABEL_WELCOME_WISH_1';
  }
  if (hour < 8) {
    return 'HEADER_LABEL_WELCOME_WISH_2';
  }
  if (hour < 8.5) {
    return 'HEADER_LABEL_WELCOME_WISH';
  }
  if (hour < 9) {
    return 'HEADER_LABEL_WELCOME_WISH_3';
  }
  if (hour < 10) {
    return 'HEADER_LABEL_WELCOME_WISH_4';
  }
  return 'HEADER_LABEL_WELCOME_WISH_5';
};

export const getUserStatus = (workInfo) => {
  if (!workInfo?.online && workInfo?.startWD && !workInfo?.stopWD) {
    return 'absent';
  }
  if (!workInfo?.online) {
    return 'offline';
  }
  return 'online';
};

export enum AlertType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}
export enum NotificationPositionType {
  RightBottom = 'right-bottom',
  TopCenter = 'top-center',
  TopCenterRight = 'top-center-right',
  TopRight = 'top-right',
}

export enum NotificationTypeEnum {
  task = 'task',
  chat = 'chat',
  call = 'call',
  calendar = 'calendar',
  dayoff = 'dayoff',
  working_status = 'working_status',
  group = 'group',
  group_new = 'group_new',
  organization_new = 'organization_new',
}
export interface IBaseNotification {
  id?: number;
  type?: AlertType;
  title?: string;
  body?: any;
  duration?: number;
  position?: NotificationPositionType;
  metaData?: any;
  notificationValue?: any;
  notificationType?: NotificationTypeEnum;
}

export const clearAllSession = () => {};

export const getDomainUrl = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  return location === 'EU'
    ? process.env.VUE_APP_DOMAIN_EU
    : process.env.VUE_APP_DOMAIN_DEFAULT;
};
export const getAllDomainUrl = () => {
  return {
    eu: 'app.tictop.eu',
    default: 'tictop.app',
    local: 'localhost:8080',
    alpha: 'tictop-alpha.com',
  };
};

export const loginAgain = () => {
  clearAllLocalData();
};
export const clearAllLocalData = async (isSwitchingOrg?) => {
  try {
    clearLocalStorage({
      isSwitchingOrg: !!isSwitchingOrg,
    });

    clearAllStore();
    // await createTaskFormIndexDB.getInstance().clearAll();
  } catch (error) {
    console.log('🚀 Hyrin ~ clearAllLocalData ~ error', error);
  }
};
