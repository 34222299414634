export const fomatPrice = (originValue) => {
  if (!originValue) return 0;
  const viPrice = Math.floor(originValue * 23);
  const lastNumber = parseInt(viPrice.toString().slice(-1));
  return (viPrice - (lastNumber + 1)) * 1000;
};

export const formatPrice = (originValue) => {
  if (!originValue || originValue < 0) return 0;
  return Math.floor(originValue / 100) * 100;
};
