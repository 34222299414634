export const CRangePrice = [
  {
    key: '0 - 50',
    des: '',
    percent: 100,
    percentLabel: 0,
    color: 'rgba(45,212,191,1)',
  },
  {
    key: '51 - 100',
    des: '25% off this range',
    percentLabel: 25,
    percent: 0,
    color: 'rgba(20,184,166,1)',
  },
  {
    key: '101 - 200',
    des: '30% off this range',
    percent: 0,
    percentLabel: 30,
    color: 'rgba(13,148,136,1)',
  },
  {
    key: '201 - infinity',
    des: '40% off this range',
    percent: 0,
    percentLabel: 40,
    color: 'rgba(0,141,141,1)',
  },
];
