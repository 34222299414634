<script setup></script>

<template>
  <div class="flex items-center justify-center w-full">
    <div class="w-full md:w-3/4 flex flex-col px-12 py-8">
      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách thanh toán</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <span class="flex flex-col space-y-3 text-gray-600 leading-loose">
            <span
            >Người dùng Tictop có thể thanh toán nhanh chóng và tiện lợi tại
             website <i>https://pay.tictop.vn</i> hoặc ngay trên <i>ứng dụng Tictop</i> bằng các hình thức sau:</span
            >
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>Thanh toán qua Visa, Mastercard</li>
              <li>Thanh toán qua ví điện tử MoMo, ZaloPay, VNPay</li>
            </ul>
            <span>
              Nếu cần hỗ trợ thanh toán trên ứng dụng, bạn có thể liên hệ với
              Tictop qua email
              <i class="text-current-500 hover:text-current"
              ><a href="mailto:services@tictop.app?subject=Support For Customer"
              >services@tictop.app</a
              ></i
              >
              hoặc Facebook Tictop.
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
