<script setup></script>

<template>
  <div class="flex items-center justify-center w-full">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 3rem 6rem 3rem;
        height: 24rem;
      "
      class="w-3/4 flex-col"
    >
      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách đổi trả</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <span class="flex flex-col space-y-3 text-gray-600 leading-loose">
            Chúng tôi hỗ trợ khách hàng đổi trả mã trong trường hợp mã lỗi,
            không kích hoạt được, mã quá thời hạn.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
