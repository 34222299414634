import router from './router';
// import { Message } from "element-ui";
// import getPageTitle from "@/utils/get-page-title";
import NProgress from 'nprogress';

const whiteList = []; // no redirect whitelist

NProgress.configure({ showSpinner: false }); // NProgress Configuration
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const token = '123';
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    if (token) {
      const permissionCreated = true;
      if (permissionCreated) {
        next();
      } else {
        // store.dispatch('router/generateRoutes');
        next({ ...to, replace: true });
      }
    } else {
      // has no token
      {
        // next(`/login?redirect=${to.fullPath.replace('?', '&')}`);
        next('/login');
      }
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
