<script setup>
import { onMounted, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  menuList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

onMounted(() => {
  onScrollTo(props.modelValue);
});

watch(
  () => props.modelValue,
  () => {
    onScrollTo(props.modelValue);
  }
);
const activeClass = 'text-current-500 bg-current-50 border border-current-600';
const onScrollTo = (keyName) => {
  emit('update:modelValue', keyName);
  const element = document.getElementById(`syn-tab-scroll-item-${keyName}`);
  element && element.scrollIntoView({ block: 'start' });
};
</script>

<template>
  <slot name="prefix-header"></slot>
  <div
    class="flex items-center flex-wrap w-full text-xs p-2 space-x-1 relative"
    style="min-height: 3rem"
  >
    <div
      v-for="(menu, index) in menuList"
      :key="index"
      :class="
        modelValue == menu.key ? activeClass : ' bg-gray-100 text-gray-500'
      "
      class="py-1 font-semibold cursor-pointer rounded-full m-0.5 px-2 relative"
      style="box-sizing: border-box"
      @click="onScrollTo(menu.key)"
    >
      <span>{{ $t(menu.labelKey) || menu.label }}</span>
      <div
        v-if="menu.isSelected"
        class="absolute -top-0.5 -right-0.5 h-2.5 w-2.5 rounded-full bg-current-500"
      ></div>
    </div>
    <slot name="prefix"></slot>
  </div>
  <div
    id="syn-tab-scroll-intersection-root"
    class="flex-1 overflow-auto small-scrollbar pb-3"
    style="scroll-behavior: smooth"
  >
    <slot name="body"></slot>
  </div>
</template>
