import { defineStore } from 'pinia';
import PaymentService from '@/application/services/payment/PaymentService';
import storeId from '@/base/store/storeId';
import { PaymentTransactionLog } from '@/application/interfaces/payment/TransactionInterfaces';
import { IPaymentPayload } from '@/application/types/payment.types';
import paymentDetailStore from '@/store/payment/paying-store';
import { setLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import router from '@/ui/router';
import { diff } from '@/ui/hooks/hook-date';
import plansStore from '@/store/plans';
import organizationStore from '@/store/organization';

type PaymentState = {
  _paymentService: PaymentService;
  paymentConfigs: any;
  billingHistory: any;
  isLoadingBillingHistory: boolean;
  exchangeRatesBySymbol: any;
  detailTransaction: PaymentTransactionLog;
  isLoadingDetailTransaction: any;
  isShowCurrentPlanGlobalModal: boolean;
  isCreatingPaymentToken: boolean;
  paymentPayload: IPaymentPayload | null;
  paymentConfigsByProvider: any;
  isLoading: boolean;
};

export default defineStore({
  id: storeId.PAYMENT_SETTING,
  state: () =>
    ({
      _paymentService: PaymentService.getInstance(),
      paymentConfigs: {},
      billingHistory: [] as any,
      isLoadingBillingHistory: false,
      exchangeRatesBySymbol: {},
      detailTransaction: {},
      isLoadingDetailTransaction: false,
      isShowCurrentPlanGlobalModal: false,
      isCreatingPaymentToken: false,
      paymentPayload: null,
      paymentConfigsByProvider: {},
      isLoading: true,
    } as PaymentState),
  getters: {},
  actions: {
    async getPaymentConfigs() {
      const res = await PaymentService.getInstance().getPaymentConfigs();

      this.paymentConfigs = res.result;
      this.exchangeRatesBySymbol = this.paymentConfigs?.exchangeRates?.reduce(
        (oldValue, currentValue) => {
          return {
            ...oldValue,
            [currentValue.symbol]: currentValue,
          };
        },
        {}
      );
      this.paymentConfigsByProvider = this.paymentConfigs?.providers?.reduce(
        (oldValue, currentValue) => {
          return {
            ...oldValue,
            [currentValue.key]: currentValue,
          };
        },
        {}
      );
    },

    updatePaymentPayload(data) {
      this.paymentPayload = { ...this.paymentPayload, ...data };
    },
    async checkAndMakeNewPayment(data: IPaymentPayload) {
      try {
        this.paymentPayload = data;
        this.isCreatingPaymentToken = true;
        const currentOrgPlan = plansStore().currentOrgPlan;
        if (diff(currentOrgPlan?.endDate, new Date(), 'day') > 0) {
          this.isShowCurrentPlanGlobalModal = true;
          this.isCreatingPaymentToken = false;
          return;
        }

        this.createPaymentToken(data);
      } catch (e) {
        console.log(e);
      } finally {
        this.isCreatingPaymentToken = false;
      }
    },
    async createPaymentToken(data: IPaymentPayload) {
      const payload = { ...data };
      try {
        this.isCreatingPaymentToken = true;

        const totalUsers = payload.totalUsers
          ? payload.totalUsers
          : getDefaultTotalUserByOrganizationSize();

        const res = await this._paymentService.createPaymentToken({
          planId: payload.planId,
          purpose: payload.purpose || null,
          period: payload.period || 12,
          totalUsers,
        });

        const paymentRequestToken = res.result;

        paymentDetailStore().updateSessionPayment({
          token: paymentRequestToken,
          startTime: Date.now(),
        });
        paymentDetailStore().changePlanId('');
        setLocalStorage(StorageConstant.TOKEN, paymentRequestToken);

        router.push({
          name: 'PaymentDetailPage',
        });

        return res.result;
      } catch (e) {
        console.log(e);
      } finally {
        this.isCreatingPaymentToken = false;
      }
    },

    async getPaymentHistories() {
      try {
        this.isLoadingBillingHistory = true;
        const res = await this._paymentService.getPaymentHistories();
        this.billingHistory = res['result'];
        this.isLoadingBillingHistory = false;
      } catch (e) {
        this.billingHistory = [];
        this.isLoadingBillingHistory = false;
      }
    },

    async getTransactionDetail(id) {
      try {
        this.isLoadingDetailTransaction = true;
        const res = await this._paymentService.getTransactionDetail(id);
        this.detailTransaction = res['result'];
        this.isLoadingDetailTransaction = false;
      } catch (e) {
        this.isLoadingDetailTransaction = false;
        console.log(e);
      }
    },
  },
});

const getDefaultTotalUserByOrganizationSize = () => {
  let totalUsers = 10;
  switch (organizationStore().organizationInfo?.size) {
    case '2-5':
      totalUsers = 5;
      break;
    case '6-10':
      totalUsers = 10;
      break;
    case '11-20':
      totalUsers = 20;
      break;
    case '21-50':
      totalUsers = 50;
      break;

    default:
      totalUsers = 10;
      break;
  }

  return totalUsers;
};
