import { createApp } from 'vue';
import App from './ui/App.vue';
import { createPinia } from 'pinia';
import './ui/index.css';
import './ui/interceptor';
import 'nprogress/nprogress.css';
import router from './ui/router';
import './assets/tailwind.css';
import { declare } from './ui/hooks/declareComponents';
import customDirective from '@/ui/directive';
import { myi18n } from '@/ui/plugins/i18n/myi18n';
import { detectLocation } from '@/ui/app-global-state';
import filters from '@/ui/plugins/filters';

detectLocation();
const requireCommon = require.context(
  './ui/common/',
  true,
  /(Syn|Vig|Atom)[A-Z]\w+\.(vue|js)$/
);

const layoutComponent = require.context(
  './ui/components/layouts',
  true,
  /[A-Z]\w+\.(vue|js)$/
);

const iconComponent = require.context(
  './ui/components/icons',
  true,
  /[A-Z]\w+\.(vue|js)$/
);

const app = createApp(App);
app.use(createPinia());
app.use(myi18n);
app.use(filters);

app.directive('cusFocus', {
  // When the bound element is mounted into the DOM...
  mounted(el, binding) {
    // Focus the element
    if (binding.value) {
      el.focus();
    }
  },
});

customDirective(app);

declare(requireCommon, app);
declare(iconComponent, app);
declare(layoutComponent, app);

app.use(router);
app.mount('#app');
