import PaymentService from '@/application/services/payment/PaymentService';

export default class MakePaymentClass {
  lastPrice: number;
  planMarket: string;
  periodUnit: string;
  period: number;
  planId: string;
  quantity: number;
  subscription: boolean;
  receiveInvoice: boolean;
  paymentMethod: string;
  currencyUnit: string;
  locate: string;
  payerFirstName: string;
  payerLastName: string;
  payerEmail: string;
  payerPhone: string;
  ipAddress: string;
  startDate: string;
  paymentRequestToken: string;
  voucherCode: string;
  constructor(data) {
    this.planId = data?.planId;
    this.quantity = data?.quantity;
    this.subscription = data?.subscription;
    this.receiveInvoice = data?.receiveInvoice || false;
    this.paymentMethod = data?.paymentMethod;
    this.payerFirstName = data?.payerFirstName;
    this.payerLastName = data?.payerLastName;
    this.payerEmail = data?.payerEmail;
    this.payerPhone = data?.payerPhone;
    this.lastPrice = data?.lastPrice;
    this.startDate = data?.startDate;
    this.locate = data?.locate;

    this.planMarket = data?.planMarket || 'VN';
    this.periodUnit = data?.periodUnit || 'MONTH';
    this.period = data?.period;
    this.currencyUnit = data?.currencyUnit;
    this.ipAddress = data?.ipAddress;
    this.paymentRequestToken = data?.paymentRequestToken;
    this.voucherCode = data?.voucherCode;
  }

  makePayment() {
    return PaymentService.getInstance().makePayment(this);
  }
}
