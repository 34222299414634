import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be0d2642"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.navClass)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.key,
        class: _normalizeClass([_ctx.isActive == item.key ? 'tab-active' : 'tab-default', "flex-auto"]),
        onClick: ($event: any) => (_ctx.changeTab(item.key))
      }, _toDisplayString(item.name), 11, _hoisted_1))
    }), 128))
  ], 2))
}