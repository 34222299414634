import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import PaymentService from '@/application/services/payment/PaymentService';
import { IPaymentInformation } from '@/application/interfaces/PaymentInterfaces';

type TPaymentSession = {
  token: string;
  startTime: number;
};

// Generated by https://quicktype.io

export default defineStore({
  id: storeId.PAYMENT,
  state: () =>
    ({
      isLoading: false,
      paymentInformation: {},
      exchangeRatesBySymbol: {},
      paymentRemainTime: {
        remainDay: 361.0402523702743,
        remainMonths: 12.034675079009142,
      },
      currentPaymentSession: {
        token: '',
        startTime: 0,
      },
      allPlanComparativeCriteria: [],
    } as {
      isLoading: boolean;
      paymentInformation: IPaymentInformation;
      exchangeRatesBySymbol: any;
      paymentRemainTime: {
        remainDay: number;
        remainMonths: number;
        discountPeriodPercent: number;
      };
      currentPaymentSession: TPaymentSession;
      allPlanComparativeCriteria: any;
    }),
  getters: {},
  actions: {
    async initData() {
      const result = await PaymentService.getInstance().getPaymentInformation();
      const newIntentPlanId = this.paymentInformation?.newIntentPlanId;
      this.paymentInformation = {
        ...result,
        newIntentPlanId,
      };
      return Promise.all([
        // this.getDefaultData(),
        this.getPaymentRemainMonths(),
      ]);
    },

    async getPaymentRemainMonths() {
      const res = await PaymentService.getInstance().getPaymentRemainMonths();

      console.log('🚀 Hyrin ~ getPaymentRemainMonths ~ res.result', res.result);
      this.paymentRemainTime = res.result;
    },
    async calculatePaymentTotalAmount(payload) {
      const res =
        await PaymentService.getInstance().calculatePaymentTotalAmount(payload);
      return res.result;
    },

    changePlanId(planId) {
      this.paymentInformation = {
        ...this.paymentInformation,
        newIntentPlanId: planId,
      };
    },

    syncZaloPayOrderStatus(orderTransId) {
      return PaymentService.getInstance().syncZaloPayOrderStatus(orderTransId);
    },

    updateSessionPayment(data: TPaymentSession) {
      this.currentPaymentSession = {
        ...this.currentPaymentSession,
        ...data,
        startTime: Date.now(),
      };
    },

    async getAllPlanComparativeCriteria() {
      let res = [];
      try {
        res =
          await PaymentService.getInstance().getAllPlanComparativeCriteria();
        this.allPlanComparativeCriteria =
          res['result'].map((o) => {
            return {
              ...o,
              translations: o.translations?.reduce(
                (a, v) => ({ ...a, [v.lang]: v }),
                {}
              ),
              criteria: o?.criteria?.map((e) => {
                return {
                  ...e,
                  criteriaTranslations: e.criteriaTranslations?.reduce(
                    (a, v) => ({
                      ...a,
                      [v.lang]: v,
                    }),
                    {}
                  ),
                };
              }),
            };
          }) || [];
      } catch (e) {
        this.allPlanComparativeCriteria = [];
      }
    },
  },
});
