<script setup></script>

<template>
  <!-- NEW -->
  <div class="flex items-center justify-center w-full">
    <div
        style="display: flex; flex-wrap: wrap; padding: 3rem 3rem 6rem 3rem"
        class="w-3/4"
    >
      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách bảo mật thông tin</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <ul style="list-style-type: decimal; margin-left: 2rem">
            <li class="container-text">
              <span class="font-medium">Mục đích và phạm vi thu thập thông tin</span><br>
              <span class="text-gray-600 leading-loose">
                Tictop không bán, chia sẻ hay trao đổi thông tin cá nhân của khách hàng thu thập trên trang web hoặc ứng dụng Tictop cho một bên thứ ba nào khác.<br/>
                Thông tin cá nhân thu thập được sẽ chỉ được sử dụng trong nội bộ công ty.
                Khi bạn liên hệ đăng ký dịch vụ, thông tin cá nhân mà Tictop thu thập bao gồm:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Họ và tên</li>
                  <li>Địa chỉ</li>
                  <li>Điện thoại</li>
                  <li>Email</li>
                </ul>
                Ngoài thông tin cá nhân là các thông tin về dịch vụ:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Tên sản phẩm</li>
                  <li>Số lượng</li>
                  <li>Thời gian giao nhận sản phẩm</li>
                </ul>
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Phạm vi sử dụng thông tin</span><br>
              <span class="text-gray-600 leading-loose">
                Thông tin cá nhân thu thập được sẽ chỉ được Tictop sử dụng trong nội bộ công ty và cho một hoặc tất cả các mục đích sau đây:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Hỗ trợ khách hàng</li>
                  <li>Cung cấp thông tin liên quan đến dịch vụ</li>
                  <li>Xử lý đơn đặt hàng và cung cấp dịch vụ và thông tin qua trang web hoặc ứng dụng của chúng tôi theo yêu cầu của bạn</li>
                  <li>Chúng tôi có thể sẽ gửi thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng kí nhận email thông báo.</li>
                </ul>
                Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ quản lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch tài chính liên quan đến các khoản thanh toán trực tuyến của bạn.
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Thời gian lưu trữ thông tin</span><br>
              <span class="text-gray-600 leading-loose">
                Đối với thông tin cá nhân, Tictop chỉ xóa đi dữ liệu này nếu khách hàng có yêu cầu, khách hàng yêu cầu gửi mail về <a
                  href="mailto:services@tictop.app?subject=Support Customer">services@tictop.app</a>
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Những người hoặc tổ chức có thể được tiếp cận với thông tin cá nhân</span><br>
              <span class="text-gray-600 leading-loose">
                Đối tượng được tiếp cận với thông tin cá nhân của khách hàng thuộc một trong những trường hợp sau:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>CÔNG TY CỔ PHẦN CÔNG NGHỆ VÀ TIN HỌC VIG</li>
                  <li>Các đối tác có ký hợp động thực hiện 1 phần dịch vụ do CÔNG TY CỔ PHẦN CÔNG NGHỆ VÀ TIN HỌC VIG. Các đối tác này sẽ nhận được những thông tin theo thỏa thuận hợp đồng (có thể 1 phần hoặc toàn bộ thông tin tuy theo điều khoản hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do Công ty cung cấp.</li>
                </ul>
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</span><br>
              <span class="text-gray-600 leading-loose">
                Công ty cổ phần Công Nghệ Và Tin Học VIG
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Địa chỉ: Tầng 5 Toà nhà Diamond Flower, đường Hoàng Đạo Thuý, P. Nhân Chính, Q. Thanh Xuân, Tp. Hà Nội</li>
                  <li>Điện thoại: <span style="font-weight: 600">(024) 99 99 96 98</span></li>
                  <li>Website: <span style="font-weight: 600">www.tictop.vn</span></li>
                  <li>Email: <span style="font-weight: 600">services@tictop.app</span></li>
                </ul>
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình</span><br>
              <span class="text-gray-600 leading-loose">
                Khách hàng có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình trực tiếp trên website, ứng dụng Tictop hoặc bằng cách gửi thông tin cần chỉnh sửa vào email <a
                  href="mailto:services@tictop.app?subject=Update Information ">services@tictop.app</a>.
              </span>
            </li>

            <li class="container-text">
              <span class="font-medium">Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo.</span><br>
              <span class="text-gray-600 leading-loose">
                Tại Tictop, việc bảo vệ thông tin cá nhân của bạn là rất quan trọng, bạn được đảm bảo rằng thông tin cung cấp cho chúng tôi sẽ được mật Tictop cam kết không chia sẻ, bán hoặc cho thuê thông tin cá nhân của bạn cho bất kỳ người nào khác. Tictop cam kết chỉ sử dụng các thông tin của bạn vào các trường hợp sau:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Nâng cao chất lượng dịch vụ dành cho khách hàng</li>
                  <li>Giải quyết các tranh chấp, khiếu nại</li>
                  <li>Khi cơ quan pháp luật có yêu cầu</li>
                </ul>
                Tictop hiểu rằng quyền lợi của bạn trong việc bảo vệ thông tin cá nhân cũng chính là trách nhiệm của chúng tôi nên trong bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính sách bảo mật của Tictop, và liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo vui lòng liên hệ qua số hotline <span
                  style="font-weight: 600;">(024) 99 99 96 98</span> hoặc email <a
                  href="mailto:services@tictop.app?subject=Support Customer">services@tictop.app</a>
              </span>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>

  <!--  <div class="flex items-center justify-center w-full">-->
  <!--    <div-->
  <!--      style="display: flex; flex-wrap: wrap; padding: 3rem 3rem 6rem 3rem"-->
  <!--      class="w-3/4"-->
  <!--    >-->
  <!--      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách bảo mật</div>-->
  <!--      <div class="flex flex-col space-y-10">-->
  <!--        <div class="flex flex-col space-y-3">-->
  <!--          <span class="font-medium">Bảo vệ quyền riêng tư</span>-->
  <!--          <span class="text-gray-600 leading-loose">-->
  <!--            <b>VIG TECHNOLOGY., JSC</b> bảo mật toàn bộ thông tin của Khách hàng-->
  <!--            và những nội dung các tài khoản sử dụng của Khách hàng trao đổi, lưu-->
  <!--            trữ trên nền tảng Tictop. Chúng tôi sẽ thực hiện và duy trì tất cả-->
  <!--            các biện pháp bảo vệ nội bộ cần thiết và trong khả năng của chúng-->
  <!--            tôi; mang tính hành chính, vật lý và kỹ thuật để bảo vệ cho tính bảo-->
  <!--            mật và toàn vẹn đối với dữ liệu Khách hàng.<br />-->
  <!--            <b>VIG TECHNOLOGY., JSC</b> cam kết:-->
  <!--            <ul style="list-style-type: decimal; margin-left: 2rem">-->
  <!--              <li>-->
  <!--                Không chủ động thay đổi các dữ liệu được tạo ra bởi Khách hàng.-->
  <!--                Điều này không bao hàm việc hệ thống phần mềm có thể tự động sửa-->
  <!--                đổi kích cỡ, định dạng dữ liệu để phù hợp với mục đích hiển thị-->
  <!--                và chức năng sử dụng.-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Không truy cập vào dữ liệu của Khách hàng trừ trường hợp khắc-->
  <!--                phục lỗi kỹ thuật hoặc theo yêu cầu của Khách hàng hoặc trường-->
  <!--                hợp phải tuân theo quy định của pháp luật.-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Không tiết lộ dữ liệu của Khách hàng ngoại trừ một trong các-->
  <!--                trường hợp sau:-->
  <!--                <ul style="list-style-type: circle; margin-left: 2rem">-->
  <!--                  <li>Có sự chấp thuận của Khách hàng.</li>-->
  <!--                  <li>Phải tuân theo quy định của pháp luật.</li>-->
  <!--                  <li>Thuộc trường hợp quy định tại Điều khoản sử dụng này.</li>-->
  <!--                </ul>-->
  <!--              </li>-->
  <!--            </ul>-->
  <!--            Mặc dù vậy, <b>VIG TECHNOLOGY., JSC</b> có quyền tiếp cận một cách-->
  <!--            chủ động hoặc tự động thông tin về các tài khoản đang tồn tại trên-->
  <!--            hệ thống (bao gồm Họ tên, email) cho một số mục đích nhất định, bao-->
  <!--            gồm:<br />-->
  <!--            <ul style="list-style-type: decimal; margin-left: 2rem">-->
  <!--              <li>-->
  <!--                Thông báo bằng email đến Khách hàng về các tính năng mới hoặc-->
  <!--                các sản phẩm mới.-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Thông báo bằng email đến Khách hàng về các sự kiện, chương trình-->
  <!--                khuyến mại, thông tin về sự cố sản phẩm, dịch vụ…-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Liên hệ trực tiếp với Khách hàng để hỗ trợ kỹ thuật hoặc các hỗ-->
  <!--                trợ khác nhằm tăng trải nghiệm người dùng.-->
  <!--              </li>-->
  <!--            </ul>-->
  <!--            <b>VIG TECHNOLOGY., JSC</b> toàn quyền sử dụng tên của Khách hàng-->
  <!--            cho mục đích Marketing hoặc trong các chiến dịch quảng bá, giới-->
  <!--            thiệu sản phẩm hoặc trong các cuộc nói chuyện, trao đổi với các-->
  <!--            Khách hàng khác của <b>VIG TECHNOLOGY., JSC</b> hoặc các Khách hàng-->
  <!--            tiềm năng khác của <b>VIG TECHNOLOGY., JSC</b>. Ví dụ,-->
  <!--            <b>VIG TECHNOLOGY., JSC</b> có thể đưa tên Khách hàng vào trang web-->
  <!--            hiển thị tên tất cả các Khách hàng của mình.-->
  <!--          </span>-->
  <!--        </div>-->

  <!--        <div class="flex flex-col space-y-3">-->
  <!--          <span class="font-medium"-->
  <!--            >Dữ liệu của Khách hàng và trách nhiệm của các bên</span-->
  <!--          >-->
  <!--          <span class="text-gray-600 leading-loose">-->
  <!--            Dữ liệu của Khách hàng là mọi thông tin mà Khách hàng gửi, tải lên,-->
  <!--            truyền hoặc bằng bất kể phương thức nào giúp hiển thị thông tin đó-->
  <!--            trên tài khoản Tictop của Khách hàng. Dữ liệu đó bao gồm: các tin-->
  <!--            nhắn, các thông báo, các tập tin, các hình ảnh, các video, các đường-->
  <!--            dẫn, các nội dung liên quan đến công việc và tất cả mọi thông tin-->
  <!--            được hiển thị trên Hệ thống Tictop của Khách hàng; Dữ liệu của Khách-->
  <!--            hàng bao gồm cả mọi thông tin, đường dẫn, văn bản mà Khách hàng và-->
  <!--            các tài khoản sử dụng của Khách hàng nhập vào Tictop thông qua các-->
  <!--            nền tảng lưu trữ đám mây như Google Drive, OneDrive…<br />-->

  <!--            Khách hàng có quyền sử dụng và kiểm soát dữ liệu được đăng tải trên-->
  <!--            tài khoản Tictop của Khách hàng. <b>VIG TECHNOLOGY., JSC</b> cung-->
  <!--            cấp các tính năng có sẵn, theo hiện trạng và áp dụng chung cho tất-->
  <!--            cả Khách hàng của <b>VIG TECHNOLOGY., JSC</b> để Khách hàng xóa hoặc-->
  <!--            trích xuất dữ liệu ra bên ngoài hệ thống tổ chức Tictop. Khách hàng-->
  <!--            chịu mọi trách nhiệm cho tất cả các dữ liệu mà Khách hàng hoặc tài-->
  <!--            khoản sử dụng của Khách hàng gửi đến và/hoặc gửi đi từ nền tảng-->
  <!--            Tictop.<br />-->

  <!--            Khách hàng hiểu rằng <b>VIG TECHNOLOGY., JSC</b> không sở hữu bất kì-->
  <!--            dữ liệu nào mà Khách hàng đăng tải lên Tictop; trừ các tài liệu và-->
  <!--            hoặc giao diện hướng dẫn được <b>VIG TECHNOLOGY., JSC</b> chủ động-->
  <!--            cung cấp công khai cho Khách hàng.<br />-->

  <!--            Việc quyết định đưa dữ liệu nào lên nền tảng Tictop là toàn quyền-->
  <!--            của Khách hàng. Tuy nhiên, những nội dung sau sẽ KHÔNG ĐƯỢC PHÉP-->
  <!--            đăng tải trên nền tảng Tictop:<br />-->

  <!--            <ul style="list-style-type: decimal; margin-left: 2rem">-->
  <!--              <li>-->
  <!--                Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn đến-->
  <!--                con người và tài sản.-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Liên quan đến việc công bố các thông tin hoặc tài liệu lừa đảo,-->
  <!--                gây mất uy tín danh dự, quấy rối hoặc mang tính khiêu dâm.-->
  <!--              </li>-->
  <!--              <li>-->
  <!--                Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, tôn giáo,-->
  <!--                giới tính, người tàn tật.-->
  <!--              </li>-->
  <!--              <li>Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác.</li>-->
  <!--              <li>-->
  <!--                Cản trở hoặc phá hỏng Tictop (bao gồm nhưng không giới hạn bởi-->
  <!--                việc truy cập trái phép Tictop thông qua bất cứ phương tiện máy-->
  <!--                móc, phần mềm).-->
  <!--              </li>-->
  <!--            </ul>-->

  <!--            <b>VIG TECHNOLOGY., JSC</b> có toàn quyền vô hiệu hóa quyền truy cập-->
  <!--            vào nội dung dữ liệu của Khách hàng nếu chúng tôi xác định dữ liệu-->
  <!--            được truy cập vi phạm Điều khoản này, hoặc chúng tôi nhận được đơn-->
  <!--            khiếu nại từ tài khoản sử dụng, hoặc thông báo vi phạm sở hữu trí-->
  <!--            tuệ và/hoặc thông báo nội dung có dấu hiệu vi phạm pháp luật của các-->
  <!--            cơ quan có thẩm quyền.<br />-->

  <!--            Khách hàng tự chịu trách nhiệm trước pháp luật nếu sử dụng phần mềm-->
  <!--            trái với quy định của pháp luật.<br />-->
  <!--          </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<style scoped>
li::marker {
  font-weight: 600;
}

a {
  font-weight: 600;
  text-decoration: underline;
}

.container-text {
  margin-bottom: 2rem;
}
</style>
