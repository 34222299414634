export const VOUCHER_UI_BY_STATUS = {
  valid: {
    bg: '#CEE8E9 0% 0% no-repeat padding-box',
    text: '#008E8E',
    descriptionText: '#404040',
    highlightText: '#008E8E',
    discountText: '#FF8800',
    expiredDateText: '#707070',
  },
  error: {
    bg: '#FFF6F6 0% 0% no-repeat padding-box',
    text: '#A5A5A5',
    descriptionText: '#CECECE',
    highlightText: '#EF4444',
    discountText: '#A5A5A5',
    expiredDateText: '#CECECE',
  },
  expired: {
    bg: '#FFF6F6 0% 0% no-repeat padding-box',
    text: '#A5A5A5',
    descriptionText: '#CECECE',
    highlightText: '#EF4444',
    discountText: '#A5A5A5',
    expiredDateText: '#EF4444',
  },
};
