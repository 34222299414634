export default function clearAll() {
  const requireCommon = require.context(
    '../store/',
    true,
    /[0-9a-z]\w+\.(ts|js)$/
  );

  requireCommon.keys().forEach((fileName: string) => {
    if (
      ['./clear-all.ts', './common-store.ts', './storeId.ts'].includes(fileName)
    )
      return;

    const componentConfig = requireCommon(fileName);

    if (
      !componentConfig ||
      !componentConfig.default ||
      typeof componentConfig.default !== 'function'
    )
      return;
    componentConfig.default().$reset();
  });
}
