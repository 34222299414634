import PlansRepository from '@/application/repositories/PlansRepository';
import { EPlanCapacityKeys } from '@/application/enums/PlanEnums';

export default class PlansService {
  private static instance: PlansService;
  _plansRepository: PlansRepository;

  constructor() {
    this._plansRepository = PlansRepository.getInstance();
  }

  public static getInstance(): PlansService {
    if (!PlansService.instance) PlansService.instance = new PlansService();
    return PlansService.instance;
  }

  getCurrentOrgPlan(): Promise<any> {
    return this._plansRepository.getCurrentOrgPlan();
  }

  getAllPlansDetail(): Promise<any> {
    return this._plansRepository.getAllPlansDetail();
  }
  getOrgPlanCapacity(filters: EPlanCapacityKeys[]) {
    return this._plansRepository.getOrgPlanCapacity(filters);
  }
  checkOrganizationUsageExceed() {
    return this._plansRepository.checkOrganizationUsageExceed();
  }
}
