import BaseRepository from '@/base/repositories/BaseRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { IPaymentPayload } from '../types/payment.types';
import { NewPayment } from './types/payment.repository.types';

export default class PaymentRepository {
  private static instance: PaymentRepository;
  baseRepository: BaseRepository;

  constructor() {
    this.baseRepository = new BaseRepository();
  }

  public static getInstance(): PaymentRepository {
    if (!PaymentRepository.instance) {
      // Get from local storage
      PaymentRepository.instance = new PaymentRepository();
    }

    return PaymentRepository.instance;
  }

  getPaymentInformation() {
    return this.baseRepository.requestWithAuthorize({
      url: 'Payment/GetRequestTokenInfo',
      method: 'get',
    });
  }

  getAllPlans() {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetAllPlansDetail?lang=${getLocalStorage(
        StorageConstant.LANGUAGE
      )}`,
      method: 'get',
    });
  }

  calculatePaymentTotalAmount(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/CalculatePaymentTotalAmount?quantity=${data.quantity}&pricingUnit=${data.pricingUnit}&currencyUnit=USD`,
      method: 'get',
    });
  }

  getCurrentPlan() {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetCurrentOrgPlan`,
      method: 'get',
    });
  }

  getPaymentRemainMonths() {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/GetPaymentRemainMonths`,
      method: 'get',
    });
  }

  getPaymentConfigs() {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/GetPaymentConfigs`,
      method: 'get',
    });
  }

  makePayment(data: NewPayment) {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/CreatePayUrl`,
      method: 'post',
      data,
    });
  }

  syncZaloPayOrderStatus(orderTransId) {
    return this.baseRepository.requestWithAuthorize({
      url: `/zalo-pay/sync-order-status?orderTransId=${orderTransId}`,
      method: 'post',
    });
  }

  createPaymentToken(data: IPaymentPayload): any {
    return this.baseRepository.requestWithAuthorize({
      url: 'Payment/CreateSession',
      method: 'post',
      data,
    });
  }

  getPaymentHistories() {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/GetPaymentHistories`,
      method: 'get',
    });
  }

  getTransactionDetail(idTransaction): any {
    return this.baseRepository.requestWithAuthorize({
      url: `Payment/GetTransactionDetail?id=${idTransaction}`,
      method: 'get',
    });
  }

  getAllPlanComparativeCriteria(): any {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetAllPlanComparativeCriteria`,
      method: 'get',
    });
  }
  getAvailableVouchers(): any {
    return this.baseRepository.requestWithAuthorize({
      url: `Voucher/GetAvailableVouchers`,
      method: 'get',
    });
  }
}

// Generated by https://quicktype.io
