import UnAuthRepository from '@/application/repositories/UnauthRepository';
import { ICreatePayLicenseUrl } from '@/application/types/payment.types';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export default class UnAuthService {
  private static instance: UnAuthService;
  _unauthRepository: UnAuthRepository;

  constructor() {
    this._unauthRepository = UnAuthRepository.getInstance();
  }

  public static getInstance(): UnAuthService {
    if (!UnAuthService.instance) {
      // Get from local storage
      UnAuthService.instance = new UnAuthService();
    }

    return UnAuthService.instance;
  }
  checkPhone(phone): Promise<any> {
    return this._unauthRepository.checkPhone(phone);
  }
  checkEmail(phone): Promise<any> {
    return this._unauthRepository.checkEmail(phone);
  }
  checkUserHasPasswordByEmail(data: string): Promise<any> {
    return this._unauthRepository.checkUserHasPasswordByEmail(data);
  }

  checkUserHasPasswordByPhone(data: string): Promise<any> {
    return this._unauthRepository.checkUserHasPasswordByPhone(data);
  }

  sendVerificationCodeByPhoneNumber(data): Promise<any> {
    return this._unauthRepository.sendVerificationCodeByPhoneNumber(data);
  }
  reSendVerificationCodeByPhoneNumber(data): Promise<any> {
    return this._unauthRepository.reSendVerificationCodeByPhoneNumber(data);
  }
  sendVerificationCodeByEmail(data: any): Promise<any> {
    return this._unauthRepository.sendVerificationCodeByEmail(data);
  }
  reSendVerificationCodeByEmail(data: any): Promise<any> {
    return this._unauthRepository.reSendVerificationCodeByEmail(data);
  }
  verifyUserPhoneNumber(data): Promise<any> {
    return this._unauthRepository.verifyUserPhoneNumber(data);
  }
  getInvitedUser(data): Promise<any> {
    return this._unauthRepository.getInvitedUser(data);
  }
  async saveUserProfile(
    data: any,
    refId: string,
    voucherCode: string
  ): Promise<any> {
    const res = await this._unauthRepository.saveUserProfile(
      data,
      refId,
      voucherCode
    );
    return res?.result;
  }
  // Login
  verifyToken(): Promise<any> {
    const token = getLocalStorage(StorageConstant.TOKEN);
    return this._unauthRepository.verifyToken({ jwtToken: token });
  }
  loginWithPassword(payload): Promise<any> {
    return this._unauthRepository.loginWithPassword(payload);
  }
  loginByPhoneCode(data: any): Promise<any> {
    return this._unauthRepository.loginByPhoneCode(data);
  }
  loginByEmailCode(data: any): Promise<any> {
    return this._unauthRepository.loginByEmailCode(data);
  }
  getIpInfo(): Promise<any> {
    return this._unauthRepository.getIpInfo();
  }
  checkVoucherCode(voucherCode: string): Promise<any> {
    return this._unauthRepository.checkVoucherCode(voucherCode);
  }
  getVoucherCodeDetail(voucherCode: string): Promise<any> {
    return this._unauthRepository.getVoucherCodeDetail(voucherCode);
  }
  verifySpecialOfferToken(data: {
    organizationId: number;
    userId: number;
    token: string;
  }): Promise<any> {
    return this._unauthRepository.verifySpecialOfferToken(data);
  }
  getLicenseRegistrationDetailInfo(registerId: string): Promise<any> {
    return this._unauthRepository.getLicenseRegistrationDetailInfo(registerId);
  }
  createPayUrlForLicense(data: ICreatePayLicenseUrl): Promise<any> {
    return this._unauthRepository.createPayUrlForLicense(data);
  }
  getLicenseResource(data: { paymentRequestToken: string }): Promise<any> {
    return this._unauthRepository.getLicenseResource(data);
  }
}
