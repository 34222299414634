import CatalogRepository from '@/application/repositories/CatalogRepository';
import UnauthRepository from '../repositories/UnauthRepository';

export default class CatalogService {
  private static instance: CatalogService;
  _catalogRepository: CatalogRepository;
  _unauthRepository: UnauthRepository;

  constructor() {
    this._catalogRepository = CatalogRepository.getInstance();
    this._unauthRepository = UnauthRepository.getInstance();
  }

  public static getInstance(): CatalogService {
    if (!CatalogService.instance) {
      // Get from local storage
      CatalogService.instance = new CatalogService();
    }

    return CatalogService.instance;
  }
  async saveOrganizationSetting(payload) {
    const res = await this._catalogRepository.saveOrganizationSetting({
      organizationInfo: payload,
      positions: [],
      taskTypes: [],
      domains: [],
    });
    return res.result;
  }

  getUserProfile(): Promise<any> {
    return this._catalogRepository.getUserProfile();
  }
  getOrganizationConfigDefault(): Promise<any> {
    return this._catalogRepository.getOrganizationConfigDefault();
  }
  getCheckCode(data): Promise<any> {
    return this._catalogRepository.getCheckCode(data);
  }
  getCheckEmailPhone(data): Promise<any> {
    return this._catalogRepository.getCheckEmailPhone(data);
  }
  checkEmailPhoneInOrganization(data): Promise<any> {
    return this._catalogRepository.checkEmailPhoneInOrganization(data);
  }
  saveTeam(data): Promise<any> {
    return this._catalogRepository.saveTeam(data);
  }
  saveTeamDraft(data): Promise<any> {
    return this._catalogRepository.saveTeamDraft(data);
  }
  getDraftTeam(): Promise<any> {
    return this._catalogRepository.getDraftTeam();
  }
  getOrganizationInfo(id: string): Promise<any> {
    return this._catalogRepository.getOrganizationInfo(id);
  }
  updateOrganization(data: any): Promise<any> {
    return this._catalogRepository.updateOrganization(data);
  }

  uploadAvatarAws(file): Promise<any> {
    return this._catalogRepository.uploadAvatarAws(file);
  }
  skipAddMember(): Promise<any> {
    return this._catalogRepository.skipAddMember();
  }
  inviteUser(data): Promise<any> {
    return this._catalogRepository.inviteUser(data);
  }
  getInvitedUser(params): Promise<any> {
    return this._unauthRepository.getInvitedUser(params);
  }

  rejectedInvitation(data): Promise<any> {
    return this._catalogRepository.rejectedInvitation(data);
  }

  checkOrganizationAttachCode(data): Promise<any> {
    return this._catalogRepository.checkOrganizationAttachCode(data);
  }
  getPendingUsers(): Promise<any> {
    return this._catalogRepository.getPendingUsers();
  }
  getOrganizationAttachmentCode(): Promise<any> {
    return this._catalogRepository.getOrganizationAttachmentCode();
  }
  generateOrganizationAttachmentCode(): Promise<any> {
    return this._catalogRepository.generateOrganizationAttachmentCode();
  }
  resendInvitation(inviteId): Promise<any> {
    return this._catalogRepository.resendInvitation(inviteId);
  }
  cancelInvitation(inviteId): Promise<any> {
    return this._catalogRepository.cancelInvitation(inviteId);
  }
  getMyPendingInvitation(): Promise<any> {
    return this._catalogRepository.getMyPendingInvitation();
  }
  checkUserInstallApp(): Promise<any> {
    return this._catalogRepository.checkUserInstallApp();
  }
  getUserOrganizations(): Promise<any> {
    return this._catalogRepository.getUserOrganizations();
  }
  onFinishWithPersonalMode(): Promise<any> {
    return this._catalogRepository.onFinishWithPersonalMode();
  }
  updateAndFinishSetupOrganization(payload): Promise<any> {
    return this._catalogRepository.updateAndFinishSetupOrganization(payload);
  }
  getAllMyPendingInvitation(): Promise<any> {
    return this._catalogRepository.getAllMyPendingInvitation();
  }
  usingTrialPackage(planId: string) {
    return this._catalogRepository.usingTrialPackage(planId);
  }
  payImmediately(planId: string) {
    return this._catalogRepository.payImmediately(planId);
  }
  getPaymentBonus() {
    return this._catalogRepository.getPaymentBonus();
  }
}
