import { computed, ref } from 'vue';
import { getPriceByTotalUser } from '@/ui/helpers/payment/payment-helper';
// import { discountByTotalUserRanges } from '@/ui/helpers/payment/payment.mock';
import { PlanInfo } from '@/application/types/plan.types';
import {
  DiscountByPeriod,
  DiscountByUser,
} from '@/application/types/payment-configs.types';
import paymentStore from '@/store/payment';
import planStore from '@/store/plans';

export default function calculatePaymentDemo(planId) {
  const _paymentStore = paymentStore();
  const _planStore = planStore();

  const allPlanByIds = computed(() => _planStore.allPlanByIds);

  const paymentConfigs = computed(() => _paymentStore.paymentConfigs);
  const discountByPeriods = computed(() =>
    paymentConfigs.value?.discountByPeriods?.filter((o) => o.planId == planId)
  );

  const discountByUsers = computed<DiscountByUser[]>(
    () =>
      paymentConfigs.value?.discountByUsers?.filter(
        (period) => period?.planId == 'PRO'
      ) || []
  );
  const currentPlan = computed<PlanInfo>(() => {
    if (!planId) return {};
    return allPlanByIds.value[planId];
  });
  const basePrice = computed(() => {
    if (!planId) return 0;
    return currentPlan.value?.basePrice;
  });

  // new

  const totalUsers = ref(5);
  const calculatedPaymentInfo = computed<{
    monthPrice: any;
    unitPrice: number;
    discountPercent: number;
  }>(() => {
    return getPriceByTotalUser(
      totalUsers.value,
      basePrice.value,
      discountByUsers.value
    );
  });

  const getPriceByPeriod = ({ period, discount }: DiscountByPeriod) => {
    const lastTotalAmount =
      calculatedPaymentInfo.value?.monthPrice * period * (1 - discount);

    const lastTotalAmountNotDiscount =
      basePrice.value * totalUsers.value * period;

    let discountPercent =
      ((lastTotalAmountNotDiscount - lastTotalAmount) /
        lastTotalAmountNotDiscount) *
      100;
    if (discountPercent > 0 && discountPercent < 1)
      discountPercent =
        Math.floor((discountPercent + Number.EPSILON) * 10) / 10;
    else discountPercent = Math.floor(discountPercent);

    return {
      lastTotalAmount,
      priceActualForOneUserInOneMonth:
        totalUsers.value > 0 ? lastTotalAmount / totalUsers.value / period : 0,
      lastTotalAmountNotDiscount,
      discountPercent,
    };
  };

  return {
    discountByPeriods,
    totalUsers,
    getPriceByPeriod,
  };
}
