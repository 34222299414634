import { computed, ref } from 'vue';
import { getPriceByTotalUser } from '@/ui/helpers/payment/payment-helper';
import { DiscountByUser } from '@/application/types/payment-configs.types';
import { PlanInfo } from '@/application/types/plan.types';
import paymentStore from '@/store/payment';
import planStore from '@/store/plans';
import payingStore from '@/store/payment/paying-store';

export default function paymentCalculatePriceComposables(planId) {
  const _paymentStore = paymentStore();
  const _planStore = planStore();
  const _payingStore = payingStore();

  const paymentConfigs = computed(() => _paymentStore.paymentConfigs);
  const paymentRemainTime = computed(() => _payingStore.paymentRemainTime);
  const allPlanByIds = computed(() => _planStore.allPlanByIds);
  const currentPlan = computed<PlanInfo>(() => {
    if (!planId) return {};
    return allPlanByIds.value[planId];
  });
  const basePrice = computed(() => {
    if (!planId) return 0;
    return currentPlan.value?.basePrice;
  });

  const discountByUsers = computed<DiscountByUser[]>(
    () =>
      paymentConfigs.value?.discountByUsers?.filter(
        (period) => period?.planId == planId
      ) || []
  );

  // new

  const totalUsers = ref(5);
  const calculatedPaymentInfo = computed<{
    monthPrice: any;
    unitPrice: number;
    discountPercent: number;
  }>(() => {
    return getPriceByTotalUser(
      totalUsers.value,
      basePrice.value,
      discountByUsers.value
    );
  });

  const priceActualForOneUserInOneMonth = computed<number>(() => {
    return totalUsers.value > 0
      ? (calculatedPaymentInfo.value.monthPrice / totalUsers.value) *
          (1 - paymentRemainTime.value?.discountPeriodPercent)
      : 0;
  });

  // const lastTotalAmount = computed(() => {
  //   return (
  //     ((calculatedPaymentInfo.value.monthPrice *
  //       (1 - paymentRemainTime.value?.discountPeriodPercent)) /
  //       30) *
  //     paymentRemainTime.value?.remainDay
  //   );
  // });
  const lastTotalAmount = computed(() => {
    return (
      (calculatedPaymentInfo.value.monthPrice / 30) *
      paymentRemainTime.value?.remainDay *
      (1 - paymentRemainTime.value?.discountPeriodPercent)
    );
  });

  const lastTotalAmountNotDiscount = computed(() => {
    return (
      ((basePrice.value * totalUsers.value) / 30) *
      paymentRemainTime.value?.remainDay
    );
  });

  const discountPercent = computed(() => {
    const notDiscountPrice = lastTotalAmountNotDiscount.value;

    const percent =
      ((notDiscountPrice - lastTotalAmount.value) / notDiscountPrice) * 100;
    if (percent == 0) return 0;
    return percent < 1
      ? Math.floor((percent + Number.EPSILON) * 10) / 10
      : Math.floor(percent);
  });

  return {
    totalUsers,
    lastTotalAmount,
    lastTotalAmountNotDiscount,
    discountPercent,
    priceActualForOneUserInOneMonth,
  };
}
