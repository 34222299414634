import { translate } from '@/ui/plugins/i18n/myi18n';
export default {
  organizationModels: [
    {
      id: 1,
      code: 'COMPANY',
      index: 1,
      active: true,
      name: translate('COMMON_LABEL_COMPANY'),
      description: null,
    },
    {
      id: 2,
      code: 'TEAM',
      index: 2,
      active: true,
      name: translate('COMMON_LABEL_TEAM'),
      description: null,
    },
    {
      id: 4,
      code: 'PERSONAL',
      index: 4,
      active: true,
      name: translate('COMMON_LABEL_PERSONAL'),
      description: null,
    },
  ],
  organizationTypes: [
    {
      id: 1,
      code: 'SOFTWARE_DEVELOPER',
      index: 1,
      active: true,
      name: 'Phát triển phần mềm',
      description: null,
    },
    {
      id: 2,
      code: 'MARKETING',
      index: 2,
      active: true,
      name: 'Tiếp thị',
      description: null,
    },
    {
      id: 3,
      code: 'SALE_AND_CRM',
      index: 3,
      active: true,
      name: 'Bán hàng và CRM',
      description: null,
    },
    {
      id: 4,
      code: 'CREATIVE_DESIGN',
      index: 4,
      active: true,
      name: 'Sáng tạo và thiết kế',
      description: null,
    },
    {
      id: 5,
      code: 'SCHOOL',
      index: 5,
      active: true,
      name: 'Trường học',
      description: null,
    },
  ],
  organizationConfigs: {
    SOFTWARE_DEVELOPER: {
      POSITION: [
        {
          id: 1,
          organizationTypeId: 1,
          group: 'POSITION',
          code: 'MANAGER',
          index: 1,
          active: true,
          name: 'Quản lý',
          description: null,
        },
        {
          id: 2,
          organizationTypeId: 1,
          group: 'POSITION',
          code: 'TESTOR',
          index: 2,
          active: true,
          name: 'Kiểm thử',
          description: null,
        },
      ],
      TASK_TYPE: [
        {
          id: 3,
          organizationTypeId: 1,
          group: 'TASKTYPE',
          code: 'CONTENT_WRITING',
          index: 1,
          active: true,
          name: 'Viết nội dung',
          description: null,
        },
        {
          id: 4,
          organizationTypeId: 1,
          group: 'TASKTYPE',
          code: 'CODING',
          index: 2,
          active: true,
          name: 'Lập trình',
          description: null,
        },
      ],
      DOMAIN: [],
    },
    MARKETING: {
      POSITION: [
        {
          id: 5,
          organizationTypeId: 2,
          group: 'POSITION',
          code: 'DIRECTOR',
          index: 1,
          active: true,
          name: 'Giám đốc',
          description: null,
        },
      ],
      TASK_TYPE: [
        {
          id: 6,
          organizationTypeId: 2,
          group: 'TASKTYPE',
          code: 'CUSTOMER_SERVICE',
          index: 1,
          active: true,
          name: 'Hỗ trợ khách hàng',
          description: null,
        },
      ],
      DOMAIN: [
        {
          id: 1,
          organizationTypeId: 2,
          group: 'TASKTYPE',
          code: 'CUSTOMER_SERVICE',
          index: 1,
          active: true,
          name: 'Tictop feadback',
          description: null,
        },
      ],
    },
  },
};
