import { computed, ref, watch } from 'vue';
import planStore from '@/store/plans';
import { CRangePrice } from '@/application/constants/PaymentConstants';

export default function calculatePricePlansComposables(
  packageCurrent,
  totalUser
) {
  const _planStore = planStore();
  const pricePackages = computed(() => _planStore.allPlans);
  const currentPlans = computed(() => {
    const index = pricePackages.value.findIndex(
      (el) => el?.id === packageCurrent
    );
    if (index == -1) return {};
    return pricePackages.value[index];
  });

  const priceInMonth = computed(
    () => currentPlans.value?.planPricing?.monthlyBillPerMonth || 15
  );
  const priceInYear = computed(
    () => currentPlans.value?.planPricing?.yearlyBillPerMonth || 12.5
  );

  const rangePrice = ref(CRangePrice);

  watch(
    () => totalUser,
    () => {
      calculatePercentRangePrice();
    }
  );

  const priceMonthNotDisCount = computed(
    () => totalUser.value * priceInMonth.value
  );
  const priceYearNotDisCount = computed(
    () => totalUser.value * priceInYear.value
  );

  const paymentResult = computed(() => {
    const sum = {
      inMonth: 0,
      inYear: 0,
    };

    if (totalUser.value < 0) return;

    if (totalUser.value < 51) {
      sum.inMonth = priceInMonth.value * totalUser.value;
      sum.inYear = priceInYear.value * totalUser.value;
    }

    if (totalUser.value > 50 && totalUser.value < 101) {
      sum.inMonth =
        priceInMonth.value * 50 +
        (totalUser.value - 50) * priceInMonth.value * 0.25;
      sum.inYear =
        priceInYear.value * 50 +
        (totalUser.value - 50) * priceInYear.value * 0.25;
    }

    if (totalUser.value > 100 && totalUser.value < 201) {
      sum.inMonth =
        priceInMonth.value * 50 +
        50 * priceInMonth.value * 0.25 +
        (totalUser.value - 100) * priceInMonth.value * 0.3;
      sum.inYear =
        priceInYear.value * 50 +
        50 * priceInYear.value * 0.25 +
        (totalUser.value - 100) * priceInYear.value * 0.3;
    }

    if (totalUser.value > 200) {
      sum.inMonth =
        priceInMonth.value * 50 +
        50 * priceInMonth.value * 0.25 +
        100 * priceInMonth.value * 0.3 +
        (totalUser.value - 200) * priceInMonth.value * 0.4;
      sum.inYear =
        priceInYear.value * 50 +
        50 * priceInYear.value * 0.25 +
        100 * priceInMonth.value * 0.3 +
        (totalUser.value - 200) * priceInYear.value * 0.4;
    }

    return sum;
  });

  const calculatePercentRangePrice = () => {
    if (totalUser.value <= 0) {
      rangePrice.value.forEach((el) => (el.percent = 0));
    }
    if (totalUser.value < 51) {
      rangePrice.value[0].percent = (totalUser.value / 50) * 100;
      rangePrice.value[1].percent = 0;
      rangePrice.value[2].percent = 0;
      rangePrice.value[3].percent = 0;
    }
    if (totalUser.value > 50 && totalUser.value < 101) {
      rangePrice.value[0].percent = 100;
      rangePrice.value[1].percent = ((totalUser.value - 50) / 50) * 100;
      rangePrice.value[2].percent = 0;
      rangePrice.value[3].percent = 0;
    }
    if (totalUser.value > 100 && totalUser.value < 201) {
      rangePrice.value[0].percent = 100;
      rangePrice.value[1].percent = 100;
      rangePrice.value[2].percent = ((totalUser.value - 100) / 100) * 100;
      rangePrice.value[3].percent = 0;
    }
    if (totalUser.value > 200) {
      rangePrice.value[0].percent = 100;
      rangePrice.value[1].percent = 100;
      rangePrice.value[2].percent = 100;
      rangePrice.value[3].percent = ((totalUser.value - 200) / 100) * 100;
    }
  };

  return {
    priceInMonth,
    priceInYear,
    rangePrice,
    totalUser,
    paymentResult,
    currentPlans,
    priceMonthNotDisCount,
    priceYearNotDisCount,
    calculatePercentRangePrice,
  };
}
