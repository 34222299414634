import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from '@/ui/pages/home/HomePage.vue';
import Home from '@/ui/pages/home/Home.vue';
import NotFound from '@/ui/pages/error/not-found.vue';
import Rules from '@/ui/pages/policy/Rules';
import PaymentPolicy from '@/ui/pages/policy/PaymentPolicy';
import PaymentDelivery from '@/ui/pages/policy/PaymentDelivery';
import ProductPolicy from '@/ui/pages/policy/ProductPolicy';
import PrivacyPolicy from '@/ui/pages/policy/PrivacyPolicy';
import ReturnPolicy from '@/ui/pages/policy/ReturnPolicy';
import PaymentPage from '@/ui/pages/payment/PaymentPage';
import PaymentDetailPage from '@/ui/pages/payment/PaymentDetailPage.vue';
import PaymentVoucherPage from '@/ui/pages/payment/PaymentVoucherPage.vue';
import PaymentPlan from '@/ui/modules/payment/PaymentPlan.vue';
import PaymentManagementPage from '@/ui/pages/payment/PaymentManagementPage.vue';
import PaymentResultPage from '@/ui/pages/payment/PaymentResultPage.vue';
import PricingOverview from '@/ui/pages/pricing/PricingOverview.vue';
import PaymentBillingHistoriesPage from '@/ui/pages/payment/PaymentBillingHistoriesPage.vue';
import Login from '../pages/auth/Login.vue';
import BuyLicensePage from '@/ui/pages/payment/BuyLicensePage.vue';

export const publicRoutes = [
  {
    path: '/buy-license/:token',
    name: 'BuyLicensePage',
    component: BuyLicensePage,
    meta: { layout: 'BuyLicense' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'Login' },
  },
  {
    path: '/pricing-1',
    name: 'Home',
    component: Home,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/pricing',
    name: 'PricingOverview',
    component: PricingOverview,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/',
    name: 'HomePage',
    component: PricingOverview,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/dieu-khoan',
    name: 'Rules',
    component: Rules,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/chinh-sach-thanh-toan',
    name: 'PaymentPolicy',
    component: PaymentPolicy,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/chinh-sach-giao-nhan',
    name: 'PaymentDelivery',
    component: PaymentDelivery,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/chinh-sach-bao-hanh',
    name: 'ProductPolicy',
    component: ProductPolicy,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/chinh-sach-bao-mat',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/chinh-sach-doi-tra',
    name: 'ReturnPolicy',
    component: ReturnPolicy,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/payment/result',
    name: 'PaymentResultPage',
    component: PaymentResultPage,
    meta: { layout: 'Dashboard' },
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: PaymentPage,
    meta: { layout: 'payment' },
    redirect: { name: 'PaymentDetailPage' },
    children: [
      {
        path: 'voucher',
        name: 'PaymentVoucherPage',
        component: PaymentVoucherPage,
        meta: { layout: 'voucher' },
      },
      {
        path: 'cart',
        name: 'PaymentDetailPage',
        component: PaymentDetailPage,
        meta: { layout: 'payment' },
      },
      {
        path: 'plans',
        name: 'PaymentPlan',
        component: PaymentPlan,
        meta: { layout: 'payment' },
      },
      {
        path: 'management',
        name: 'PaymentManagementPage',
        component: PaymentManagementPage,
        meta: { layout: 'payment' },
      },
      {
        path: 'billing-histories',
        name: 'PaymentBillingHistoriesPage',
        component: PaymentBillingHistoriesPage,
        meta: { layout: 'payment' },
      },
    ],
  },
];
export const notFoundRoutes = [
  {
    // path: '/:catchAll(.*)',
    path: '/:pathMatch(.*)*',
    name: 'Notfound',
    component: NotFound,
    meta: { layout: 'Empty' },
  },
];
export const privateRoutes = [];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes: publicRoutes,
  scrollBehavior() {
    // return desired position
    return { top: 0 };
  },
});
export default router;
