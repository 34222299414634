import axios, { AxiosResponse } from 'axios';
import { ResponseStatusCode } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import router from '@/ui/router';
import Qs from 'qs';

const debug = process.env.NODE_ENV !== 'production';

const customeErrorCodes = [
  ResponseStatusCode.LoginFailed,
  ResponseStatusCode.LoginBlocked,
  ResponseStatusCode.SendPhoneCodeBlocked,
  ResponseStatusCode.CaptchaCheckFailed,
  ResponseStatusCode.InvalidSessionInfo,
  ResponseStatusCode.SendVerificationFailed,
  ResponseStatusCode.CodeInvalid,
  ResponseStatusCode.InviteTokenInvalid,
  ResponseStatusCode.InviteTokenExpired,
  ResponseStatusCode.InviteTokenCanceled,
  ResponseStatusCode.InviteUserAttached,
  ResponseStatusCode.InviteJoinedByOtherMethods,
];
// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_TICTOP_SERVER}/api/payment/`,
  // // withCredentials: true, // send cookies when cross-domain requests
  timeout: debug ? 200000 : 20000, // request timeout
  paramsSerializer: (params) =>
    Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

// request interceptor
service.interceptors.request.use(
  async (config) => {
    const requestTime = Date.now();
    // do something before request is sent
    config.headers = {
      ...config.headers,
      'x-request-time': requestTime,
    };

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  (response: AxiosResponse<any>) => {
    const { success, error } = response.data;
    if (success) {
      const action = translate(response.config.headers?.Action);
      if (action) {
        // openNotification(
        //   AlertType.success,
        //   translate('COMMON_LABEL_SUCCESS'),
        //   action,
        //   5000,
        //   NotificationPositionType.TopCenterRight
        // );
      }
      return response.data;
    }

    const errorCode = error?.code;
    switch (errorCode) {
      case 403:
        {
          router.push({
            name: 'Notfound',
          });
        }
        break;
      case ResponseStatusCode.OrganizationInactive:
        {
          router.push({
            name: 'OrganizationInactive',
          });
        }
        break;
      case ResponseStatusCode.WaitingActivationOrganization:
        {
          router.push({
            name: 'WaitingActivation',
          });
        }
        break;
      case ResponseStatusCode.SuspiciousRequest:
        {
          router.push({
            name: 'SuspiciousRequest',
            query: {
              t: error.timeBlockRemaining,
            },
          });
        }
        break;
      case ResponseStatusCode.LoginFailed:
        {
          if (error?.message === 'USER_DEACTIVATED')
            router.push({
              name: 'AccountDeactivated',
              query: {
                id: error.details,
              },
            });
        }
        break;

      default: {
        if (!customeErrorCodes.includes(errorCode ?? 0) && debug) {
          // openNotification(
          //   AlertType.error,
          //   translate('COMMON_LABEL_ERROR'),
          //   error?.message,
          //   5000,
          //   NotificationPositionType.TopCenterRight
          // );
        }
        // new ErrorResponseClass(errorCode)
        return Promise.reject(response.data);
      }
    }
    return Promise.reject(response.data);
  },
  async (error) => {
    if (error && error.response) {
      const { status } = error.response;
      if (status === 400) {
        // router.push('/notfound');
        router.push({ name: 'Notfound' });
        // router.push("/login");
        return Promise.reject(new Error('Error'));
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;
