import dayjs, { QUnitType } from 'dayjs';

export const getToDayUTC = () => {
  return Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate()
  );
};
export const diff = (d1, d2, unit: QUnitType) => {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);

  return date1.diff(date2, unit);
};
