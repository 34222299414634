export const getBrowserInfo = () => {
  const userAgent = window.navigator.userAgent;

  const test = function (regexp: any) {
    return regexp.test(window.navigator.userAgent);
  };

  switch (true) {
    case test(/edg/i):
      return {
        deviceName: 'Microsoft Edge',
        deviceInfo: userAgent,
      };
    case test(/trident/i):
      return {
        deviceName: 'Microsoft Internet Explorer',
        deviceInfo: userAgent,
      };
    case test(/firefox|fxios/i):
      return {
        deviceName: 'Mozilla Firefox',
        deviceInfo: userAgent,
      };
    case test(/opr\//i):
      return {
        deviceName: 'Opera',
        deviceInfo: userAgent,
      };
    case test(/ucbrowser/i):
      return {
        deviceName: 'UC Browser',
        deviceInfo: userAgent,
      };
    case test(/samsungbrowser/i):
      return {
        deviceName: 'Samsung Browser',
        deviceInfo: userAgent,
      };
    case test(/chrome|chromium|crios/i):
      return {
        deviceName: 'Google Chrome',
        deviceInfo: userAgent,
      };
    case test(/safari/i):
      return {
        deviceName: 'Apple Safari',
        deviceInfo: userAgent,
      };
    default:
      return {
        deviceName: 'Other',
        deviceInfo: userAgent,
      };
  }
};

export const ignoreUnicode = (str, toLower = true) => {
  if (!str) return str;

  str = str.trim();
  if (toLower) str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|$|_/g,
    '-'
  );

  str = str.replace(/-+-/g, '-');
  str = str.replace(/^-+|-+$/g, '');

  return str;
};
