<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g data-name="Layer 2">
        <path
          d="M16.52 8.55h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2zM16.52 13.9h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2zM16.52 19.37h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2z"
        ></path>
        <path
          d="M25.87 1H6.13a3.9 3.9 0 0 0-3.89 3.92v24.39a1.41 1.41 0 0 0 2.14 1.21l3.68-2.21a.35.35 0 0 1 .37 0l4.09 2.45a1.34 1.34 0 0 0 .72.21 1.46 1.46 0 0 0 .73-.2l4.11-2.46a.35.35 0 0 1 .37 0l3.66 2.21a1.39 1.39 0 0 0 1.42 0 1.41 1.41 0 0 0 .71-1.22V12.44h3.14a2.39 2.39 0 0 0 2.38-2.38V4.92A3.9 3.9 0 0 0 25.87 1zm-3.63 27.26-2.76-1.66a2.38 2.38 0 0 0-2.43 0l-3.8 2.28-3.79-2.29A2.38 2.38 0 0 0 7 26.6l-2.76 1.67V4.92A1.89 1.89 0 0 1 6.13 3h16.52a3.58 3.58 0 0 0-.41 1.63v23.6zm5.52-18.2a.38.38 0 0 1-.38.38h-3.14V4.66A1.63 1.63 0 0 1 25.87 3a1.89 1.89 0 0 1 1.89 1.89z"
        ></path>
      </g>
    </g>
  </svg>
</template>
