<script setup></script>

<template>
  <div class="flex justify-center w-full">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 3rem 6rem 3rem;
        height: 24rem;
      "
      class="w-3/4 flex-col"
    >
      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách bảo hành sản phẩm</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <span class="flex flex-col space-y-3 text-gray-600 leading-loose">
            Chúng tôi cam kết thường xuyên kiểm tra và bảo trì hệ thống để việc
            sử dụng ứng dụng của quý khách được thuận lợi, không bị gián đoạn.
            Nếu có phát sinh sự cố kỹ thuật, chúng tôi sẽ khắc phục trong thời
            gian sớm nhất.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
