import { IPaymentInformation } from '@/application/interfaces/PaymentInterfaces';
import PaymentRepository from '@/application/repositories/PaymentRepository';
import { IPaymentPayload } from '@/application/types/payment.types';

export default class PaymentService {
  private static instance: PaymentService;
  _paymentRepository: PaymentRepository;

  constructor() {
    this._paymentRepository = PaymentRepository.getInstance();
  }

  public static getInstance(): PaymentService {
    if (!PaymentService.instance) {
      // Get from local storage
      PaymentService.instance = new PaymentService();
    }

    return PaymentService.instance;
  }

  async getPaymentInformation(): Promise<IPaymentInformation> {
    const res = await this._paymentRepository.getPaymentInformation();
    return res.result;
  }

  getAllPlans() {
    return this._paymentRepository.getAllPlans();
  }

  getPaymentConfigs() {
    return this._paymentRepository.getPaymentConfigs();
  }

  getCurrentPlan() {
    return this._paymentRepository.getCurrentPlan();
  }

  getPaymentRemainMonths() {
    return this._paymentRepository.getPaymentRemainMonths();
  }

  calculatePaymentTotalAmount(payload) {
    return this._paymentRepository.calculatePaymentTotalAmount(payload);
  }

  makePayment(payload) {
    return this._paymentRepository.makePayment(payload);
  }

  syncZaloPayOrderStatus(orderTransId) {
    return this._paymentRepository.syncZaloPayOrderStatus(orderTransId);
  }

  createPaymentToken(data: IPaymentPayload): Promise<any> {
    return this._paymentRepository.createPaymentToken(data);
  }
  getPaymentHistories() {
    return this._paymentRepository.getPaymentHistories();
  }

  getTransactionDetail(id): Promise<any> {
    return this._paymentRepository.getTransactionDetail(id);
  }

  getAllPlanComparativeCriteria(): Promise<any> {
    return this._paymentRepository.getAllPlanComparativeCriteria();
  }
  getAvailableVouchers(): Promise<any> {
    return this._paymentRepository.getAvailableVouchers();
  }
}
