<template>
  <div
    :class="[
      'vig-input vue-tel-input',
      styleClasses,
      { disabled: disabled },
      error ? 'vig-input-error' : 'vig-input',
    ]"
    class="bg-white h-10 rounded-md border text-sm relative px-2"
  >
    <div
      v-click-outside="clickedOutside"
      class="rounded hover:bg-current-50 border-0 focus:outline-none"
      :class="['vti__dropdown', { open: open }]"
      :tabindex="open ? dropdownOptions.tabindex : null"
      @keydown="keyboardNav"
      @click="toggleDropdown"
      @keydown.esc="reset"
    >
      <span class="vti__selection space-x-2">
        <SynIcon
          v-if="dropdownOptions.showFlags"
          custom-class="w-5 h-5"
          :name="getFlagName(activeCountryCode)"
        />
        <span
          v-if="dropdownOptions.showDialCodeInSelection"
          class="vti__country-code"
        >
          +{{ activeCountry && activeCountry.dialCode }}
        </span>
        <slot name="arrow-icon" :open="open">
          <span class="vti__dropdown-arrow">{{ open ? '▲' : '▼' }}</span>
        </slot>
      </span>
    </div>
    <ul
      v-show="open"
      ref="list"
      class="vti__dropdown-list small-scrollbar w-full"
      :class="dropdownOpenDirection"
    >
      <li
        v-for="(pb, index) in sortedCountries"
        :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
        class="flex items-center space-x-2 py-2 my-1"
        :class="['vti__dropdown-item', getItemClass(index, pb.iso2)]"
        @click="choose(pb)"
        @mousemove="selectedIndex = index"
      >
        <div
          v-if="dropdownOptions.showFlags"
          :class="['vti__flag', pb.iso2.toLowerCase()]"
        />
        <span class="font-medium text-current-800">{{ pb.name }}</span>
        <span v-if="dropdownOptions.showDialCodeInList">
          (+{{ pb.dialCode }})
        </span>
      </li>
    </ul>
    <input
      :id="inputOptions.id"
      ref="input"
      v-model="phone"
      v-cusFocus="autofocus"
      class="w-full text-sm rounded-lg border-0 outline-none"
      style="box-shadow: none"
      :class="[
        inputOptions.styleClasses,
        disabled ? 'bg-gray-100' : '',
        inputClass,
      ]"
      :type="inputOptions.type"
      :autocomplete="inputOptions.autocomplete"
      :autofocus="inputOptions.autofocus"
      :disabled="disabled"
      :maxlength="inputOptions.maxlength"
      :name="inputOptions.name"
      :placeholder="$t('COMMON_LABEL_MOBILE_PHONE')"
      :readonly="inputOptions.readonly"
      :required="inputOptions.required"
      :tabindex="inputOptions.tabindex"
      @blur="onBlur"
      @focus="onFocus"
      @mouseleave="onMouseLeave"
      @input="onInput"
      @keyup.enter="onEnter"
      @keyup.space="onSpace"
      @keydown="onKeydown"
      @change="onChange"
    />
    <div
      class="absolute inset-y-0 right-0 flex items-center h-full focus:outline-none focus:shadow-outline"
    >
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import utils, { setCaretPosition } from './utils';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  StorageConstant,
  getLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';

const fallbackCountry = {
  fr: { dialCode: '33', iso2: 'FR', name: 'France' },
  vn: { dialCode: '84', iso2: 'VN', name: 'Vietnam' },
  us: { dialCode: '1', iso2: 'US', name: 'United States' },
  jp: { name: 'Japan (日本)', iso2: 'JP', dialCode: '81' },
  kr: { name: 'South Korea (대한민국)', iso2: 'KR', dialCode: '82' },
};
const fallbackCountryFromLanguage = {
  fr: 'fr',
  vi: 'vn',
  en: 'us',
};

function getDefault(key) {
  const value = utils.options[key];
  if (typeof value === 'undefined') {
    return utils.options[key];
  }
  return value;
}

// let examples = null;
// const getExamples = () => new Promise(
//   (resolve) => (
//     examples
//       ? resolve(examples)
//       : import('libphonenumber-js/examples.mobile.json')
//         .then((results) => {
//           examples = results;
//           resolve(results);
//         })
//   ),
// );
export default {
  name: 'VueTelInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    allCountries: {
      type: Array,
      default: () => getDefault('allCountries'),
    },
    autoFormat: {
      type: Boolean,
      default: () => getDefault('autoFormat'),
    },
    customValidate: {
      type: [Boolean, RegExp],
      default: () => getDefault('customValidate'),
    },
    defaultCountry: {
      // Default country code, ie: 'AU'
      // Will override the current country of user
      type: String,
      default: () => getDefault('defaultCountry'),
    },
    disabled: {
      type: Boolean,
      default: () => getDefault('disabled'),
    },
    autoDefaultCountry: {
      type: Boolean,
      default: () => getDefault('autoDefaultCountry'),
    },
    dropdownOptions: {
      type: Object,
      default: () => getDefault('dropdownOptions'),
    },
    ignoredCountries: {
      type: Array,
      default: () => getDefault('ignoredCountries'),
    },
    inputOptions: {
      type: Object,
      default: () => getDefault('inputOptions'),
    },
    invalidMsg: {
      type: String,
      default: () => getDefault('invalidMsg'),
    },
    mode: {
      type: String,
      default: () => getDefault('mode'),
    },
    onlyCountries: {
      type: Array,
      default: () => getDefault('onlyCountries'),
    },
    preferredCountries: {
      type: Array,
      default: () => getDefault('preferredCountries'),
    },
    validCharactersOnly: {
      type: Boolean,
      default: () => getDefault('validCharactersOnly'),
    },
    styleClasses: {
      type: [String, Array, Object],
      default: () => getDefault('styleClasses'),
    },
    error: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  emits: [
    'input',
    'keydown',
    'country-changed',
    'validate',
    'open',
    'close',
    'validate',
    'blur',
    'focus',
    'enter',
    'space',
    'mouseleave',
    'change',
  ],
  data() {
    return {
      phone: '',
      activeCountryCode: '',
      open: false,
      finishMounted: false,
      selectedIndex: null,
      typeToFindInput: '',
      typeToFindTimer: null,
      dropdownOpenDirection: 'below',
      parsedPlaceholder: this.inputOptions.placeholder,
      phoneObject: {},
      timer: null,
    };
  },
  computed: {
    activeCountry() {
      return this.findCountry(this.activeCountryCode);
    },
    parsedMode() {
      if (this.mode === 'auto') {
        if (!this.phone || this.phone[0] !== '+') {
          return 'national';
        }
        return 'international';
      }
      if (!['international', 'national'].includes(this.mode)) {
        console.error('Invalid value of prop "mode"');
        return 'international';
      }
      return this.mode;
    },
    filteredCountries() {
      // List countries after filtered
      const location = getLocalStorage(StorageConstant.LOCATION);
      let allCountries = this.allCountries;
      if (location == 'EU')
        allCountries = this.allCountries.filter((o) => o.iso2 !== 'VN');
      if (this.onlyCountries.length) {
        return allCountries.filter(({ iso2 }) =>
          this.onlyCountries.some((c) => c.toUpperCase() === iso2)
        );
      }
      if (this.ignoredCountries.length) {
        return allCountries.filter(
          ({ iso2 }) =>
            !this.ignoredCountries.includes(iso2.toUpperCase()) &&
            !this.ignoredCountries.includes(iso2.toLowerCase())
        );
      }
      return allCountries;
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries).map(
        (country) => ({
          ...country,
          preferred: true,
        })
      );
      return [...preferredCountries, ...this.filteredCountries];
    },
    // phoneObject() {
    //   let result;
    //   if (this.phone?.[0] === '+') {
    //     result = parsePhoneNumberFromString(this.phone) || {};
    //   } else {
    //     result =
    //       parsePhoneNumberFromString(this.phone, this.activeCountryCode) || {};
    //   }
    //   console.log(
    //     '🚀 ~ file: SynTelInput.vue ~ line 245 ~ phoneObject ~ result',
    //     result
    //   );
    //   const { ...phoneObject } = result;
    //   let valid = result.isValid?.();
    //   let formatted = this.phone;
    //   if (valid) {
    //     formatted = result.format?.(this.parsedMode.toUpperCase());
    //   }
    //   if (
    //     result.country &&
    //     (this.ignoredCountries.length || this.onlyCountries.length)
    //   ) {
    //     if (!this.findCountry(result.country)) {
    //       valid = false;
    //       Object.assign(result, { country: null });
    //     }
    //   }
    //   Object.assign(phoneObject, {
    //     countryCode: result.country,
    //     valid,
    //     country: this.activeCountry,
    //     formatted,
    //   });
    //   return phoneObject;
    // },
  },
  watch: {
    activeCountry(value, oldValue) {
      if (!value && oldValue?.iso2) {
        this.activeCountryCode = oldValue.iso2;
        return;
      }
      if (value?.iso2) {
        this.$emit('country-changed', value);
        // this.resetPlaceholder();
      }
    },
    'phoneObject.countryCode': function (value) {
      this.activeCountryCode = value;
    },
    'phoneObject.valid': function () {
      this.$emit('validate', this.phoneObject);
    },
    'phoneObject.formatted': function (value) {
      if (!this.autoFormat || this.customValidate) {
        return;
      }
      this.emitInput(value);
      this.$nextTick(() => {
        // In case `v-model` is not set, we need to update the `phone` to be new formatted value
        if (value && !this.value) {
          this.phone = value;
        }
      });
    },
    finishMounted() {
      this.resetPlaceholder();
    },
    'inputOptions.placeholder': function () {
      this.resetPlaceholder();
    },
    // value(value, oldValue) {
    //   console.log(
    //     '🚀 ~ file: SynTelInput.vue ~ line 304 ~ value ~ value, oldValue',
    //     value,
    //     this.testCharacters()
    //   );
    //   if (!this.testCharacters()) {
    //     this.$nextTick(() => {
    //       this.phone = oldValue;
    //       this.onInput();
    //     });
    //   } else {
    //     this.phone = value;
    //   }
    // },
    open(isDropdownOpened) {
      // Emit open and close events
      if (isDropdownOpened) {
        this.setDropdownPosition();
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
    value(val) {
      this.phone = val;
    },
  },
  mounted() {
    this.listenLocalStorage();

    if (this.value) {
      this.phone = this.value.trim();
    }
    this.cleanInvalidCharacters();
    this.initializeCountry()
      .then(() => {
        if (
          !this.phone &&
          this.inputOptions?.showDialCode &&
          this.activeCountryCode
        ) {
          this.phone = `+${this.activeCountryCode}`;
        }
        this.$emit('validate', this.phoneObject);
      })
      .catch(console.error)
      .then(() => {
        this.finishMounted = true;
      });
  },
  methods: {
    listenLocalStorage() {
      window.addEventListener('storage', (event) => {
        if (event.key !== StorageConstant.PHONE_COUNTRY_CODE) return;
        const countryCode = event.newValue;
        this.choose(fallbackCountry[countryCode ? countryCode : 'us']);
      });
    },
    resetPlaceholder() {
      this.parsedPlaceholder = translate('COMMON_LABEL_MOBILE_PHONE');
      // TODO: Fix dynamicPlaceholder
      // if (!this.inputOptions.dynamicPlaceholder) {
      //   return result;
      // }
      // getExamples()
      //   .then((results) => {
      //     examples = results;
      //     const mode = (!this.mode || this.mode === 'auto') ? 'international' : this.mode;
      //     const number = getExampleNumber(this.activeCountryCode.toUpperCase(), results);
      //     this.parsedPlaceholder = number?.format(mode.toUpperCase()) || this.placeholder;
      //   })
      //   .catch(console.error);
    },
    initializeCountry() {
      return new Promise((resolve) => {
        /**
         * 1. If the phone included prefix (i.e. +12), try to get the country and set it
         */
        if (this.phone?.[0] === '+') {
          resolve();
          return;
        }
        /**
         * 2. Use default country if passed from parent
         */
        if (this.defaultCountry) {
          this.choose(this.defaultCountry);
          resolve();
          return;
        }

        const language = getLocalStorage(StorageConstant.LANGUAGE);
        const phoneCountryCode = getLocalStorage(
          StorageConstant.PHONE_COUNTRY_CODE
        );
        const fallbackCountryCode = (
          phoneCountryCode
            ? phoneCountryCode
            : fallbackCountryFromLanguage[language]
        ).toLowerCase();
        /**
         * 3. Check if fetching country based on user's IP is allowed, set it as the default country
         */
        this.choose(fallbackCountry[fallbackCountryCode]);
        resolve();
      });
    },
    /**
     * Get the list of countries from the list of iso2 code
     */
    getCountries(list = []) {
      return list
        .map((countryCode) => this.findCountry(countryCode))
        .filter(Boolean);
    },
    findCountry(iso = '') {
      return this.filteredCountries.find(
        (country) => country.iso2 === iso.toUpperCase()
      );
    },
    getItemClass(index, iso2) {
      const highlighted = this.selectedIndex === index;
      const lastPreferred = index === this.preferredCountries.length - 1;
      const preferred = this.preferredCountries.some(
        (c) => c.toUpperCase() === iso2
      );
      return {
        highlighted,
        'last-preferred': lastPreferred,
        preferred,
      };
    },
    choose(country) {
      let parsedCountry = country;

      if (typeof parsedCountry === 'string') {
        parsedCountry = this.findCountry(parsedCountry);
      }
      if (!parsedCountry) {
        return;
      }

      setLocalStorage(StorageConstant.PHONE_COUNTRY_CODE, parsedCountry.iso2);

      if (
        this.phone?.[0] === '+' &&
        parsedCountry.iso2 &&
        this.phoneObject.nationalNumber
      ) {
        this.activeCountryCode = parsedCountry.iso2;
        // Attach the current phone number with the newly selected country
        this.phone = parsePhoneNumberFromString(
          this.phoneObject.nationalNumber?.toString(),
          parsedCountry.iso2
        ).formatInternational();
        this.changePhoneObject();
        // return;
      }
      if (this.inputOptions?.showDialCode && parsedCountry) {
        // Reset phone if the showDialCode is set
        this.phone = `+${parsedCountry.dialCode}`;
        this.changePhoneObject();
        // return;
      }
      // update value, even if international mode is NOT used
      this.activeCountryCode = parsedCountry.iso2;
      this.emitInput(this.phone);
    },
    cleanInvalidCharacters() {
      const currentPhone = this.phone;
      if (this.validCharactersOnly) {
        const results = this.phone.match(/[()\-+0-9\s]*/g);
        this.phone = results.join('');
      }
      if (this.customValidate && this.customValidate instanceof RegExp) {
        const results = this.phone.match(this.customValidate);
        this.phone = results.join('');
      }
      if (currentPhone !== this.phone) {
        this.emitInput(this.phone);
      }
    },
    testCharacters() {
      if (this.validCharactersOnly) {
        const result = /^[()\-+0-9\s]*$/.test(this.phone);
        if (!result) {
          return false;
        }
      }
      if (this.customValidate) {
        return this.testCustomValidate();
      }
      return true;
    },
    testCustomValidate() {
      return this.customValidate instanceof RegExp
        ? this.customValidate.test(this.phone)
        : false;
    },
    onInput() {
      this.changePhoneObject();
      this.$refs.input.setCustomValidity(
        this.phoneObject.valid ? '' : this.invalidMsg
      );
      // Returns response.number to assign it to v-model (if being used)
      // Returns full response for cases @input is used
      // and parent wants to return the whole response.
      this.emitInput(this.phone);
    },
    emitInput(value) {
      this.$emit(
        'input',
        value ? this.phoneObject : null,
        value,
        this.$refs.input
      );
    },
    onBlur() {
      this.changePhoneObject();
      this.$emit('blur', this.phone);
    },
    onKeydown() {
      this.$emit('keydown', this.phone);
    },
    onChange() {
      this.changePhoneObject();
      this.$emit('change', this.phone);
    },
    onMouseLeave() {
      this.changePhoneObject();
      this.$emit('mouseleave', this.phone);
    },
    onFocus() {
      setCaretPosition(this.$refs.input, this.phone?.length);
      this.$emit('focus');
    },
    onEnter() {
      this.changePhoneObject();
      this.$emit('enter', this.phone);
    },
    onSpace() {
      this.$emit('space');
    },
    focus() {
      this.$refs.input.focus();
    },
    toggleDropdown() {
      if (this.disabled) {
        return;
      }
      this.open = !this.open;
    },
    clickedOutside() {
      this.open = false;
    },
    keyboardNav(e) {
      if (e.keyCode === 40) {
        // down arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(
            this.sortedCountries.length - 1,
            this.selectedIndex + 1
          );
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (
          selEle.offsetTop + selEle.clientHeight >
          this.$refs.list.scrollTop + this.$refs.list.clientHeight
        ) {
          this.$refs.list.scrollTop =
            selEle.offsetTop -
            this.$refs.list.clientHeight +
            selEle.clientHeight;
        }
      } else if (e.keyCode === 38) {
        // up arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.sortedCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      } else if (e.keyCode === 13) {
        // enter key
        if (this.selectedIndex !== null) {
          this.choose(this.sortedCountries[this.selectedIndex]);
        }
        this.open = !this.open;
      } else {
        // typing a country's name
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = '';
        }, 700);
        // don't include preferred countries so we jump to the right place in the alphabet
        const typedCountryI = this.sortedCountries
          .slice(this.preferredCountries.length)
          .findIndex((c) =>
            c.name.toLowerCase().startsWith(this.typeToFindInput)
          );
        if (typedCountryI >= 0) {
          this.selectedIndex = this.preferredCountries.length + typedCountryI;
          const selEle = this.$refs.list.children[this.selectedIndex];
          const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop;
          const needToScrollBottom =
            selEle.offsetTop + selEle.clientHeight >
            this.$refs.list.scrollTop + this.$refs.list.clientHeight;
          if (needToScrollTop || needToScrollBottom) {
            this.$refs.list.scrollTop =
              selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      }
    },
    reset() {
      this.selectedIndex = this.sortedCountries
        .map((c) => c.iso2)
        .indexOf(this.activeCountryCode);
      this.open = false;
    },
    setDropdownPosition() {
      const spaceBelow =
        window.innerHeight - this.$el.getBoundingClientRect().bottom;
      const hasEnoughSpaceBelow = spaceBelow > 200;
      if (hasEnoughSpaceBelow) {
        this.dropdownOpenDirection = 'below';
      } else {
        this.dropdownOpenDirection = 'above';
      }
    },
    getFlagName(countryCode) {
      if (!countryCode) return '';
      const country = {
        VN: {
          flagName: 'flag-vietnam',
        },
        FR: {
          flagName: 'flag-france',
        },
        US: {
          flagName: 'flag-united-state',
        },
        JP: {
          flagName: 'flag-japan',
        },
        KR: {
          flagName: 'flag-korea',
        },
      };
      return country[countryCode]?.flagName || 'flag-vietnam';
    },
    changePhoneObject() {
      let result;
      if (!this.phone) return;
      if (this.phone?.[0] === '+') {
        result = parsePhoneNumberFromString(this.phone?.toString()) || {};
      } else {
        result =
          parsePhoneNumberFromString(
            this.phone?.toString(),
            this.activeCountryCode
          ) || {};
      }
      const { ...phoneObj } = result;
      let valid = result.isValid?.();
      let formatted = this.phone;
      if (valid) {
        formatted = result.format?.(this.parsedMode.toUpperCase());
      }
      if (
        result.country &&
        (this.ignoredCountries.length || this.onlyCountries.length)
      ) {
        if (!this.findCountry(result.country)) {
          valid = false;
          Object.assign(result, { country: null });
        }
      }
      Object.assign(phoneObj, {
        countryCode: result.country,
        valid,
        country: this.activeCountry,
        formatted,
      });
      this.phone = phoneObj?.number || formatted;
      this.phoneObject = phoneObj;
    },
  },
};
</script>
<style src="./sprite.css"></style>
