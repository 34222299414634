import { defineStore } from 'pinia';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import storeId from '@/base/store/storeId';
// import settingStore from '@/store/setting';
import myProfileStore from '@/store/auth/my-profile';
// import RouterInstanceClass from '@/ui/router/RouterInstanceClass';

export default defineStore({
  id: storeId.PAGE_LOGIN,
  state: () => ({
    _unAuthService: UnAuthenticateService.getInstance(),
    email: '',
    phone: '',
    resetToken: '',
  }),
  getters: {},
  actions: {
    async verifyToken() {
      const res = await this._unAuthService.verifyToken();
      return res?.result;
    },
    async checkEmail(email: string): Promise<any> {
      const res = await this._unAuthService.checkEmail(email);
      return res?.result;
    },
    async checkPhone(phone: string): Promise<any> {
      const res = await this._unAuthService.checkPhone(phone);
      return res?.result;
    },
    async checkUserHasPasswordByEmail(data: string): Promise<any> {
      const res = await this._unAuthService.checkUserHasPasswordByEmail(data);
      return res?.result;
    },
    async checkUserHasPasswordByPhone(data: string): Promise<any> {
      const res = await this._unAuthService.checkUserHasPasswordByPhone(data);
      return res?.result;
    },
    async sendVerificationCodeByPhoneNumber(data: any): Promise<any> {
      const res = await this._unAuthService.sendVerificationCodeByPhoneNumber(
        data
      );
      return res?.result;
    },
    async reSendVerificationCodeByPhoneNumber(data: any): Promise<any> {
      const res = await this._unAuthService.reSendVerificationCodeByPhoneNumber(
        data
      );
      return res?.result;
    },

    async sendVerificationCodeByEmail(data: any): Promise<any> {
      const res = await this._unAuthService.sendVerificationCodeByEmail(data);
      return res;
    },
    async reSendVerificationCodeByEmail(data: any): Promise<any> {
      const res = await this._unAuthService.reSendVerificationCodeByEmail(data);
      return res;
    },

    async prepareDataBeforeNextRoute() {
      await myProfileStore().fetchCurrentLoginUser();
      // await settingStore().getOrganizationSettings();
      // RouterInstanceClass.getInstance().generateRoutes();
      // RouterInstanceClass.getInstance().generateDynamicRoutes();
    },
  },
});
