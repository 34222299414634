import { SettingProgressEnum } from '@/ui/common/constants/constant';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import loginPageStore from '@/store/un-authenticate/login-store';
import router from '@/ui/router';
export interface ILoginResult {
  jwtToken: string;
  refreshToken: string;
  organizationCode: string;
  organizationId: number;
  organizationSelectRequire: boolean;
  organizationSettingProgress: SettingProgressEnum;
  currentSettingProgress: SettingProgressEnum;
  organizations: any;
  numberOfLogins: number;
}
export default class AuthenticateClass {
  constructor() {}

  createNewAccount(data, refId, voucherCode) {
    return UnAuthenticateService.getInstance().saveUserProfile(
      data,
      refId,
      voucherCode
    );
  }

  async handleSuccess(loginResult: ILoginResult) {
    if (!loginResult.jwtToken) return;

    // 0. Clean all previous org's data

    const currentProcess =
      loginResult.organizationSettingProgress ||
      loginResult.currentSettingProgress;

    MyOrganizationSingleton.setInstance(
      loginResult.organizations,
      loginResult.organizationId,
      loginResult.jwtToken,
      loginResult.refreshToken,
      loginResult.numberOfLogins,
      currentProcess
    );

    // 1. Has multiple organization => Select organization
    if (loginResult.organizationSelectRequire) {
      return {
        caseKey: AuthenticateCaseEnum.SelectOrganizationRequired,
        organizations: loginResult.organizations,
      };
    }
    // 2. Go to current organization
    if (
      currentProcess == SettingProgressEnum.SettingFinished ||
      currentProcess == SettingProgressEnum.SettingOrganization
    ) {
      await loginPageStore().prepareDataBeforeNextRoute();

      this.gotoNextRoute();
      return {
        caseKey: AuthenticateCaseEnum.GoToCurrentOrganization,
      };
    }

    // 3. Has not organization, and go to Home page
    if (
      currentProcess == SettingProgressEnum.NoWorkspace ||
      currentProcess == SettingProgressEnum.SelectInvitation
    ) {
      router.push({
        name: 'Home',
      });
      return {
        caseKey: AuthenticateCaseEnum.GoToHomePage,
      };
    }
  }

  gotoNextRoute() {
    const route = router.currentRoute;
    const redirecRoute = route.value?.query?.redirect;
    const nextRoute = redirecRoute
      ? redirecRoute.toString()
      : `/payment/management`;
    router.push(nextRoute);
  }
}
