import BaseRepository, {
  BaseResponse,
} from '@/base/repositories/BaseRepository';
import { EPlanCapacityKeys } from '@/application/enums/PlanEnums';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default class PlansRepository {
  private static instance: PlansRepository;
  baseRepository: BaseRepository;

  constructor() {
    this.baseRepository = new BaseRepository();
  }

  public static getInstance(): PlansRepository {
    if (!PlansRepository.instance) {
      PlansRepository.instance = new PlansRepository();
    }
    return PlansRepository.instance;
  }

  getCurrentOrgPlan(): any {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetCurrentOrgPlan`,
      method: 'get',
    });
  }

  getAllPlansDetail(): any {
    const lang = getLocalStorage(StorageConstant.LANGUAGE);
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetAllPlansDetail?lang=${lang}`,
      method: 'get',
    });
  }
  getOrgPlanCapacity(filters: EPlanCapacityKeys[]): Promise<BaseResponse> {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/GetOrgPlanCapacityStatus`,
      method: 'post',
      data: {
        filters,
      },
    });
  }
  checkOrganizationUsageExceed(): Promise<BaseResponse> {
    return this.baseRepository.requestWithAuthorize({
      url: `Plan/CheckOrganizationUsageExceedVer2`,
      method: 'get',
    });
  }

}
