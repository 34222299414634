<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 240.68 241.75"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect
          class="cls-2"
          x=".5"
          y=".5"
          width="239.68"
          height="240.75"
          rx="56.84"
          ry="56.84"
        />
        <path
          class="cls-3"
          d="m24.07,65.24c-2.19-13.58,7.04-26.37,20.62-28.56L214.59,9.26c-8.77-5.54-19.16-8.76-30.3-8.76H58.29C26.89.5,1.44,25.95,1.44,57.34v127.07c0,29.42,22.35,53.61,51,56.54L24.07,65.24Z"
        />
        <g>
          <g>
            <path
              class="cls-3"
              d="m148.91,76.29c-1.08-1.11-2.46-1.66-4.13-1.66-2.46,0-4.26,1.18-5.39,3.54-2.41-2.83-5.71-4.25-9.89-4.25-5.02,0-9.24,1.94-12.66,5.81-3.42,3.87-5.13,8.59-5.13,14.16s1.71,10.29,5.13,14.16c3.42,3.87,7.64,5.81,12.66,5.81,4.18,0,7.48-1.42,9.89-4.25,1.13,2.36,2.93,3.54,5.39,3.54,1.67,0,3.05-.55,4.13-1.66,1.08-1.11,1.62-2.47,1.62-4.07v-27.05c0-1.6-.54-2.96-1.62-4.07Zm-11.99,24.31c-1.4,1.81-3.34,2.71-5.79,2.71s-4.39-.9-5.79-2.71c-1.4-1.81-2.1-4.04-2.1-6.71s.7-4.9,2.1-6.71c1.4-1.81,3.33-2.71,5.79-2.71s4.39.9,5.79,2.71c1.4,1.81,2.1,4.04,2.1,6.71s-.7,4.9-2.1,6.71Z"
            />
            <path
              class="cls-3"
              d="m163.56,111.48c-1.08,1.11-2.46,1.66-4.13,1.66s-3.05-.55-4.13-1.66c-1.08-1.11-1.62-2.47-1.62-4.07v-48.42c0-1.6.54-2.96,1.62-4.07,1.08-1.11,2.46-1.66,4.13-1.66s3.05.55,4.13,1.66c1.08,1.11,1.62,2.47,1.62,4.07v48.42c0,1.61-.54,2.96-1.62,4.07h0Z"
            />
            <path
              class="cls-3"
              d="m201.7,79.72c-3.69-3.87-8.39-5.81-14.1-5.81s-10.37,1.91-13.98,5.74c-3.62,3.82-5.43,8.57-5.43,14.23s1.81,10.41,5.43,14.23c3.62,3.82,8.28,5.74,13.98,5.74s10.41-1.93,14.1-5.81c3.54-3.78,5.31-8.5,5.31-14.16s-1.77-10.38-5.31-14.16Zm-8.3,20.87c-1.4,1.81-3.33,2.71-5.79,2.71s-4.39-.9-5.79-2.71c-1.4-1.81-2.1-4.04-2.1-6.71s.7-4.9,2.1-6.71c1.4-1.81,3.33-2.71,5.79-2.71s4.39.9,5.79,2.71c1.4,1.81,2.1,4.04,2.1,6.71s-.7,4.9-2.1,6.71Z"
            />
            <path
              class="cls-3"
              d="m105.34,75.25c4.07-5.25,6.1-8.78,6.1-10.6,0-4.21-2.65-6.31-7.95-6.31h-27.94c-2.2,0-3.89.52-5.1,1.56-1.2,1.04-1.81,2.39-1.81,4.05s.6,3.01,1.81,4.05c1.21,1.04,2.9,1.56,5.1,1.56h19.19l-24.57,31.74c-1.61,2.08-2.41,3.92-2.41,5.53,0,4.62,3.05,6.92,9.15,6.92h28.83c4.61,0,6.91-1.93,6.91-5.8s-2.3-5.8-6.91-5.8h-21.2l20.8-26.89h0Z"
            />
          </g>
          <g>
            <path
              class="cls-1"
              d="m90.64,143.64h-7.18v14.91h7.18c2.16,0,3.9-.7,5.24-2.11,1.34-1.41,2-3.19,2-5.35s-.67-3.94-2-5.35c-1.34-1.41-3.08-2.11-5.24-2.11Z"
            />
            <path
              class="cls-1"
              d="m126.45,157.66c-2.28,0-4.06.84-5.36,2.51-1.3,1.67-1.95,3.74-1.95,6.21s.65,4.53,1.95,6.21c1.3,1.67,3.08,2.51,5.36,2.51s4.06-.84,5.36-2.51c1.3-1.67,1.95-3.74,1.95-6.21s-.65-4.53-1.95-6.21c-1.3-1.67-3.09-2.51-5.36-2.51Z"
            />
            <path
              class="cls-1"
              d="m177.14,122.32h-104.04c-3.47,0-6.29,2.81-6.29,6.28v60.96c0,3.47,2.81,6.28,6.29,6.28h82.13c-.87-.82-1.3-1.78-1.3-2.88,0-.9.2-1.84.61-2.83l3.87-8.61-10.71-26.74c-.31-.76-.47-1.59-.47-2.49,0-1.17.49-2.16,1.48-2.96.99-.81,2.02-1.21,3.1-1.21,2.38,0,4,1.14,4.85,3.44l6.67,18.25,7.14-18.25c.9-2.29,2.51-3.44,4.85-3.44,1.08,0,2.11.4,3.1,1.21.99.81,1.48,1.8,1.48,2.96,0,.9-.16,1.73-.47,2.49l-15.87,38.52c-.43,1.08-.96,1.92-1.57,2.55h15.14c3.47,0,6.29-2.81,6.29-6.28v-60.96c0-3.47-2.81-6.28-6.29-6.28Zm-73.48,41.01c-3.28,3-7.41,4.5-12.38,4.5h-7.81v11.54c0,1.74-.49,3.08-1.48,4.04-.99.96-2.25,1.44-3.8,1.44s-2.81-.48-3.8-1.44c-.99-.96-1.48-2.31-1.48-4.04v-39.53c0-3.66,1.83-5.49,5.49-5.49h12.45c5.21,0,9.51,1.56,12.91,4.68,3.4,3.12,5.1,7.16,5.1,12.14s-1.74,9.07-5.21,12.17Zm40.75,15.56c0,1.49-.5,2.74-1.5,3.77-1,1.03-2.28,1.54-3.83,1.54-2.28,0-3.94-1.09-4.98-3.28-2.23,2.62-5.28,3.93-9.15,3.93-4.64,0-8.55-1.79-11.71-5.37-3.16-3.58-4.74-7.95-4.74-13.11s1.58-9.53,4.74-13.11c3.16-3.58,7.07-5.37,11.71-5.37,3.87,0,6.92,1.31,9.15,3.93,1.04-2.18,2.71-3.28,4.98-3.28,1.55,0,2.82.51,3.83,1.54,1,1.03,1.5,2.28,1.5,3.77v25.04Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #03ca77;
}

.cls-2 {
  fill: #fff;
  stroke: #eaeaea;
  stroke-miterlimit: 10;
}

.cls-3 {
  fill: #0068ff;
}
</style>
