// Firebase V9
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { VUE_APP_FIREBASE } from './firebaseConfig';
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

// Firebase App
export const firebaseApp = initializeApp(VUE_APP_FIREBASE);

// Remote config
export const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings = {
  minimumFetchIntervalMillis: parseInt(
    process.env.VUE_APP_FETCH_REMOTE_CONFIG_TIME_MS || ''
  ),
  fetchTimeoutMillis: 0,
};
export const initRemoteConfig = () => {
  return fetchAndActivate(remoteConfig);
};

// Analysic
const analytics = getAnalytics(firebaseApp);
export const initAnalytics = () => {
  return setAnalyticsCollectionEnabled(analytics, true);
};

export const initAll = () => {
  return Promise.all([initRemoteConfig(), initAnalytics()]);
};
