import UnAuthService from '@/application/services/un-authenticate/UnAuthenticateService';
import { AuthenticateType } from '@/ui/common/constants/constant';
import AuthenticateClass from '../authenticate/AuthenticateClass';

export class AuthenticateUserPassword implements IAuthenticate {
  userName: string;
  password: string;
  loginType: AuthenticateType;
  loginId: string;
  _unauthService: UnAuthService;

  constructor(
    userName: string,
    password: string,
    loginType: AuthenticateType,
    loginId: string
  ) {
    this.userName = userName;
    this.password = password;
    this.loginType = loginType;
    this.loginId = loginId;
    this._unauthService = UnAuthService.getInstance();
  }

  async login() {
    try {
      const res: any = await this._unauthService.loginWithPassword({
        userName: this.userName,
        password: this.password,
        loginType: this.loginType,
        loginId: this.loginId,
      });

      // handle login success
      return new AuthenticateClass().handleSuccess(res?.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export class AuthenticatePhoneCode implements IAuthenticate {
  phoneNumber: string;
  code: string;
  sessionInfo: string;
  _unauthService: UnAuthService;

  constructor(phoneNumber: string, code: string, sessionInfo: string) {
    this.phoneNumber = phoneNumber;
    this.code = code;
    this.sessionInfo = sessionInfo;
    this._unauthService = UnAuthService.getInstance();
  }

  async login() {
    const res: any = await this._unauthService.loginByPhoneCode({
      phoneNumber: this.phoneNumber,
      code: this.code,
      sessionInfo: this.sessionInfo,
    });

    // handle login success
    return new AuthenticateClass().handleSuccess(res?.result);
  }
}

export class AuthenticateEmailCode implements IAuthenticate {
  email: string;
  code: string;
  _unauthService: UnAuthService;

  constructor(email: string, code: string) {
    this.email = email;
    this.code = code;
    this._unauthService = UnAuthService.getInstance();
  }

  async login() {
    const res: any = await this._unauthService.loginByEmailCode({
      email: this.email,
      code: this.code,
    });

    // handle login success
    return new AuthenticateClass().handleSuccess(res?.result);
  }
}

interface IAuthenticate {
  login: any;
}
