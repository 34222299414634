<template>
  <svg
    color="#2e4362"
    viewBox="0 0 1024 1024"
    style="display: inline-block; stroke: currentcolor; fill: currentcolor"
  >
    <path
      d="M485.333 405.333h-26.667v-53.333h26.667v26.667h53.333v26.667h-26.667v26.667h-26.667v-26.667zM538.667 725.333v-26.667h-26.667v26.667h26.667zM512 325.333v-26.667h-53.333v26.667h26.667v26.667h26.667v-26.667zM725.333 298.667v160h-26.667v-26.667h-106.667v-133.333h133.333zM698.667 405.333v-80h-80v80h80zM325.333 458.667h-26.667v26.667h53.333v-26.667h-26.667zM325.333 538.667h26.667v26.667h26.667v-80h-26.667v26.667h-53.333v53.333h26.667v-26.667zM458.667 565.333v53.333h26.667v-53.333h-26.667zM565.333 298.667h-26.667v80h26.667v-80zM565.333 432v-26.667h-26.667v26.667h26.667zM645.333 378.667h26.667v-26.667h-26.667v26.667zM378.667 352h-26.667v26.667h26.667v-26.667zM432 298.667v133.333h-133.333v-133.333h133.333zM405.333 325.333h-80v80h80v-80zM645.333 458.667v26.667h-26.667v-26.667h-53.333v26.667h-26.667v-26.667h-80v-26.667h-26.667v26.667h-26.667v26.667h26.667v53.333h26.667v-26.667h26.667v53.333h26.667v-53.333h80v26.667h-53.333v26.667h53.333v26.667h26.667v-80h26.667v26.667h26.667v53.333h26.667v-26.667h26.667v-26.667h-26.667v-26.667h-26.667v-26.667h26.667v-26.667h-53.333zM352 672h26.667v-26.667h-26.667v26.667zM298.667 592h133.333v133.333h-133.333v-133.333zM325.333 698.667h80v-80h-80v80zM645.333 618.667v-26.667h-26.667v26.667h26.667zM645.333 645.333h-26.667v26.667h-26.667v-26.667h-26.667v-26.667h26.667v-26.667h-53.333v-26.667h-26.667v53.333h-26.667v26.667h-26.667v80h26.667v-26.667h26.667v-26.667h53.333v53.333h26.667v-26.667h26.667v26.667h53.333v-26.667h26.667v-26.667h-53.333v-26.667zM405.333 565.333h26.667v-26.667h-26.667v26.667zM672 618.667v26.667h26.667v26.667h26.667v-80h-26.667v26.667h-26.667zM725.333 725.333v-26.667h-26.667v26.667h26.667zM725.333 512v-26.667h-26.667v26.667h26.667zM672 245.333h106.667v106.667h53.333v-160h-160v53.333zM245.333 352v-106.667h106.667v-53.333h-160v160h53.333zM352 778.667h-106.667v-106.667h-53.333v160h160v-53.333zM778.667 672v106.667h-106.667v53.333h160v-160h-53.333z"
    ></path>
  </svg>
</template>
