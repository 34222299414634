import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import {
  getRemoteConfigValueByKey,
  RemoteConfigKey,
} from '@/ui/plugins/firebases/firebase-remote-config';
import { Value } from '@firebase/remote-config';

export default defineStore({
  id: storeId.remoteConfig,
  state: () => ({
    paymentVnpayEnabled: false,
    paymentMomoEnabled: false,
    paymentMethodActive: {
      vnpay: false,
      momo: false,
      zaloPay: false,
    },
  }),
  getters: {},
  actions: {
    fetchRemoteConfigs() {
      const paymentVnpayEnabled = getRemoteConfigValueByKey(
        RemoteConfigKey.PAYMENT_VNPAY_ENABLED
      ).asBoolean();
      const paymentMomoEnabled = getRemoteConfigValueByKey(
        RemoteConfigKey.PAYMENT_MOMO_ENABLED
      ).asBoolean();
      const paymentZaloPayEnabled = getRemoteConfigValueByKey(
        RemoteConfigKey.PAYMENT_ZALOPAY_ENABLED
      ).asBoolean();

      this.paymentMethodActive = {
        vnpay: paymentVnpayEnabled,
        momo: paymentMomoEnabled,
        zaloPay: paymentZaloPayEnabled,
      };
      return this.paymentMethodActive;
    },
    getRemoteConfig(key): Value {
      return getRemoteConfigValueByKey(key);
    },
  },
});
