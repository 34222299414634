import tippy, { animateFill, roundArrow } from 'tippy.js';

export default {
  install: (app) => {
    app.directive('vig-tooltip', {
      mounted(el, binding) {
        const content = binding.value?.content || binding.value || '';
        const allowHTML = true;
        const appendTo =
          binding.value?.appendTo === 'body' ? () => document.body : 'parent';
        const placement = binding.value?.placement || 'top';
        const theme = binding.value?.theme || 'translucent';
        const showOnCreate = binding.value?.showOnCreate || false;
        const interactive = binding.value?.interactive || false;
        const animation = binding.value?.animation || 'scale';
        const zIndex = binding.value?.zIndex || 9999;
        const delay =
          binding.value?.delay || binding.value?.delay === 0
            ? binding.value?.delay
            : 200;
        const skidding =
          binding.value?.skidding || binding.value?.skidding === 0
            ? binding.value?.skidding
            : 0;
        const distance =
          binding.value?.distance || binding.value?.distance === 0
            ? binding.value?.distance
            : 5;

        tippy(el, {
          appendTo,
          allowHTML,
          content,
          showOnCreate,
          arrow: roundArrow,
          theme,
          interactive,
          animation,
          placement,
          animateFill: true,
          delay: [delay, null],
          offset: [skidding, distance],
          plugins: [animateFill],
          zIndex,
        });
      },
      unmounted(el) {
        el._tippy && el._tippy.destroy();
      },
    });
  },
};
