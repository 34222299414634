<script setup></script>

<template>
  <div class="flex items-center justify-center w-full">
    <div
      style="display: flex; flex-wrap: wrap"
      class="w-full md:w-3/4 px-12 py-8"
    >
      <div class="text-3xl pr-4 pt-4 pb-8">Điều khoản chung</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <span class="font-medium"
            >Điều khoản dịch vụ của người dùng Tictop</span
          >
          <span class="text-gray-600 leading-loose">
            <b>Tictop</b> (“chúng tôi”, “của chúng tôi”) là nền tảng (phần mềm
            dịch vụ) hỗ trợ quản lý công việc, quản lý thời gian và làm việc
            hiệu quả dành cho doanh nghiệp, tổ chức, đội nhóm, được vận hành và
            phát triển bởi <b>VIG TECHNOLOGY., JSC</b>. Ngoài việc hỗ trợ bằng
            các công cụ công nghệ trực tuyến, chúng tôi không tham gia vào quá
            trình trực tiếp sử dụng của Khách hàng. Các Khách hàng đồng ý sử
            dụng Tictop là để quản lý công việc tại tổ chức của mình sẽ mặc
            nhiên đồng ý với điều khoản sử dụng này.<br />
            Chúng tôi sẵn sàng giải thích cho khách hàng về các Điều khoản này.
            Mọi thắc mắc xin vui lòng liên hệ email
            <b
              ><a href="mailto:services@tictop.app?subject=Support For Customer"
                >services@tictop.app</a
              ></b
            >.<br />
            Đôi khi, chúng tôi có thể sửa đổi các Điều khoản này. Vui lòng kiểm
            tra trang này định kỳ để cập nhật. Nếu bạn không đồng ý hoặc không
            thể tuân thủ Điều khoản đã sửa đổi, bạn phải ngừng sử dụng Dịch vụ
            và Trang web. Các Điều khoản cập nhật sẽ có hiệu lực khi chúng được
            đăng và sẽ áp dụng trong tương lai. Việc bạn tiếp tục sử dụng Dịch
            vụ và Trang web sau bất kỳ cập nhật nào như vậy sẽ mặc định việc bạn
            chấp nhận những thay đổi đó.
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium">Các định nghĩa</span>
          <span class="text-gray-600 leading-loose">
            <b>Tictop:</b> là nền tảng (phần mềm dịch vụ) hỗ trợ quản lý công
            việc, quản lý thời gian và làm việc hiệu quả dành cho doanh nghiệp,
            tổ chức, đội nhóm. Tictop có trang web giới thiệu chính thức tại địa
            chỉ
            <b><a href="https://www.tictop.vn">https://www.tictop.vn</a></b>
            .<br />
            <b>VIG TECHNOLOGY., JSC:</b> là đơn vị sở hữu và toàn quyền khai
            thác nền tảng Tictop<br />
            <b>Khách hàng:</b> Là các tổ chức hoặc cá nhân có nhu cầu sử dụng
            nền tảng Tictop.<br />
            <b>Tài khoản tổ chức Tictop của Khách hàng:</b> Là một hệ thống được
            Tictop mở quyền truy cập cho Khách hàng. Đây là không gian làm việc
            cho Khách hàng và các cá nhân được Khách hàng cấp quyền sử dụng.<br />
            <b>Tài khoản sử dụng:</b> Là tài khoản người dùng trên Tài khoản tổ
            chức của Khách hàng mà Khách hàng tạo quyền cho người dùng đó. Với
            mỗi một Tài khoản tổ chức, Khách hàng có toàn quyền cấp quyền sử
            dụng cho các nhân viên của mình tham gia vào tổ chức. Bằng hành động
            gửi đường dẫn mời tham gia tổ chức tới các địa chỉ email hoặc số
            điện thoại khác nhau hoặc gửi mã liên kết tổ chức, Khách hàng đồng ý
            cấp quyền sử dụng tổ chức Tictop của Khách hàng cho cá nhân là chủ
            sở hữu của email / số điện thoại đó. Mỗi một thành viên trong tổ
            chức được tính là 01 tài khoản sử dụng. Số tài khoản sử dụng là căn
            cứ tính chi phí sử dụng nền tảng Tictop.
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium"
            >Tài khoản Tổ chức Tictop của Khách hàng</span
          >
          <span class="text-gray-600 leading-loose">
            Trong quá trình đăng kí Tictop, khách hàng sẽ tạo tài khoản bằng
            email và số điện thoại.<br />
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Khách hàng có trách nhiệm phải tự bảo mật tài khoản của mình
                cũng như chịu trách nhiệm hoàn toàn về các hoạt động liên quan
                đến tài khoản của Khách hàng.
              </li>
              <li>
                Mỗi một tổ chức tùy theo mức gói đăng ký mà Khách hàng có thể
                tạo tài khoản cho các nhân viên của mình tham gia sử dụng. Số
                lượng tài khoản sử dụng tùy thuộc vào mức phí đăng ký.
              </li>
              <li>
                Khách hàng thông báo ngay cho Tictop khi có bất cứ ai sử dụng
                tài khoản của mình mà không được phép hay bất cứ lỗi bảo mật
                nào, và bảo đảm Khách hàng đăng xuất (logout) khỏi tài khoản sau
                mỗi phiên sử dụng. Tictop không thể và sẽ không chịu trách nhiệm
                cho bất cứ sự mất mát, hư hỏng hay trách nhiệm nào khác nếu
                Khách hàng không tuân thủ điều này hay do sự truy cập trái phép
                vào tài khoản của Khách hàng.
              </li>
            </ul>
            Trong trường hợp có tranh chấp giữa hai hoặc nhiều bên về quyền sở
            hữu tài khoản, Khách hàng đồng ý rằng <b>VIG TECHNOLOGY., JSC</b> sẽ
            là trọng tài duy nhất của tranh chấp đó và quyết định của
            <b>VIG TECHNOLOGY., JSC</b> (có thể bao gồm việc chấm dứt hoặc đình
            chỉ tài khoản tranh chấp) là quyết định cuối cùng và ràng buộc tất
            cả các bên.
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium">Bảo vệ quyền riêng tư</span>
          <span class="text-gray-600 leading-loose">
            <b>VIG TECHNOLOGY., JSC</b> bảo mật toàn bộ thông tin của Khách hàng
            và những nội dung các tài khoản sử dụng của Khách hàng trao đổi, lưu
            trữ trên nền tảng Tictop. Chúng tôi sẽ thực hiện và duy trì tất cả
            các biện pháp bảo vệ nội bộ cần thiết và trong khả năng của chúng
            tôi; mang tính hành chính, vật lý và kỹ thuật để bảo vệ cho tính bảo
            mật và toàn vẹn đối với dữ liệu Khách hàng.<br />
            <b>VIG TECHNOLOGY., JSC</b> cam kết:
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Không chủ động thay đổi các dữ liệu được tạo ra bởi Khách hàng.
                Điều này không bao hàm việc hệ thống phần mềm có thể tự động sửa
                đổi kích cỡ, định dạng dữ liệu để phù hợp với mục đích hiển thị
                và chức năng sử dụng.
              </li>
              <li>
                Không truy cập vào dữ liệu của Khách hàng trừ trường hợp khắc
                phục lỗi kỹ thuật hoặc theo yêu cầu của Khách hàng hoặc trường
                hợp phải tuân theo quy định của pháp luật.
              </li>
              <li>
                Không tiết lộ dữ liệu của Khách hàng ngoại trừ một trong các
                trường hợp sau:
                <ul style="list-style-type: circle; margin-left: 2rem">
                  <li>Có sự chấp thuận của Khách hàng.</li>
                  <li>Phải tuân theo quy định của pháp luật.</li>
                  <li>Thuộc trường hợp quy định tại Điều khoản sử dụng này.</li>
                </ul>
              </li>
            </ul>
            Mặc dù vậy, <b>VIG TECHNOLOGY., JSC</b> có quyền tiếp cận một cách
            chủ động hoặc tự động thông tin về các tài khoản đang tồn tại trên
            hệ thống (bao gồm Họ tên, email) cho một số mục đích nhất định, bao
            gồm:<br />
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Thông báo bằng email đến Khách hàng về các tính năng mới hoặc
                các sản phẩm mới.
              </li>
              <li>
                Thông báo bằng email đến Khách hàng về các sự kiện, chương trình
                khuyến mại, thông tin về sự cố sản phẩm, dịch vụ…
              </li>
              <li>
                Liên hệ trực tiếp với Khách hàng để hỗ trợ kỹ thuật hoặc các hỗ
                trợ khác nhằm tăng trải nghiệm người dùng.
              </li>
            </ul>
            <b>VIG TECHNOLOGY., JSC</b> toàn quyền sử dụng tên của Khách hàng
            cho mục đích Marketing hoặc trong các chiến dịch quảng bá, giới
            thiệu sản phẩm hoặc trong các cuộc nói chuyện, trao đổi với các
            Khách hàng khác của <b>VIG TECHNOLOGY., JSC</b> hoặc các Khách hàng
            tiềm năng khác của <b>VIG TECHNOLOGY., JSC</b>. Ví dụ,
            <b>VIG TECHNOLOGY., JSC</b> có thể đưa tên Khách hàng vào trang web
            hiển thị tên tất cả các Khách hàng của mình.
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium"
            >Dữ liệu của Khách hàng và trách nhiệm của các bên</span
          >
          <span class="text-gray-600 leading-loose">
            Dữ liệu của Khách hàng là mọi thông tin mà Khách hàng gửi, tải lên,
            truyền hoặc bằng bất kể phương thức nào giúp hiển thị thông tin đó
            trên tài khoản Tictop của Khách hàng. Dữ liệu đó bao gồm: các tin
            nhắn, các thông báo, các tập tin, các hình ảnh, các video, các đường
            dẫn, các nội dung liên quan đến công việc và tất cả mọi thông tin
            được hiển thị trên Hệ thống Tictop của Khách hàng; Dữ liệu của Khách
            hàng bao gồm cả mọi thông tin, đường dẫn, văn bản mà Khách hàng và
            các tài khoản sử dụng của Khách hàng nhập vào Tictop thông qua các
            nền tảng lưu trữ đám mây như Google Drive, OneDrive…<br />

            Khách hàng có quyền sử dụng và kiểm soát dữ liệu được đăng tải trên
            tài khoản Tictop của Khách hàng. <b>VIG TECHNOLOGY., JSC</b> cung
            cấp các tính năng có sẵn, theo hiện trạng và áp dụng chung cho tất
            cả Khách hàng của <b>VIG TECHNOLOGY., JSC</b> để Khách hàng xóa hoặc
            trích xuất dữ liệu ra bên ngoài hệ thống tổ chức Tictop. Khách hàng
            chịu mọi trách nhiệm cho tất cả các dữ liệu mà Khách hàng hoặc tài
            khoản sử dụng của Khách hàng gửi đến và/hoặc gửi đi từ nền tảng
            Tictop.<br />

            Khách hàng hiểu rằng <b>VIG TECHNOLOGY., JSC</b> không sở hữu bất kì
            dữ liệu nào mà Khách hàng đăng tải lên Tictop; trừ các tài liệu và
            hoặc giao diện hướng dẫn được <b>VIG TECHNOLOGY., JSC</b> chủ động
            cung cấp công khai cho Khách hàng.<br />

            Việc quyết định đưa dữ liệu nào lên nền tảng Tictop là toàn quyền
            của Khách hàng. Tuy nhiên, những nội dung sau sẽ KHÔNG ĐƯỢC PHÉP
            đăng tải trên nền tảng Tictop:<br />

            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn đến
                con người và tài sản.
              </li>
              <li>
                Liên quan đến việc công bố các thông tin hoặc tài liệu lừa đảo,
                gây mất uy tín danh dự, quấy rối hoặc mang tính khiêu dâm.
              </li>
              <li>
                Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, tôn giáo,
                giới tính, người tàn tật.
              </li>
              <li>Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác.</li>
              <li>
                Cản trở hoặc phá hỏng Tictop (bao gồm nhưng không giới hạn bởi
                việc truy cập trái phép Tictop thông qua bất cứ phương tiện máy
                móc, phần mềm).
              </li>
            </ul>

            <b>VIG TECHNOLOGY., JSC</b> có toàn quyền vô hiệu hóa quyền truy cập
            vào nội dung dữ liệu của Khách hàng nếu chúng tôi xác định dữ liệu
            được truy cập vi phạm Điều khoản này, hoặc chúng tôi nhận được đơn
            khiếu nại từ tài khoản sử dụng, hoặc thông báo vi phạm sở hữu trí
            tuệ và/hoặc thông báo nội dung có dấu hiệu vi phạm pháp luật của các
            cơ quan có thẩm quyền.<br />

            Khách hàng tự chịu trách nhiệm trước pháp luật nếu sử dụng phần mềm
            trái với quy định của pháp luật.<br />
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium">Tạm ngừng và chấm dứt sử dụng Tictop</span>
          <span class="text-gray-600 leading-loose">
            <b>Thời hạn thỏa thuận</b><br />
            Thỏa thuận này bắt đầu kể từ ngày Khách hàng đồng ý và chấm dứt khi
            tất cả các tài khoản được cấp kèm theo thỏa thuận này hết hạn sử
            dụng. Đối với trường hợp Khách hàng dùng thử, nếu Khách hàng không
            chuyển sang hình thức thuê bao trước khi hết hạn dùng thử, thỏa
            thuận này sẽ được chấm dứt khi hết hạn dùng thử.<br />

            <b>Tạm dừng</b><br />
            <b>VIG TECHNOLOGY., JSC</b> có quyền tạm ngừng việc sử dụng của
            Khách hàng đối với Khách hàng trong các trường hợp sau:
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Khách hàng không thực hiện nghĩa vụ thanh toán các khoản chi phí
                sử dụng Khách hàng sau khi <b>VIG TECHNOLOGY., JSC</b> đã gửi
                thông báo yêu cầu thanh toán 30 ngày.
              </li>
              <li>
                <b>VIG TECHNOLOGY., JSC</b> cho rằng hệ thống Tictop đang được
                Khách hàng sử dụng để tham gia vào các cuộc tấn công, gửi thư
                rác, các hoạt động bất hợp pháp hoặc việc sử dụng nền tảng
                Tictop của Khách hàng gây nguy hại tới
                <b>VIG TECHNOLOGY., JSC</b> và những đối tượng khác
              </li>
            </ul>

            <b>Thỏa thuận được coi như chấm dứt trong các trường hợp sau:</b
            ><br />
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                <b>VIG TECHNOLOGY., JSC</b> đơn phương chấm dứt thỏa thuận do
                Khách hàng không thực hiện nghĩa vụ thanh toán cho
                <b>VIG TECHNOLOGY., JSC</b> theo quy định
              </li>
              <li>
                Theo yêu cầu của tòa án và cơ quan nhà nước có thẩm quyền.
              </li>
              <li>
                Khi có thỏa thuận của Khách hàng và
                <b>VIG TECHNOLOGY., JSC</b> về việc chấm dứt thỏa thuận.
              </li>
              <li>
                <b>VIG TECHNOLOGY., JSC</b> không có nghĩa vụ hoàn trả bất kể
                chi phí nào mà Khách hàng đã thanh toán trong trường hợp chấm
                dứt thỏa thuận vì những lý do đã nêu trên.
              </li>
            </ul>
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium"
            >Điều khoản bảo đảm và giới hạn trách nhiệm của chúng tôi</span
          >
          <span class="text-gray-600 leading-loose">
            <b>Từ chối đảm bảo</b><br />
            <b>VIG TECHNOLOGY., JSC</b> không cam đoan, tuyên bố, hoặc bảo đảm
            rằng việc sử dụng nền tảng Tictop của Khách hàng sẽ không bị gián
            đoạn hoặc không bị lỗi, hoặc nền tảng Tictop sẽ đáp ứng yêu cầu
            Khách hàng hoặc tất cả các lỗi trên phần mềm và/hoặc tài liệu sẽ
            được sửa hoặc hệ thống tổng thể đảm bảo hoạt động của nền tảng
            Tictop (bao gồm nhưng không giới hạn: mạng internet, các mạng truyền
            dẫn khác, mạng nội bộ và các thiết bị của Khách hàng).
            <b>VIG TECHNOLOGY., JSC</b> không đảm bảo dưới bất kỳ hình thức nào,
            dù rõ ràng hay ngầm định về các điều kiện như sự thỏa mãn về chất
            lượng, phù hợp cho nhu cầu sử dụng đặc thù hoặc không xâm phạm các
            quyền của bên thứ ba. Khách hàng sẽ chịu toàn bộ trách nhiệm trong
            việc xác định xem nền tảng Tictop hoặc thông tin được tạo ra từ nền
            tảng Tictop là đúng đắn và đáp ứng đầy đủ cho mục đích sử dụng của
            mình.<br />

            <b>Giới hạn về trách nhiệm pháp lý</b><br />
            Trong bất cứ trường hợp nào <b>VIG TECHNOLOGY., JSC</b> đều không
            chịu trách nhiệm về bất kỳ các thiệt hại nào trực tiếp, gián tiếp,
            ngẫu nhiên hoặc mang tính chất trừng phạt, bao gồm nhưng không giới
            hạn ở các thiệt hại do mất doanh thu, mất lợi nhuận, mất lợi thế
            kinh doanh, do ngừng việc hoặc mất mát dữ liệu do hậu quả của: (1)
            việc sử dụng hoặc không thể sử dụng nền tảng Tictop; (2) bất kỳ các
            thay đổi nào được thực hiện đối với nền tảng Tictop; (3)từ chối
            quyền truy cập hoặc biến đổi các dữ liệu; (4) xóa, sai hỏng, hoặc
            không lưu trữ dữ liệu có trên hoặc thông qua nền tảng Tictop; (5)
            các tuyên bố hay hành vi của bất kỳ bên thứ ba nào đối với nền tảng
            Tictop; và (6) bất kỳ vấn đề nào khác liên quan đến nền tảng
            Tictop.<br />
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium">Bất khả kháng</span>
          <span class="text-gray-600 leading-loose">
            Sự kiện bất khả kháng là sự kiện xảy ra mang tính khách quan và nằm
            ngoài tầm kiểm soát của các Bên, bao gồm nhưng không giới hạn: động
            đất, bão, lũ, lụt, lốc, sóng thần, lở đất; hỏa hoạn; dịch bệnh;
            chiến tranh hoặc có nguy cơ xảy ra chiến tranh và các thảm họa tự
            nhiên khác chưa lường hết được; việc truy cập tictop.vn hoặc
            tictop.app, ứng dụng Tictop trên điện thoại bị gián đoạn do can
            thiệp bởi ISP (Internet service provider), sự thay đổi chính sách
            hoặc sự ngăn cấm theo các quyết định của cơ quan có thẩm quyền.<br />

            Việc một Bên không hoàn thành nghĩa vụ của mình do sự kiện bất khả
            kháng sẽ không phải là cơ sở để bên kia chấm dứt hợp đồng và bồi
            thường thiệt hại. Tuy nhiên, Bên bị ảnh hưởng bởi sự kiện bất khả
            kháng có nghĩa vụ phải:<br />

            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Tiến hành các biện pháp ngăn ngừa hợp lý và các biện pháp thay
                thế cần thiết để hạn chế tối đa ảnh hưởng do sự kiện bất khả
                kháng gây ra.
              </li>
              <li>
                Thông báo bằng văn bản cho bên kia về trường hợp được miễn trách
                nhiệm và những hậu quả có thể xảy ra do sự kiện bất khả kháng
                sau khi có cơ sở để xác định việc xảy ra sự kiện bất khả kháng
                làm ảnh hưởng trực tiếp đến khả năng thực hiện nghĩa vụ hợp
                đồng.
              </li>
            </ul>
          </span>
        </div>

        <div class="flex flex-col space-y-3">
          <span class="font-medium">Giải quyết tranh chấp</span>
          <span class="text-gray-600 leading-loose">
            Tất cả các tranh chấp hoặc các bất đồng phát sinh giữa 02 bên sẽ
            được giải quyết trên tinh thần hợp tác để cùng nhau giải quyết.<br />

            Trong trường hợp 02 bên thương lượng không thể đạt được kết quả thì
            sẽ đưa ra giải quyết theo trình tự tố tụng tại Tòa án có thẩm quyền.
            Phán quyết của Tòa án là chung thẩm, mang quyết định cuối cùng buộc
            các bên phải tuân thủ.<br />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
