<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 512.001 512.001"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M216.319,130.778l164.925,164.925L267.108,409.839c9.192,0.995,18.121,5.006,25.172,12.057  c17.11,17.11,16.378,45.314-2.204,61.477c-15.63,13.593-39.266,13.624-54.927,0.08c-8.476-7.329-13.25-17.15-14.349-27.304  l-53.406,53.406c-3.262,3.262-8.551,3.262-11.813,0l-44.684-44.684c-3.541-3.541-2.3-9.542,2.356-11.412  c5.101-2.045,9.884-5.141,14.014-9.271c16.418-16.418,16.41-43.022,0-59.432s-43.014-16.418-59.432,0  c-4.13,4.13-7.226,8.913-9.271,14.014c-1.87,4.656-7.871,5.897-11.412,2.356L2.468,356.441c-3.262-3.262-3.262-8.551,0-11.813  l53.406-53.406c-9.2-0.987-18.121-5.006-25.172-12.057c-17.11-17.11-16.378-45.314,2.204-61.477  c15.63-13.593,39.266-13.624,54.927-0.08c8.476,7.329,13.25,17.15,14.349,27.305L216.319,130.778z"
        fill="#008d8d"
        data-original="#ff5f5f"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M216.319,130.778l164.925,164.925l-42.648,42.648l-41.374-41.374  c-1.838,2.555-3.884,4.966-6.128,7.21c-11.508,11.508-27.44,17.794-43.706,17.221c-16.505-0.573-31.586-7.775-42.457-20.285  c-19.068-21.933-19.116-55.055-0.103-77.043c0.939-1.098,1.942-2.165,2.968-3.191c2.244-2.244,4.656-4.289,7.21-6.128  l-41.335-41.335L216.319,130.778z"
        fill="#007f7f"
        data-original="#f44d4d"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M483.402,235.091c-7.3-8.469-17.088-13.253-27.207-14.382l53.338-53.338  c3.262-3.262,3.262-8.551,0-11.813l-53.397-53.397c10.148-1.098,19.973-5.873,27.302-14.348  c13.546-15.667,13.514-39.298-0.075-54.927c-16.163-18.588-44.371-19.324-61.487-2.207c-7.047,7.047-11.059,15.975-12.054,25.168  l-53.4-53.4c-3.262-3.262-8.551-3.262-11.813,0l-44.682,44.682c-3.545,3.545-2.3,9.54,2.352,11.409  c5.103,2.051,9.885,5.144,14.017,9.275c16.418,16.418,16.41,43.022,0,59.432s-43.014,16.418-59.432,0  c-4.131-4.131-7.224-8.914-9.275-14.017c-1.87-4.652-7.864-5.897-11.409-2.352l-50.589,50.589l49.188,49.188  c4.148,4.148,2.829,11.139-2.525,13.534c-5.434,2.431-10.456,6.074-14.658,10.934c-13.546,15.667-13.513,39.299,0.077,54.927  c16.163,18.587,44.37,19.322,61.486,2.206c3.732-3.732,6.613-7.992,8.645-12.539c2.39-5.349,9.385-6.652,13.527-2.51l49.185,49.185  l59.244-59.244c1.02,9.144,5.025,18.018,12.037,25.03c17.117,17.117,45.326,16.38,61.488-2.209  C496.858,274.355,496.908,250.76,483.402,235.091z"
        fill="#f2cb05"
        data-original="#42c6f4"
        class=""
      ></path>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
