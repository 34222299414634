import MobileDetect from 'mobile-detect';

export default {
  install: (app) => {
    app.directive('detect-mobile', {
      mounted(el, binding) {
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile()) {
          binding.value(true);
        } else {
          binding.value(false);
        }
      },
      // unmounted(el) {
      //     el._tippy && el._tippy.destroy();
      // },
    });
  },
};
