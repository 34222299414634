import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import { ICreatePayLicenseUrl } from '@/application/types/payment.types';

export default defineStore({
  id: storeId.BUY_LICENSE,
  state: () => ({} as {}),
  getters: {},
  actions: {
    async getLicenseRegistrationDetailInfo(registerId: string) {
      return UnAuthenticateService.getInstance().getLicenseRegistrationDetailInfo(
        registerId
      );
    },
    async createPayUrlForLicense(data: ICreatePayLicenseUrl) {
      return UnAuthenticateService.getInstance().createPayUrlForLicense(data);
    },
    async getLicenseResource(data: { paymentRequestToken: string }) {
      return UnAuthenticateService.getInstance().getLicenseResource(data);
    },
  },
});
