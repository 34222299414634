<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 296 296"
    style="enable-background: new 0 0 296 296"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M276,0H20C9,0,0,9,0,20v256c0,11,9,20,20,20h256c11,0,20-9,20-20V20C296,9,287,0,276,0z"
    />
    <g>
      <path
        class="st1"
        d="M58.2,245.6L58.2,245.6c-0.5,0-0.9,0.2-1.2,0.6l-3.3,5l-3.3-5c-0.3-0.4-0.6-0.6-1.1-0.6h-0.1   c-0.7,0-1.2,0.5-1.2,1.2v9.6c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-6l2.3,3.4c0.5,0.7,1.5,0.7,2,0l2.3-3.4v5.9   c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-9.6C59.4,246.2,58.9,245.6,58.2,245.6z"
      />
      <path
        class="st1"
        d="M93.8,251.3c0.6-0.6,1-1.4,1-2.4c0-0.8-0.3-1.6-0.9-2.1c-0.7-0.7-1.9-1.1-3.2-1.1h-3.9c-0.7,0-1.2,0.5-1.2,1.2   v9.5c0,0.7,0.5,1.2,1.2,1.2h4.1c2.7,0,4.5-1.4,4.5-3.6C95.4,252.8,94.8,251.9,93.8,251.3z M88,248h2.6c0.8,0,1.7,0.2,1.7,1.2   c0,1.1-1,1.3-1.8,1.3H88V248z M90.9,255.2h-3v-2.5h2.8c1.5,0,2.2,0.4,2.2,1.3C92.9,255.1,91.7,255.2,90.9,255.2z"
      />
      <path
        class="st1"
        d="M72.5,245.5c-3.4,0-6.1,2.7-6.1,6.2s2.6,6.1,6,6.1s6.1-2.7,6.1-6.2C78.5,248.1,75.9,245.5,72.5,245.5z    M76.1,251.6c0,2.1-1.5,3.8-3.6,3.8c-2,0-3.6-1.6-3.6-3.8c0-2.1,1.5-3.8,3.6-3.8C74.5,247.8,76.1,249.4,76.1,251.6L76.1,251.6z"
      />
      <path
        class="st1"
        d="M205.3,123.3c23.6,0,42.7-19.1,42.7-42.7s-19.1-42.7-42.7-42.7S162.6,57,162.6,80.6   C162.7,104.2,181.8,123.3,205.3,123.3z M205.3,62.5c10,0,18.1,8.1,18.1,18.1s-8.1,18.1-18.1,18.1s-18.1-8.1-18.1-18.1   C187.2,70.7,195.3,62.5,205.3,62.5z"
      />
      <path
        class="st1"
        d="M103.6,245.6c-0.7,0-1.2,0.5-1.2,1.2v9.6c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-9.6   C104.8,246.1,104.3,245.6,103.6,245.6z"
      />
      <path
        class="st1"
        d="M213,245.6c-0.7,0-1.2,0.5-1.2,1.2v6.3l-5.4-6.8c-0.2-0.3-0.5-0.6-1.1-0.6h-0.2c-0.7,0-1.2,0.5-1.2,1.2v9.6   c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2V250l5.5,7c0.2,0.3,0.6,0.6,1.2,0.6c0.6,0,1.2-0.5,1.2-1.2v-9.6   C214.2,246.1,213.7,245.6,213,245.6z"
      />
      <path
        class="st1"
        d="M229.2,255.2h-5.5v-2.5h4.7c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-4.7V248h5.4c0.6,0,1.2-0.5,1.2-1.2   c0-0.6-0.5-1.2-1.2-1.2h-6.6c-0.7,0-1.2,0.5-1.2,1.2v9.5c0,0.7,0.5,1.2,1.2,1.2h6.7c0.6,0,1.2-0.5,1.2-1.2   C230.3,255.7,229.8,255.2,229.2,255.2z"
      />
      <path
        class="st1"
        d="M246.8,245.6c-0.6,0-0.9,0.4-1.1,0.6l-3,4.4l-3-4.4c-0.2-0.2-0.5-0.6-1.1-0.6c-0.7,0-1.2,0.5-1.2,1.2   c0,0.3,0.1,0.5,0.3,0.8l3.8,5.4v3.5c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2V253l3.9-5.4c0.1-0.2,0.3-0.4,0.3-0.8   C248,246.1,247.5,245.6,246.8,245.6z"
      />
      <path
        class="st1"
        d="M205.3,141.7c-23.6,0-42.7,19.1-42.7,42.7s19.1,42.7,42.7,42.7S248,208,248,184.4S228.9,141.7,205.3,141.7z    M205.3,202.5c-10,0-18.1-8.1-18.1-18.1s8.1-18.1,18.1-18.1s18.1,8.1,18.1,18.1C223.5,194.4,215.3,202.5,205.3,202.5z"
      />
      <path
        class="st1"
        d="M190.9,245.5c-3.4,0-6.1,2.7-6.1,6.2s2.6,6.1,6,6.1s6.1-2.7,6.1-6.2C197,248.1,194.4,245.5,190.9,245.5z    M194.5,251.6c0,2.1-1.5,3.8-3.6,3.8c-2,0-3.6-1.6-3.6-3.8c0-2.1,1.5-3.8,3.6-3.8C192.9,247.8,194.5,249.4,194.5,251.6L194.5,251.6   z"
      />
      <path
        class="st1"
        d="M176.6,245.6h-0.1c-0.4,0-0.8,0.2-1.1,0.6l-3.3,5l-3.3-5c-0.3-0.4-0.6-0.6-1.1-0.6h-0.1   c-0.7,0-1.2,0.5-1.2,1.2v9.6c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-6l2.3,3.3c0.3,0.4,0.6,0.6,1,0.6c0.2,0,0.7-0.1,1-0.6l2.3-3.4   v5.9c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-9.6C177.8,246.2,177.3,245.6,176.6,245.6z"
      />
      <path
        class="st1"
        d="M118.4,141.7c-7.2,0-13.9,2.4-19.2,6.4c-5.3-4-12-6.4-19.2-6.4c-17.7,0-32,14.3-32,32V227h24.5v-53.6   c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2V227h24.5v-53.6c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2V227h24.5v-53.3l0,0   C150.4,156.1,136.1,141.7,118.4,141.7z"
      />
      <path
        class="st1"
        d="M135.3,255.2h-5.5v-2.5h4.7c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-4.7V248h5.4c0.6,0,1.2-0.5,1.2-1.2   c0-0.6-0.5-1.2-1.2-1.2h-6.6c-0.7,0-1.2,0.5-1.2,1.2v9.5c0,0.7,0.5,1.2,1.2,1.2h6.7c0.6,0,1.2-0.5,1.2-1.2   C136.5,255.7,135.9,255.2,135.3,255.2z"
      />
      <path
        class="st1"
        d="M118.4,38c-7.2,0-13.9,2.4-19.2,6.4c-5.3-4-12-6.4-19.2-6.4c-17.7,0-32,14.3-32,32v53.3h24.5V69.7   c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2l0,0v53.6h24.5V69.7c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2l0,0v53.6h24.5V70l0,0   C150.4,52.3,136.1,38,118.4,38z"
      />
      <path
        class="st1"
        d="M119.2,255.2h-4.9v-8.4c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v9.5c0,0.7,0.5,1.2,1.2,1.2h6.1   c0.6,0,1.2-0.5,1.2-1.2C120.4,255.7,119.8,255.2,119.2,255.2z"
      />
    </g>
  </svg>
</template>
<style scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #a50064;
}
.st1 {
  fill: #ffffff;
}
</style>
