<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 469.421 469.421"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="389.421"
        cy="177.21"
        r="44.138"
      ></circle>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="80"
        cy="177.21"
        r="44.138"
      ></circle>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M389.421,251.348c-19.914,0-38.114,7.289-52.11,19.332c15.877,17.402,26.864,38.185,32.278,60.668h99.832  C469.421,287.166,433.604,251.348,389.421,251.348z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M234.71,255.335c-60.061,0-108.75,48.689-108.75,108.75h217.5C343.46,304.024,294.771,255.335,234.71,255.335z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M80,251.348c-44.183,0-80,35.817-80,80h99.832c5.413-22.483,16.401-43.266,32.278-60.668  C118.114,258.638,99.914,251.348,80,251.348z"
      ></path>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="234.71"
        cy="165.335"
        r="60"
      ></circle>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 499.421 499.421"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M404.421,241.348c32.608,0,59.138-26.529,59.138-59.138s-26.529-59.138-59.138-59.138   c-32.609,0-59.139,26.529-59.139,59.138S371.812,241.348,404.421,241.348z M404.421,153.073c16.066,0,29.138,13.071,29.138,29.138   s-13.071,29.138-29.138,29.138c-16.067,0-29.139-13.071-29.139-29.138S388.354,153.073,404.421,153.073z"
        ></path>
        <path
          d="M499.421,361.348c0-52.383-42.617-95-95-95c-28.833,0-55.502,12.89-73.327,34.594   c-21.772-19.047-50.252-30.607-81.384-30.607c-31.132,0-59.612,11.56-81.384,30.607c-17.826-21.704-44.494-34.594-73.327-34.594   c-52.383,0-95,42.617-95,95v15h127.237c-0.835,5.795-1.276,11.715-1.276,17.737v15h247.5v-15c0-6.022-0.441-11.943-1.276-17.737   h127.237V361.348z M31.747,346.348c6.789-28.635,32.573-50,63.253-50c21.302,0,40.883,10.289,52.983,27.357   c-4.895,7.053-9.079,14.634-12.44,22.643H31.747z M157.159,379.086c7.202-44.588,45.962-78.75,92.551-78.75   s85.35,34.162,92.551,78.75H157.159z M363.878,346.348c-3.362-8.009-7.546-15.59-12.44-22.643   c12.099-17.068,31.681-27.357,52.983-27.357c30.681,0,56.465,21.365,63.253,50H363.878z"
        ></path>
        <path
          d="M95,241.348c32.609,0,59.138-26.529,59.138-59.138S127.609,123.073,95,123.073s-59.138,26.529-59.138,59.138   S62.391,241.348,95,241.348z M95,153.073c16.066,0,29.138,13.071,29.138,29.138S111.066,211.348,95,211.348   s-29.138-13.071-29.138-29.138S78.934,153.073,95,153.073z"
        ></path>
        <path
          d="M249.71,240.336c41.355,0,75-33.645,75-75s-33.645-75-75-75c-41.355,0-75,33.645-75,75S208.355,240.336,249.71,240.336z    M249.71,120.336c24.813,0,45,20.187,45,45s-20.187,45-45,45c-24.813,0-45-20.187-45-45S224.897,120.336,249.71,120.336z"
        ></path>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
