import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-654227e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row justify-center divide-x" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.key,
        class: _normalizeClass([
        _ctx.currentItem && _ctx.currentItem.key == item.key
          ? 'tab-active'
          : 'tab-default'
      , "flex-auto rounded"]),
        onClick: ($event: any) => (_ctx.changeTab(item))
      }, _toDisplayString(item.name.fr ? item.name.fr : item.name), 11, _hoisted_2))
    }), 128))
  ]))
}