export const UIConstantPaymentResult = {
  SUCCESS: {
    labelCode: 'PAYMENT_RESULT_SUCCESSFULL',
    colorClass: 'current-600',
    textColorClass: 'current-600',
    iconName: 'check',
  },
  FAILED: {
    labelCode: 'PAYMENT_RESULT_FAILED',
    colorClass: 'orange-600',
    textColorClass: 'gray-800',
    iconName: 'cancel',
  },
  CANCELED: {
    labelCode: 'PAYMENT_RESULT_CANCELED',
    colorClass: 'gray-600',
    textColorClass: 'gray-800',
    iconName: 'cancel',
  },
};

export const vnpayErrorByCode = {
  '00': 'VNPAY_TRANSACTION_FAILED_00',
  '07': 'VNPAY_TRANSACTION_FAILED_07',
  '09': 'VNPAY_TRANSACTION_FAILED_09',
  '10': 'VNPAY_TRANSACTION_FAILED_10',
  '11': 'VNPAY_TRANSACTION_FAILED_11',
  '12': 'VNPAY_TRANSACTION_FAILED_12',
  '13': 'VNPAY_TRANSACTION_FAILED_13',
  '24': 'VNPAY_TRANSACTION_FAILED_24',
  '51': 'VNPAY_TRANSACTION_FAILED_51',
  '65': 'VNPAY_TRANSACTION_FAILED_65',
  '75': 'VNPAY_TRANSACTION_FAILED_75',
  '79': 'VNPAY_TRANSACTION_FAILED_79',
  '99': 'VNPAY_TRANSACTION_FAILED_99',
};
