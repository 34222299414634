import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';

export default class AuthRepository {
  private static instance: AuthRepository;
  request: AxiosInstance;
  constructor() {
    this.request = BaseRequest;
  }
  public static getInstance(): AuthRepository {
    if (!AuthRepository.instance) {
      // Get from local storage
      AuthRepository.instance = new AuthRepository();
    }

    return AuthRepository.instance;
  }

  getCurrentLoginInformations() {
    return this.request({
      url: `Session/GetCurrentLoginInformations`,
      method: 'get',
    });
  }

  async selectOrganization(data): Promise<any> {
    return this.request({
      url: `selectOrganization`,
      method: 'post',
      data,
    });
  }

  checkVoucherCode(voucherCode: string): Promise<any> {
    return this.request({
      url: 'Voucher/Check',
      params: {
        voucher: voucherCode,
      },
      method: 'get',
    });
  }

  getVoucherCodeDetail(voucherCode: string): Promise<any> {
    return this.request({
      url: `Voucher/Detail/${voucherCode}`,
      method: 'get',
    });
  }
}
