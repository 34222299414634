/* eslint-disable no-unused-vars */
export enum storeId {
  app = 'APP',
  task = 'TASK',
  taskDrawer = 'TASK_DRAWER',
  taskDetail = 'TASK_DETAIL',
  taskCreateForm = 'TASK_CREATE_FORM',
  taskList = 'TASK_LIST',
  taskActivity = 'TASK_ACTIVITY',
  group = 'GROUP',
  user = 'USER',
  domain = 'DOMAIN',
  OVERVIEW_DOMAINS = 'OVERVIEW_DOMAINS',
  principle = 'PRINCIPLE',
  setting = 'SETTING',
  auth = 'AUTH',
  UN_AUTH = 'UN_AUTH',
  PAGE_LOGIN = 'PAGE_LOGIN',
  AUTH_REGISTER = 'AUTH_REGISTER',
  AUTH_MY_PROFILE = 'AUTH_MY_PROFILE',
  organization = 'ORGANIZATION',
  ORGANIZATION_INVITE_USER = 'ORGANIZATION_INVITE_USER',
  chat = 'CHAT',
  CHAT_PANEL = 'CHAT_PANEL',
  dashboard = 'DASHBOARD',
  dashboardTeam = 'DASHBOARD_TEAM',
  dashboardGroup = 'DASHBOARD_GROUP',
  dashboardFinishedTask = 'DASHBOARD_FINISHED_TASK',
  dashboardCreatedTask = 'DASHBOARD_CREATED_TASK',
  remoteConfig = 'REMOTE_CONFIG',
  security = 'SECURITY',
  video = 'VIDEO',
  fileStorage = 'FILE_STORAGE',
  alert = 'ALERT',
  calendar = 'CALENDAR',
  conference = 'CONFERENCE',
  system = 'SYSTEM',
  DAYOFF_OVERVIEW = 'DAYOFF_OVERVIEW',
  DAYOFF_CREATE = 'DAYOFF_CREATE',
  DAYOFF_DETAIL = 'DAYOFF_DETAIL',
  DAYOFF_STATISTICS = 'DAYOFF_STATISTICS',
  CALENDARS_OVERVIEW = 'CALENDARS_OVERVIEW',
  DOMAIN_MANAGEMENT = 'DOMAIN_MANAGEMENT',
  PLANS_SETTING = 'PLANS_SETTING',
  PAYMENT_SETTING = 'PAYMENT_SETTING',

  // embed
  embedUser = 'EMBED_USER',
  embedGroup = 'EMBED_GROUP',
  embedOrganization = 'EMBED_ORGANIZATION',
  embedTask = 'EMBED_TASK',
  embedRemoteConfig = 'EMBED_REMOTE_CONFIG',
  // ticket
  ticketUser = 'TICKET_USER',
  ticketGroup = 'TICKET_GROUP',
  ticketOrganization = 'TICKET_ORGANIZATION',
  ticketTask = 'TICKET_TASK',
  ticketRemoteConfig = 'TICKET_REMOTE_CONFIG',
}

export default storeId;
