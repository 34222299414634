import { ref } from 'vue';

export default function atomFormInputComposables(
  validationList: Function[],
  inputTimeout: number = 0
) {
  const isTyping = ref(false);
  const isChecking = ref(false);

  const validationResult = ref<{
    isDirty: boolean;
    isValid: boolean;
    message: string;
  }>({
    isDirty: false,
    isValid: false,
    message: '',
  });

  let timer;
  const onValidation = (value) => {
    isTyping.value = true;
    validationResult.value = {
      isDirty: true,
      isValid: false,
      message: '',
    };
    if (timer) clearTimeout(timer);

    timer = setTimeout(async () => {
      isChecking.value = true;
      for (let index = 0; index < validationList.length; index++) {
        const validationFunction = validationList[index];

        const errorMessage = validationFunction
          ? await validationFunction(value)
          : true;
        // console.log(
        //   '🚀 ~ file: atom-form-input-composables.ts ~ line 37 ~ awaitvalidationList.every ~ isValid',
        //   { ...validation, isValid }
        // );

        validationResult.value = !errorMessage
          ? {
              isDirty: true,
              isValid: true,
              message: '',
            }
          : {
              isDirty: true,
              isValid: false,
              message: typeof errorMessage == 'string' ? errorMessage : '',
            };

        if (errorMessage) break;
      }

      isChecking.value = false;
      isTyping.value = false;
    }, inputTimeout);
  };

  const reset = () => {
    isChecking.value = false;
    isTyping.value = false;
    validationResult.value = {
      isDirty: true,
      isValid: false,
      message: '',
    };
  };

  return {
    isTyping,
    isChecking,
    validationResult,
    onValidation,
    reset,
  };
}
