import AuthService from '@/application/services/authenticate/AuthService';
import AuthenticateClass from '@/application/entities/authenticate/AuthenticateClass';

export class AuthenticateSelectOrganization implements IAuthenticate {
  organizationId: number;
  _authService: AuthService;

  constructor(organizationId: number) {
    this.organizationId = organizationId;
    this._authService = AuthService.getInstance();
  }

  async login() {
    const res: any = await this._authService.selectOrganization({
      OrganizationId: this.organizationId,
    });

    // handle login success
    return new AuthenticateClass().handleSuccess(res?.result);
  }
}

export class AcceptedInvitation {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  inviteToken: string;

  constructor(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    inviteToken: string
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.email = email;
    this.inviteToken = inviteToken;
  }
}

interface IAuthenticate {
  login: any;
}
