import { remoteConfig } from '@/ui/plugins/firebases/init-app';
import { getValue } from 'firebase/remote-config';
export const RemoteConfigKey = {
  PAYMENT_MOMO_ENABLED: 'payment_momo_enabled',
  PAYMENT_VNPAY_ENABLED: 'payment_vnpay_enabled',
  PAYMENT_ZALOPAY_ENABLED: 'payment_zalopay_enabled',
};

export const getRemoteConfigValueByKey = (key) => {
  return getValue(remoteConfig, key);
};
