import { ref, provide } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import 'dayjs/locale/fr';

import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import en from './en.json';
import vi from './vi.json';
import fr from './fr.json';

export const options = ref(vi as any);

export const onChangeI18n = async (lang) => {
  dayjs.locale(lang);

  switch (lang) {
    case 'fr':
      options.value = fr;
      break;
    case 'en':
      options.value = en;
      break;
    case 'vi':
      options.value = vi;
      break;
    default:
      break;
  }
};

export const translate = (key: string, params?: any): string => {
  if (!key) return '';
  let text = key
    ? key
        .toString()
        .split('.')
        .reduce((o, i) => {
          if (o) return o[i];
        }, options.value)
    : '';

  // If not translate key, get default by EN
  if (!text) {
    text = key
      ? key.split('.').reduce((o, i) => {
          if (o) return o[i];
        }, en)
      : '';
  }

  if (text && params) {
    for (const key in params) {
      text = text.replaceAll(`{{${key}}}`, params[key]);
    }
  }
  return text;
};

export const myi18n = {
  install: (app) => {
    const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';

    if (options.value == null) {
      switch (lang) {
        case 'fr':
          options.value = fr;
          break;
        case 'en':
          options.value = en;
          break;
        case 'vi':
          options.value = vi;
          break;
        default:
          options.value = vi;
          break;
      }
    }
    // Plugin code goes here
    app.config.globalProperties.$t = (key, params) => {
      return translate(key, params);
    };

    app.config.globalProperties.$locale = lang;

    provide('translate', translate);
  },
};
