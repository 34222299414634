<template>
  <!--  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--       xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512"-->
  <!--       style="enable-background:new 0 0 512 512" xml:space="preserve"><g>-->
  <!--    <path fill="#ffb127" data-original="#ffb127" xmlns="http://www.w3.org/2000/svg"-->
  <!--          d="m464.871094 0h-224.941406c-26.03125 0-47.128907 21.101562-47.128907 47.128906v110.800782c11.890625 5.910156 24.738281 12.300781 36.730469 18.261718l18.347656-36.710937c11.109375-22.199219 38.191406-31.21875 60.382813-20.128907 22.238281 11.128907 31.25 38.136719 20.117187 60.378907l-32.707031 65.429687c.867187 8.660156.097656 17.390625-2.34375 25.839844h21.59375l-56.953125 156.609375c-3.378906 9.289063 3.152344 20.128906 14.101562 20.128906 6.121094 0 11.878907-3.777343 14.089844-9.878906l60.679688-166.859375h10.511718l61.296876 166.910156c2.230468 6.070313 7.972656 9.828125 14.089843 9.828125 10.230469 0 17.730469-10.21875 14.070313-20.167969l-57.5-156.570312h75.5625c26.027344 0 47.128906-21.101562 47.128906-47.128906v-176.742188c0-26.027344-21.101562-47.128906-47.128906-47.128906zm-32.140625 206.738281h-67.300781c-8.28125 0-15-6.71875-15-15 0-8.289062 6.71875-15 15-15h67.300781c8.289062 0 15 6.710938 15 15 0 8.28125-6.710938 15-15 15zm0-56.238281h-67.300781c-8.28125 0-15-6.710938-15-15 0-8.28125 6.71875-15 15-15h67.300781c8.289062 0 15 6.71875 15 15 0 8.289062-6.710938 15-15 15zm0-56.230469h-160.660157c-8.289062 0-15-6.71875-15-15s6.710938-15 15-15h160.660157c8.289062 0 15 6.71875 15 15s-6.710938 15-15 15zm0 0"></path><path-->
  <!--      xmlns="http://www.w3.org/2000/svg"-->
  <!--      d="m263.921875 264.566406c2.710937-7.941406 2.691406-16.238281.402344-23.808594l37.226562-74.445312c3.703125-7.410156.703125-16.421875-6.707031-20.125-7.40625-3.707031-16.417969-.703125-20.125 6.707031l-31.75 63.492188c-18.976562-9.449219-47.808594-23.785157-71.035156-35.335938-21.609375-10.746093-37.03125-20.382812-63.34375-20.382812h-13.253906c20.488281 0 38.773437-9.429688 50.742187-24.1875 9.132813-11.230469 14.59375-25.542969 14.59375-41.140625 0-36.089844-29.25-65.339844-65.34375-65.339844-36.078125 0-65.328125 29.25-65.328125 65.339844 0 17.332031 6.75 33.082031 17.761719 44.769531 11.90625 12.660156 28.816406 20.558594 47.5625 20.5625-25.132813 0-49.703125 10.183594-67.378907 27.90625-18.019531 17.972656-27.945312 41.917969-27.945312 67.425781v48.199219c0 20.75 13.472656 38.40625 32.132812 44.6875v83.84375c0 8.285156 6.714844 15 15 15h96.398438c8.285156 0 15-6.714844 15-15v-168.535156c14.222656 7.109375 32.90625 16.449219 48.023438 24.011719 10.597656 5.296874 22.980468 5.773437 34.023437 1.28125 11.011719-4.53125 19.5-13.550782 23.285156-24.75.019531-.058594.039063-.117188.058594-.175782zm0 0"-->
  <!--      fill="#05b3b0" data-original="#05b3b0"></path></g></svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="GradientFill_1"
        gradientUnits="userSpaceOnUse"
        x1="256"
        x2="256.001"
        y1="511.999"
      >
        <stop offset="0" stop-color="#6c54a3"></stop>
        <stop offset="1" stop-color="#00b1d2"></stop>
      </linearGradient>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 0c141.38 0 256 114.616 256 256s-114.62 256-256 256-256-114.624-256-256 114.62-256 256-256zm-100.876 286.328a5.079 5.079 0 0 0 5.079 5.078h24.583v24.582a5.079 5.079 0 1 0 10.157 0v-24.582h156.857a5.079 5.079 0 0 0 0-10.158h-22.776v-79.769a5.079 5.079 0 0 0 -10.157 0v79.769h-25.254v-67.194a5.079 5.079 0 1 0 -10.158 0v67.194h-25.255v-49.648a5.076 5.076 0 1 0 -10.152 0v49.645h-25.248v-37.766a5.079 5.079 0 1 0 -10.158 0v37.769h-17.699v-127.193a5.079 5.079 0 0 0 -10.157 0v127.193h-24.586a5.08 5.08 0 0 0 -5.079 5.08zm59.047-75.272a5.075 5.075 0 0 0 7.178-.081l32.423-33.167 31.887 21.926a5.079 5.079 0 0 0 6.6-.731l30.527-32.894v5.273a5.079 5.079 0 1 0 10.158 0v-17.327a5.079 5.079 0 0 0 -5.079-5.078h-17.333a5.076 5.076 0 1 0 0 10.152h4.868l-27.607 29.749-31.793-21.862a5.075 5.075 0 0 0 -6.5.635l-35.411 36.227a5.075 5.075 0 0 0 .087 7.178zm176.229 102.098v-174.239h-268.8v174.239a13.977 13.977 0 0 0 13.963 13.962h240.87a13.98 13.98 0 0 0 13.967-13.962zm-78.491 24.119h-111.819l-4.685 14.176h121.195l-4.686-14.176zm93.324-243.773h-298.466a13.276 13.276 0 0 0 -13.267 13.264v18.891a13.276 13.276 0 0 0 13.268 13.26h4.68v174.239a24.148 24.148 0 0 0 24.12 24.119h23.851l-12.9 62.343a15.693 15.693 0 0 0 30.268 8.108l8.408-25.408h141.611l8.408 25.408a15.7 15.7 0 0 0 30.272-8.108l-12.909-62.343h23.856a24.152 24.152 0 0 0 24.125-24.119v-174.239h4.675a13.276 13.276 0 0 0 13.269-13.26v-18.891a13.277 13.277 0 0 0 -13.269-13.264z"
        fill="url(#GradientFill_1)"
        fill-rule="evenodd"
        data-original="url(#GradientFill_1)"
      ></path>
    </g>
  </svg>
</template>
