import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DefaultValue from '@/store/organization/default-value';
import CatalogService from '@/application/services/CatalogService';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default defineStore({
  id: storeId.organization,
  state: () => ({
    _catalogService: CatalogService.getInstance(),
    isLoadingOrg: false,
    attachmentCode: '',
    organizationModels: DefaultValue.organizationModels,
    organizationTypes: DefaultValue.organizationTypes,
    organizationConfigs: DefaultValue.organizationConfigs,
    draftedData: [],
    fileName: '',
    organizationInfo: {} as any,
    _pendingUsers: [] as any[],
    isInstallMobileApp: false,
    organizationIds: [] as any[],
    organizationByIds: {} as any,
  }),
  getters: {
    draftedDataLength(state): number {
      return state.draftedData?.length;
    },

    pendingUsers(state): any[] {
      return state._pendingUsers ?? [];
    },
  },
  actions: {
    updateCurrentOrganizationInfo() {
      const currentOrg = this.organizationByIds[this.organizationInfo?.id];
      if (!currentOrg?.id) return;
      this.organizationInfo = {
        ...this.organizationInfo,
        members: currentOrg?.members,
        joinedDate: currentOrg?.joinedDate,
      };
    },
    async getUserOrganizations(): Promise<any> {
      this.isLoadingOrg = true;

      try {
        const res = await this._catalogService.getUserOrganizations();

        const allOrganizations = res?.result;

        const newSet = new Set(allOrganizations.map((org) => org.id));
        this.organizationIds = [...newSet];
        this.organizationByIds = allOrganizations.reduce((old, current) => {
          return {
            ...old,
            [current?.id]: current,
          };
        }, {});
        this.updateCurrentOrganizationInfo();

        this.isLoadingOrg = false;
        return res?.result;
      } catch (e) {
        this.isLoadingOrg = false;
      }
    },
    async getOrganizationInfo(id?: string) {
      const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
      const orgId = id || organizationId;
      const res = await this._catalogService.getOrganizationInfo(orgId);
      this.organizationInfo = res?.result;
      return res?.result;
    },
  },
});
