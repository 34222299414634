import { DiscountByUser } from '@/application/types/payment-configs.types';

export const getPriceByTotalUser = (
  totalUser,
  basePrice,
  discountByTotalUser: DiscountByUser[]
) => {
  const price = discountByTotalUser
    .filter((item) => item.from <= totalUser)
    .reduce((oldValue, currentItem, currentIndex, array) => {
      const totalUserInThisLevel =
        currentIndex < array.length - 1 && currentItem.to !== null
          ? currentItem.to - currentItem.from + 1
          : totalUser - currentItem.from + 1;

      const priceInCurrentLevel = _formatPriceAfterDiscount(
        basePrice,
        currentItem.discount
      );

      return oldValue + totalUserInThisLevel * priceInCurrentLevel;
    }, 0);

  return {
    monthPrice: price,
    unitPrice: price / totalUser,
    discountPercent: Math.round(
      ((basePrice - price / totalUser) / basePrice) * 100
    ),
  };
};

const _formatPriceAfterDiscount = (basePrice: number, discount) => {
  if (discount == 0) return basePrice;

  return Math.floor((basePrice / 100) * (1 - discount)) * 100;
};
