
import { defineComponent, ref, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'SynTab',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    tabType: {
      type: String,
      default: 'grid',
    },
  },
  emits: ['changeTab'],
  setup: (props, { emit }) => {
    const { items, tabType } = toRefs(props);
    let isActive = ref('email');
    const changeTab = (key) => {
      isActive.value = key;
      emit('changeTab', key);
    };

    const navClass = computed(() => {
      return tabType.value == 'grid'
        ? `grid grid-cols-${items.value.length}`
        : 'flex flex-row  justify-center';
    });
    return { isActive, changeTab, navClass };
  },
});
