import { useIntersectionObserver } from '@vueuse/core';

export default {
  install: (app) => {
    app.directive('intersection-observer', {
      beforeMount(el, binding) {
        useIntersectionObserver(el, (event) => {
          if (binding?.value && typeof binding?.value == 'function') {
            binding.value(event);
          }
        });
      },
    });
  },
};
