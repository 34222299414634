<script setup></script>

<template>
  <div class="flex items-center justify-center w-full">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 3rem 6rem 3rem;
        height: 24rem;
      "
      class="w-3/4 flex-col"
    >
      <div class="text-3xl pr-4 pt-4 pb-8">Chính sách giao nhận</div>
      <div class="flex flex-col space-y-10">
        <div class="flex flex-col space-y-3">
          <span class="flex flex-col space-y-3 text-gray-600 leading-loose">
            Chúng tôi có các hình thức giao nhận như sau:
            <ul style="list-style-type: decimal; margin-left: 2rem">
              <li>
                Gửi mã kích hoạt qua email của khách hàng: thông thường chúng
                tôi sẽ gửi mã cho quý khách trong vòng 24h kể từ khi thanh toán
                thành công
              </li>
              <li>
                Gửi mã kích hoạt qua tin nhắn SMS: thông thường chúng tôi sẽ gửi
                mã cho quý khách trong vòng 24h kể từ khi thanh toán thành công
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
