export enum storeId {
  app = 'APP',
  task = 'TASK',
  group = 'GROUP',
  user = 'USER',
  domain = 'DOMAIN',
  principle = 'PRINCIPLE',
  setting = 'SETTING',
  auth = 'AUTH',
  organization = 'ORGANIZATION',
  chat = 'CHAT',
  dashboard = 'DASHBOARD',
  remoteConfig = 'REMOTE_CONFIG',
  security = 'SECURITY',
  video = 'VIDEO',
  fileStorage = 'FILE_STORAGE',
  alert = 'ALERT',
  SYSTEM = 'SYSTEM',
  PAYMENT = 'PAYMENT',
  PAYMENT_VOUCHER = 'PAYMENT_VOUCHER',
  BUY_LICENSE = 'BUY_LICENSE',
}

export default storeId;
